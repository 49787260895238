import { Box, Modal, Typography } from '@mui/material';
import { API_HOST } from '../../../config';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function BanModal({ BanModal, handleCloseBan, user }) {
    const handleBan = () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('admin_token')
        );
        fetch(
            `${API_HOST}/api/v1/manager-panel/manager/toggle-status`,
            {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    manager_id: user.id,
                    is_active: false,
                }),
            }
        );
    };
    return (
        <Modal
            open={BanModal}
            onClose={handleCloseBan}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Деактивировать аккаунт сотрудника
                </Typography>
                <Typography
                    id="modal-modal-description"
                    style={{
                        marginBottom: 20,
                        marginTop: 20,
                        color: '#42454C',
                        opacity: '70%',
                    }}
                >
                    Сотрудник: {user?.full_name}
                </Typography>
                <div className="buttonGroup">
                    <div
                        className="resetButton"
                        onClick={() => {
                            handleCloseBan();
                        }}
                    >
                        Отменить
                    </div>
                    <div
                        className="confirmButton"
                        onClick={() => {
                            handleBan();
                            handleCloseBan();
                        }}
                    >
                        Подтвердить
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default BanModal;
