import useSWR from 'swr';
import { API_HOST } from '../config';
import adminFetcher from './adminFetcher'

export default function useClients(page = 1, limit = 20, query) {
    const skip = (page - 1) * limit;
    const { data, error, mutate } = useSWR(
        `${API_HOST}/api/v1/manager-panel/customers?skip=${skip}&limit=${limit}${query ? `&query=${query}` : ''}`,
        adminFetcher
    );
    const isLoading = !error && !data;

    return {
        clients: data?.data,
        totalAmount: data?.amount,
        isLoading,
        mutate,
    };
}