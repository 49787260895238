import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_HOST } from '../../config';

function ConformationEmail() {
    const myHeaders = new Headers();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    myHeaders.append('Content-Type', 'application/json');
    fetch(`${API_HOST}/api/v1/auth/email/confirm`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(searchParams.get('confirmation_token')),
    })
        .then((res) => res.json())
        .then(() => navigate('/sign-in'));
    return <CircularProgress />;
}

export default ConformationEmail;
