import useSWR from 'swr';
import { API_HOST } from '../config';

export default function useReplenishments() {
    const { data, error, mutate } = useSWR(
        `${API_HOST}/api/v1/customer-panel/customer/replenishments`
    );
    const isLoading = !error && !data;
    return {
        replenishments: data,
        isLoading,
        mutate,
    };
}
