import { Navigate, Outlet } from 'react-router-dom';
import './AdminLayout.scss';
import AdminMenu from '../AdminMenu/AdminMenu';

// eslint-disable-next-line react/prop-types, no-unused-vars
function AdminLayout({ children }) {
    const token = localStorage.getItem('admin_token');
    if (!token) return <Navigate to="/admin/sign-in" />;
    return (
        <div className="admin_layout">
            <AdminMenu />
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
}

export default AdminLayout;
