import useSWR from 'swr';
import { API_HOST } from '../config';
import adminFetcher from './adminFetcher'

export default function useManagers() {
    const { data, error, mutate } = useSWR(
        `${API_HOST}/api/v1/manager-panel/managers`,adminFetcher
    );
    const isManagerLoading = !error && !data;
    return {
        managers: data,
        isManagerLoading,
        mutate,
    };
}
