import './Header.scss';
import { ReactComponent as Balance } from '../../assets/balance.svg';
import { ReactComponent as Avatar } from '../../assets/avatar.svg';
import { useState } from 'react';
import TariffPlan from '../TariffPlan/TariffPlan';

function Header({ user }) {
    const [tariff, setTariff] = useState(false);
    return (
        <div className="header">
            <div className="greeting">{`Добро пожаловать, ${user?.first_name}`}</div>
            <div className="balance">
                <Balance />
                <div className="balance-text">
                    <span className="balance-text_main">{`${user?.balance} ₽`}</span>
                    <TariffPlan
                        open={tariff}
                        handleClose={() => setTariff(false)}
                    />
                </div>
                <svg
                    width="1"
                    height="66"
                    viewBox="0 0 1 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="1" height="66" fill="#DCDCDC" />
                </svg>
                <div className="info">
                    <Avatar />
                    <div className="info-text">
                        <span className="info-text_main">
                            {`${user?.first_name}`}
                        </span>
                        <span className="info-text_sub">
                            {`${user?.email}`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
