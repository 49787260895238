import './PasswordReset.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '../../UI-kit/TextField/TextField';
import { useEffect, useState } from 'react';
import SupportIcon from '../../widgets/SupportIcon/SupportIcon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IMG from '../../assets/screenOne.png';
import { API_HOST } from '../../config';

const FORMIK_INIT_VALUES = {
    email: '',
};

const VALIDATION_SHEMA = Yup.object().shape({
    email: Yup.string()
        .trim()
        .required('Это обязательное поле')
        .matches(
            /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i,
            'Введен некорректный Email'
        ),
});

const timer = (setCount) => {
    const id = setInterval(() => {
        setCount((prev) => {
            if (prev === 0) {
                clearInterval(id);
            }
            return prev > 0 ? prev - 1 : 0;
        });
    }, 1000);
};

function PasswordReset() {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [count, setCount] = useState(60);

    const handleClickEmailConfirm = async () => {
        if (count === 0) {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            const res = await fetch(
                `${API_HOST}/api/v1/auth/email/confirm/resend`,
                {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify(formik.values.email),
                }
            );
            if (res.ok) {
                setCount(60);
                timer(setCount);
            } else {
                const data = await res.json();
                console.log(data);
                let msg = '';
                await data.detail.forEach((el) => {
                    msg += el.loc[1] + ' ' + el.msg + ' ';
                });
                alert(msg);
            }
        }
    };
    useEffect(() => {
        if (modal) {
            timer(setCount);
        }
    }, [modal]);

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        // eslint-disable-next-line no-unused-vars
        onSubmit: async (values) => {
            values.email = values.email.trim();
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            const res = await fetch(`${API_HOST}/api/v1/auth/password/reset`, {
                method: 'post',
                headers: myHeaders,
                body: JSON.stringify(values.email),
            });
            if (res.ok) {
                setModal(true);
            } else {
                const data = await res.json();
                if (data.detail === 'Пользователь с данным email не найден') {
                    formik.setErrors({
                        ...formik.errors,
                        email: 'Пользователь с данным email не найден',
                    });
                }
            }
            //formik.setValues(FORMIK_INIT_VALUES);
        },
    });

    useEffect(() => {
        formik.validateForm();
    }, []);

    return (
        <div className="PasswordReset">
            <div className="greetings">
                <Logo className="logo" />
                <div className="title">
                    <h1 className="title-mainText">
                        Простой путь к большим продажам
                    </h1>
                    <h4 className="title-subText">
                        Аналитика, управление и настройка в одном месте
                    </h4>
                </div>
                <div className="screen">
                    <img src={IMG} alt="screen" />
                </div>
            </div>
            {/* eslint-disable-next-line no-constant-condition */}
            {!modal ? (
                <div className="form-wrapper">
                    <HomeIcon
                        className="homeIcon"
                        onClick={() => navigate('/')}
                    />
                    <div className="title">
                        <h1 className="title-mainText">Сброс пароля</h1>
                        <h4 className="title-subText">
                            Введите почту, которая привязана к аккаунту и мы
                            отправим вам ссылку на восстановление доступа
                        </h4>
                    </div>
                    <form className="form" onSubmit={formik.handleSubmit}>
                        <TextField
                            label={'Почта'}
                            placeholder={'Введите почту'}
                            name={'email'}
                            onChange={formik.handleChange}
                            errors={formik.errors.email}
                            onBlur={formik.handleBlur}
                            touched={formik.touched.email}
                        />
                        <button type="submit" className={
                                    formik.isValid
                                        ? 'formBtn  active'
                                        : 'formBtn'
                                }>
                            Сбросить пароль
                        </button>
                        <span className="helpText">
                            Не получается?{' '}
                            <Link to={'/start?support=true'}>
                                Связаться с поддержкой
                            </Link>
                        </span>
                    </form>
                </div>
            ) : (
                <div className="sucsessRecovery">
                    <HomeIcon
                        className="homeIcon"
                        onClick={() => navigate('/')}
                    />
                    <div className="title">
                        <h1 className="title-mainText">Проверьте почту</h1>
                        <h4 className="title-subText">
                            Мы отправили дальнейшие инструкции по восстановлению
                            доступа на почту {formik.values.email}
                        </h4>
                        <h4 className="title-subText mt">
                            Спасибо что вы с нами!
                        </h4>
                    </div>
                    <button
                        type="button"
                        className="linkBtn"
                        onClick={() => navigate('/')}
                    >
                        Начать работу
                    </button>
                    <span className="helpText">
                        Не пришло письмо?{' '}
                        <span
                            className={count > 0 ? 'repeat disable' : 'repeat'}
                            onClick={() => handleClickEmailConfirm()}
                        >
                            Отправить еще раз{' '}
                            {count === 60
                                ? '01:00'
                                : count < 10
                                ? `00:0${count}`
                                : `00:${count}`}
                        </span>
                    </span>
                    <SupportIcon />
                </div>
            )}
        </div>
    );
}

export default PasswordReset;
