import { Modal } from '@mui/material';
import { ReactComponent as Close } from '../../assets/close.svg';
import BalanceModal from './BalanceModal';
import EntityModal from './EntityModal';
import { useState } from 'react';

function ChoiceModal({ open, handleClose }) {
    const [choice, setChoice] = useState();
    const [pay, setPay] = useState(false);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="choice-modal">
                    <h2 className="title">Пополнить баланс</h2>
                    <h2 className="sub-title">Способ пополнения:</h2>
                    <div className="btn-wrapper">
                        <button
                            onClick={() => {
                                setChoice(1);
                                setPay(true);
                                handleClose()
                            }}
                            type="submit"
                            className="accept"
                        >
                            Физ.лицо
                        </button>
                        <button
                            onClick={() => {
                                setChoice(2);
                                setPay(true);
                                handleClose()
                            }}
                            type="submit"
                            className="accept"
                        >
                            Юр.лицо
                        </button>
                    </div>
                    <h6>
                        Обратите внимание после первой оплаты изменить способ
                        пополнения нельзя
                    </h6>
                    <Close onClick={handleClose} className="close" />
                </div>
            </Modal>
            {choice === 1 && (
                <BalanceModal open={pay} handleClose={() => setPay(false)} />
            )}
            {choice === 2 && (
                <EntityModal open={pay} handleClose={() => setPay(false)} />
            )}
        </>
    );
}

export default ChoiceModal;
