import { useFormik } from 'formik';
import Select from '../../../UI-kit/Select/Select';
import { Box, Modal, Typography } from '@mui/material';
import Option from '../../../UI-kit/Select/Option';
import { API_HOST } from '../../../config';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FORMIK_INITIAL_VALUES = {
    staff: '',
};

function StaffModal({
    modal,
    handleCloseModal,
    confirm,
    setConfirm,
    client,
    arr,
    refresh,
}) {
    const [error, setError] = useState('');
    const formik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
        onSubmit: async (values) => {
            if (values.staff !== '') {
                const myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json');
                myHeaders.append(
                    'Authorization',
                    'Bearer ' + localStorage.getItem('admin_token')
                );
                await fetch(
                    `${API_HOST}/api/v1/manager-panel/manager/appoint`,
                    {
                        method: 'PUT',
                        headers: myHeaders,
                        body: JSON.stringify({
                            manager_id: values.staff,
                            customer_id: client.id,
                        }),
                    }
                );
                refresh();
                handleCloseModal();
                formik.setValues(FORMIK_INITIAL_VALUES);
            } else {
                setError('Выберите менеджера');
            }
        },
    });

    return (
        <Modal
            open={modal}
            onClose={() => {handleCloseModal();setError('')}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Изменить менеджера клиента
                        </Typography>
                        <Typography
                            id="modal-modal-description"
                            style={{
                                marginBottom: 20,
                                marginTop: 20,
                                color: '#42454C',
                                opacity: '70%',
                            }}
                        >
                            Клиент: {client?.email}
                        </Typography>
                        {error && <h4 className="error-text">{error}</h4>}
                        <Select
                            placeholder="Сотрудник"
                            onChange={formik.setFieldValue}
                            name={'staff'}
                        >
                            {client?.manager_id && <Option value={null}>Открепить менеджера</Option>}
                            {arr?.map((el) => (
                                <Option
                                    key={el.id + 1}
                                    value={el.id}
                                >{`${el.first_name} ${el.second_name}`}</Option>
                            ))}
                        </Select>
                        <div style={{ height: 20 }} />
                        {!confirm && (
                            <div
                                onClick={() => setConfirm(true)}
                                className="editButton"
                            >
                                <button type="submit">Изменить</button>
                            </div>
                        )}
                        {confirm && (
                            <div className="buttonGroup">
                                <div
                                    className="resetBtn"
                                    onClick={() => {
                                        handleCloseModal();
                                        formik.setValues(FORMIK_INITIAL_VALUES);
                                    }}
                                >
                                    <span>Отменить</span>
                                </div>
                                <div className="confirmBtn">
                                    <button type="submit">Подтвердить</button>
                                </div>
                            </div>
                        )}
                    </Box>
                </form>
            </>
        </Modal>
    );
}

export default StaffModal;
