const columns = [
    {
        Header: 'Название',
        accessor: 'title',
        sortType: 'string',
        width: 400,
    },
    {
        Header: 'Количество товаров',
        accessor: 'count',
        sortType: 'number',
        width: 200,
    },
    {
        Header: 'Статус',
        accessor: 'status',
        disableSortBy: true,
        width: 100,
    },
    {
        Header: '',
        accessor: 'button',
        disableSortBy: true,
        width: 550,
    },
];

export default columns;
