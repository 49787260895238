import { useEffect, useState } from 'react';
import Table from '../../widgets/Table/Table';
import './BetManage.scss';
import BetModal from './BetModal';
import columns from './tableColumns';
import EditOneBet from './EditOneBet';
import useCategories from '../../hooks/useCategories';
import usePriceList from '../../hooks/usePriceList';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { CircularProgress } from '@mui/material';

function BetManage() {
    const { categories,mutateCategories } = useCategories();
    const { price_list, isLoadingPrice } = usePriceList();
    const { user, isLoading } = useUser();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [current, setCurrent] = useState();
    const [list, setList] = useState([]);

    useEffect(() => {
        !isLoadingPrice &&
            setList(
                categories?.filter(
                    (el) => el.price_list_id === price_list[0].id
                )
            );
    }, [categories, price_list]);

    if (isLoading) return <CircularProgress />;

    function getTableData(arr) {
        return arr.map((el) => {
            return {
                title: el.category_name,
                count: el.transition_cost,
                button: (
                    <svg
                        onClick={() => {
                            setCurrent(el);
                            setEdit(true);
                        }}
                        className="edit"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5 18.4999H9.24C9.37161 18.5007 9.50207 18.4755 9.62391 18.4257C9.74574 18.3759 9.85656 18.3026 9.95 18.2099L16.87 11.2799L19.71 8.49994C19.8037 8.40698 19.8781 8.29637 19.9289 8.17452C19.9797 8.05266 20.0058 7.92195 20.0058 7.78994C20.0058 7.65793 19.9797 7.52722 19.9289 7.40536C19.8781 7.2835 19.8037 7.1729 19.71 7.07994L15.47 2.78994C15.377 2.69621 15.2664 2.62182 15.1446 2.57105C15.0227 2.52028 14.892 2.49414 14.76 2.49414C14.628 2.49414 14.4973 2.52028 14.3754 2.57105C14.2536 2.62182 14.143 2.69621 14.05 2.78994L11.23 5.61994L4.29 12.5499C4.19732 12.6434 4.12399 12.7542 4.07423 12.876C4.02446 12.9979 3.99924 13.1283 4 13.2599V17.4999C4 17.7652 4.10536 18.0195 4.29289 18.207C4.48043 18.3946 4.73478 18.4999 5 18.4999ZM14.76 4.90994L17.59 7.73994L16.17 9.15994L13.34 6.32994L14.76 4.90994ZM6 13.6699L11.93 7.73994L14.76 10.5699L8.83 16.4999H6V13.6699ZM21 20.4999H3C2.73478 20.4999 2.48043 20.6053 2.29289 20.7928C2.10536 20.9804 2 21.2347 2 21.4999C2 21.7652 2.10536 22.0195 2.29289 22.207C2.48043 22.3946 2.73478 22.4999 3 22.4999H21C21.2652 22.4999 21.5196 22.3946 21.7071 22.207C21.8946 22.0195 22 21.7652 22 21.4999C22 21.2347 21.8946 20.9804 21.7071 20.7928C21.5196 20.6053 21.2652 20.4999 21 20.4999Z"
                            fill="#DCDCDC"
                        />
                    </svg>
                ),
            };
        });
    }

    return (
        <div className="bet-manage">
            {!user?.shop ? (
                <>
                    <div className="title">
                        <h1 className="title-text_main">
                            Похоже у Вас нет магазина
                        </h1>
                        <h4 className="title-text_sub">
                            Чтобы просматривать информацию по ставкам необходимо
                            создать магазин
                        </h4>
                    </div>
                    <button
                        className="linkBtn"
                        type="button"
                        onClick={() => navigate('/add-shop')}
                    >
                        Создать магазин
                    </button>
                </>
            ) : (
                <>
                    {/* <div className="filters">
                        <div
                            onClick={() => {
                                document
                                    .querySelector('.filters')
                                    .querySelector('.active')
                                    .classList.remove('active');
                                document
                                    .getElementById('1')
                                    .classList.add('active');
                                setList(
                                    categories?.filter(
                                        (el) =>
                                            el.price_list_id ===
                                            price_list[0]?.id
                                    )
                                );
                            }}
                            id="1"
                            className="filter active"
                        >
                            Прайс 1
                        </div>
                        <div
                            onClick={() => {
                                document
                                    .querySelector('.filters')
                                    .querySelector('.active')
                                    .classList.remove('active');
                                document
                                    .getElementById('2')
                                    .classList.add('active');
                                setList(
                                    categories?.filter(
                                        (el) =>
                                            el.price_list_id ===
                                            price_list[1]?.id
                                    )
                                );
                            }}
                            id="2"
                            className="filter"
                        >
                            Прайс 2
                        </div>
                        <div
                            onClick={() => {
                                document
                                    .querySelector('.filters')
                                    .querySelector('.active')
                                    .classList.remove('active');
                                document
                                    .getElementById('3')
                                    .classList.add('active');
                                setList(
                                    categories?.filter(
                                        (el) =>
                                            el.price_list_id ===
                                            price_list[2]?.id
                                    )
                                );
                            }}
                            id="3"
                            className="filter"
                        >
                            Прайс 3
                        </div>
                    </div> */}
                    <div className="btn-wrapper">
                        <div className="category">
                            Категорий <span>{list?.length}</span>
                        </div>
                        {list?.length ? (
                            <button
                                className="add-btn"
                                type="button"
                                onClick={() => setModal(true)}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.0846 10.988H13.0121V4.91542C13.0121 4.64699 12.9054 4.38956 12.7156 4.19976C12.5258 4.00995 12.2684 3.90332 12 3.90332C11.7315 3.90332 11.4741 4.00995 11.2843 4.19976C11.0945 4.38956 10.9879 4.64699 10.9879 4.91542V10.988H4.91529C4.64687 10.988 4.38944 11.0946 4.19963 11.2844C4.00983 11.4742 3.9032 11.7317 3.9032 12.0001C3.9032 12.2685 4.00983 12.5259 4.19963 12.7157C4.38944 12.9056 4.64687 13.0122 4.91529 13.0122H10.9879V19.0848C10.9879 19.3532 11.0945 19.6106 11.2843 19.8004C11.4741 19.9902 11.7315 20.0969 12 20.0969C12.2684 20.0969 12.5258 19.9902 12.7156 19.8004C12.9054 19.6106 13.0121 19.3532 13.0121 19.0848V13.0122H19.0846C19.3531 13.0122 19.6105 12.9056 19.8003 12.7157C19.9901 12.5259 20.0967 12.2685 20.0967 12.0001C20.0967 11.7317 19.9901 11.4742 19.8003 11.2844C19.6105 11.0946 19.3531 10.988 19.0846 10.988Z"
                                        fill="#FCFFFC"
                                    />
                                </svg>
                                Применить общую ставку
                            </button>
                        ):<></>}
                    </div>
                    {list?.length ? (
                        <Table columns={columns} data={getTableData(list)} />
                    ) : (
                        ''
                    )}
                    <BetModal
                        current={list && list[0]?.price_list_id}
                        open={modal}
                        handleClose={() => setModal(false)}
                        refresh={mutateCategories}
                    />
                    <EditOneBet
                        open={edit}
                        handleClose={() => setEdit(false)}
                        current={current}
                        refresh={mutateCategories}
                    />
                </>
            )}
        </div>
    );
}

export default BetManage;
