import { CircularProgress, Modal } from '@mui/material';
import { ReactComponent as Close } from '../../assets/close.svg';
import TextField from '../../UI-kit/TextField/TextField';
import './BalanceModal.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { API_HOST } from '../../config';
import useUser from '../../hooks/useUser';

const FORMIK_INIT_VALUES = {
    sum: '',
};

const VALIDATION_SHEMA = Yup.object().shape({
    sum: Yup.number()
        .required('Это обязательное поле')
        .typeError('Только цифры')
        .min(4000, 'сумма не может быть менее 4000 рублей'),
});

function BalanceModal({ open, handleClose }) {
    const { user, isLoading } = useUser();

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        onSubmit: async (values) => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('user_token')
            );
            if (user.replenish_type === 'ENTITY') {
                const res = await fetch(
                    `${API_HOST}/api/v1/customer-panel/payment/replenish/entity`,
                    {
                        method: 'post',
                        headers: myHeaders,
                        body: JSON.stringify(values.sum),
                    }
                );
                if (res.ok) {
                    const link = document.createElement('a');
                    const blob = await res.blob();
                    link.download = 'Check.pdf';
                    link.href = window.URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    handleClose()
                } else {
                    const data = await res.json();
                    console.log(data);
                    let msg = '';
                    await data.detail.forEach((el) => {
                        msg += el.loc[1] + ' ' + el.msg + ' ';
                    });
                    alert(msg);
                }
            } else {
                const res = await fetch(
                    `${API_HOST}/api/v1/customer-panel/payment/link`,
                    {
                        method: 'post',
                        headers: myHeaders,
                        body: JSON.stringify(values.sum),
                    }
                );
                if (res.ok) {
                    const data = await res.json();
                    window.location.replace(data.formUrl);
                } else {
                    const data = await res.json();
                    console.log(data);
                    let msg = '';
                    await data.detail.forEach((el) => {
                        msg += el.loc[1] + ' ' + el.msg + ' ';
                    });
                    alert(msg);
                }
            }
        },
    });

    if (isLoading) return <CircularProgress />;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="balance-modal">
                    <h2 className="title">Пополнить баланс</h2>
                    <h5 className="sub-title">
                        Обратите внимание что сумма не может быть менее 4000
                        рублей
                    </h5>
                    <TextField
                        name="sum"
                        type="number"
                        errors={formik.errors.sum}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        touched={formik.touched}
                        placeholder={'Введите сумму пополнения'}
                    />
                    <div className="btn-wrapper">
                        <button type="submit" className="accept">
                            Пополнить
                        </button>
                        <button
                            onClick={() => {
                                formik.setValues(FORMIK_INIT_VALUES);
                                handleClose();
                            }}
                            className="cancel"
                        >
                            Отмена
                        </button>
                    </div>
                    <Close onClick={handleClose} className="close" />
                </div>
            </form>
        </Modal>
    );
}

export default BalanceModal;
