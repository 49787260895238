import { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import './Tree.scss';

export default function ControlledTreeView({ Regions, onChange }) {
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    //const [value, setValue] = useState([]);

    useEffect(() => {
        onChange(checked);
    }, [checked]);

    return (
        <div className="tree-wrapper">
            <CheckboxTree
                nodes={Regions}
                noCascade={true}
                checked={checked}
                expanded={expanded}
                // eslint-disable-next-line no-unused-vars
                onCheck={(nodes, node) => {
                    setChecked(() => nodes);
                    // node.checked
                    //     ? setValue((prev) => {
                    //           return [...prev, node.value];
                    //       })
                    //     : setValue((prev) =>
                    //           prev.filter((el) => el !== node.value)
                    //       );
                }}
                onExpand={(expanded) => setExpanded(expanded)}
            />
        </div>
    );
}
