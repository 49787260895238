import './AdminSignIn.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import CheckBox from '../../../UI-kit/CheckBox/CheckBox';
import TextField from '../../../UI-kit/TextField/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { API_HOST } from '../../../config';
import { useState } from 'react';

const FORMIK_INIT_VALUES = {
    username: '',
    password: '',
    isSavePassword: true,
};

const VALIDATION_SHEMA = Yup.object().shape({
    username: Yup.string()
        .trim()
        .matches(
            /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i,
            'Введите корректный Email'
        )
        .required('Пожалуйста, введите ваш Email'),
    password: Yup.string().required('Это обязательное поле'),
});

function AdminSignIn() {
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        onSubmit: async (values) => {
            values.username = values.username.trim();
            const params = new URLSearchParams();
            params.append('username', values.username.trim());
            params.append('password', values.password);
            const res = await fetch(
                `${API_HOST}/api/v1/auth/access-token`,
                {
                    method: 'POST',
                    body: params,
                }
            );
            if(res.ok){
                const data = await res.json();
                localStorage.setItem('admin_token', data.access_token);
                navigate('/admin');
            }else{
                setError('Указан неверный пароль или email');
            }
        },
    });

    return (
        <div className="admin_signIn">
            <div className="logo">
                <Logo />
                <span>Admin Panel</span>
            </div>
            <div className="form-wrapper">
                <div className="title">
                    <h1 className="title-mainText">Войти в аккаунт</h1>
                </div>
                {error && <h4 className='error-text'>{error}</h4>}
                <form className="form" onSubmit={formik.handleSubmit}>
                    <TextField
                        label={'Почта'}
                        placeholder={'Введите почту'}
                        name={'username'}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.username}
                        touched={formik.touched.username}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        label={'Пароль'}
                        placeholder={'Введите пароль'}
                        name={'password'}
                        type='password'
                        onBlur={formik.handleBlur}
                        errors={formik.errors.password}
                        touched={formik.touched.password}
                        onChange={formik.handleChange}
                    />
                    <div className="action-buttons">
                        <CheckBox
                            label={'Запомнить меня'}
                            className="remeber"
                        />
                        <Link to={'/password-reset'}>Забыли пароль?</Link>
                    </div>

                    <button type="submit" className="formBtn">
                        Войти
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AdminSignIn;
