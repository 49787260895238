/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import TextField from '../../../UI-kit/TextField/TextField';
import AdminTable from '../AdminTable/AdminTable';
import './ClientsActions.scss';
import DepositModal from './DepositModal';
import ClickModal from './ClickModal';
import { useFormik } from 'formik';
import PasswordModal from './PasswordModal';
import DelModal from './DelModal';
import { API_HOST } from '../../../config';
import { CircularProgress } from '@mui/material';

import usePriceLists from '../../../hooks/usePriceLists';

const columns = [
    {
        Header: 'Почта',
        accessor: 'email',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Сайт',
        accessor: 'site',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Баланс',
        accessor: 'balance',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Клики',
        accessor: 'clik',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Пароль',
        accessor: 'password',
        maxWidth: 400,
        minWidth: 80,
        width: 120,
        disableSortBy: true,
    },
    // {
    //     Header: 'Прайс-лист',
    //     accessor: 'price_list',
    //     maxWidth: 400,
    //     minWidth: 80,
    //     width: 170,
    //     disableSortBy: true,
    // },
    {
        Header: 'YML',
        accessor: 'yml',
        maxWidth: 400,
        minWidth: 80,
        width: 450,
        disableSortBy: true,
    },
    {
        Header: 'Редирект YML',
        accessor: 're_yml',
        maxWidth: 800,
        minWidth: 80,
        width: 550,
        disableSortBy: true,
    },
    {
        Header: 'Обновление Редирект YML',
        accessor: 'update_re_yml',
        maxWidth: 550,
        minWidth: 80,
        width: 250,
        disableSortBy: true,
    },

    // {
    //     Header: '',
    //     accessor: 'delete',
    //     maxWidth: 400,
    //     minWidth: 80,
    //     width: 100,
    //     disableSortBy: true,
    // },
];

function ClientsActions() {
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState('');
    const { priceLists, totalAmount, isLoading, mutate } = usePriceLists(currentPage, 10, query);
    const [current, setCurent] = useState({});
    const [balanceModal, setBalanceModal] = useState(false);
    const [confirmBalance, setConfirmBalance] = useState(false);
    const [clikModal, setClikModal] = useState(false);
    const [confirmClik, setConfirmClik] = useState(false);
    const [filter, setFilter] = useState([]);
    // const [clients, setClients] = useState([]);
    const [password, setPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [validate, setValidate] = useState(false);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            mutate();
        }
    };

    const handleOpenBalance = (el) => {
        setBalanceModal(true);
        setCurent(el);
    };
    const handleCloseBalance = () => {
        setBalanceModal(false);
        setConfirmBalance(false);
    };
    const handleOpenClik = (el) => {
        setClikModal(true);
        setCurent(el);
    };
    const handleCloseClik = () => {
        setClikModal(false);
        setConfirmBalance(false);
    };
    const handleOpenPassword = (el) => {
        setPassword(true);
        setCurent(el);
    };
    const handleClosePassword = () => {
        setPassword(false);
        setConfirmPassword(false);
    };

    useEffect(() => {
        if (!isLoading) {
            // setClients(priceLists);
            setFilter(priceLists);
            mutate();
        }

        const tableUpdate = setInterval(() => {
            mutate();
        }, 300000);

        return () => clearInterval(tableUpdate);
    }, [isLoading, validate, priceLists, mutate]);

    // const formik = useFormik({
    //     initialValues: {
    //         client: '',
    //     },
    // });

    // useEffect(() => {
    //     if (formik.values.client) {
    //         const filteredData = priceLists.filter((el) =>
    //             el.customer_email
    //                 ?.toLowerCase()
    //                 .includes(formik.values.client.toLowerCase())
    //         );
    //         setFilter(filteredData);
    //     } else {
    //         setFilter(clients);
    //     }
    // }, [formik.values.client]);


    const updateRedirectYML = async (price_list_id) => {
        const question = confirm("Вы уверены, что хотите обновить редирект YML?")
        if (!question) return;
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('admin_token')
        )
        const response = await fetch(`${API_HOST}/api/v1/manager-panel/price-list/${price_list_id}`, {
            method: "post",
            headers: myHeaders,
        })
        if (response.ok) {
            const data = await response.json();
            if (data.status === "success") {
                alert("Выгрузка успешно завершена, редирект прайс лист клиента обновлён.")
            } else {
                alert(`Произошла ошибка при выгрузке прайс листа, данные об ошибке: ${data.details}`)
            }
        } else {
            alert("Произошла ошибка без кода, свяжитесь с разработчиками и предоставьте им почту клиента.")
        }
    }

    function getTableData(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr.map((el) => ({
            email: el.customer_email,
            balance: (
                <div className="action">
                    <span>{el.balance}</span>
                    <button onClick={() => handleOpenBalance(el)}>
                        Изменить
                    </button>
                </div>
            ),
            clik: (
                <div className="action">
                    <span>{el.transitions_count}</span>
                    <button onClick={() => handleOpenClik(el)}>
                        Изменить
                    </button>
                </div>
            ),
            password: (
                <div className="action">
                    <button onClick={() => handleOpenPassword(el)}>
                        Изменить
                    </button>
                </div>
            ),
            site: el.shop_site,
            yml: el.url,
            re_yml: el.redirect_yml,
            update_re_yml: (
                <div className="action">
                    <button style={{ width: '190px' }} onClick={() => updateRedirectYML(el.price_list_id)}>
                        Обновить Редирект YML
                    </button>
                </div>
            ),
        }));
    }

    const totalPages = Math.ceil(totalAmount / 10)

    return (
        <div className="clients_actions">
            <div className="filters">
                <TextField
                    placeholder={'Клиент'}
                    onChange={(e) => {
                        setQuery(e.target.value)
                        setCurrentPage(1)
                    }}
                    name={'client'}
                />
            </div>

            {
                isLoading ? (
                    <CircularProgress style={{ marginTop: "40px" }} />
                ) : (
                    <>
                        {
                            totalPages === 0 ? (
                                <span>Не найдено</span>
                            ) : (
                                <>
                                    {<AdminTable columns={columns} data={getTableData(filter)} />}
                                    
                                    <div className="pagination" style={{ paddingBottom: "20px" }}>
                                        <button 
                                            onClick={() => handlePageChange(currentPage - 1)} 
                                            disabled={currentPage === 1}
                                        >
                                            Назад
                                        </button>
                                        <span>{currentPage} из {totalPages}</span>
                                        <button 
                                            onClick={() => handlePageChange(currentPage + 1)} 
                                            disabled={currentPage === totalPages}
                                        >
                                            Далее
                                        </button>
                                    </div>

                                    <DepositModal
                                        modal={balanceModal}
                                        handleCloseModal={handleCloseBalance}
                                        confirm={confirmBalance}
                                        setConfirm={setConfirmBalance}
                                        client={current}
                                        reValidate={() => setValidate((prev) => !prev)}
                                    />
                                    <ClickModal
                                        modal={clikModal}
                                        handleCloseModal={handleCloseClik}
                                        confirm={confirmClik}
                                        setConfirm={setConfirmClik}
                                        client={current}
                                        reValidate={() => setValidate((prev) => !prev)}
                                    />
                                    <PasswordModal
                                        modal={password}
                                        handleCloseModal={handleClosePassword}
                                        confirm={confirmPassword}
                                        setConfirm={setConfirmPassword}
                                        client={current}
                                    />
                                </>
                            )
                        } 
                    </>
                )
            }

        </div>
    );
}

export default ClientsActions;