import { Modal } from '@mui/material';
import { ReactComponent as Close } from '../../assets/close.svg';
import TariffCard from './TariffCard';
import './TariffPlan.scss';

function TariffPlan({ open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="tariff-modal">
                <h2 className="title">Выберите тарифный план</h2>
                <h5 className="sub-title">
                    После выбора тарифного плана Вас переадресует на страницу
                    выбора варианта оплаты
                </h5>
                <Close onClick={handleClose} className="close" />
                <div className="card-container">
                    <TariffCard
                        title={{
                            h1: 'Начальный',
                            h4: 'Подойдет для тестирования площадки и разработки плана',
                            price: '1500 ₽',
                        }}
                        varinat={'green'}
                        content={['Преимущество первое', 'Преимущество втрое']}
                    />
                    <TariffCard
                        title={{
                            h1: 'Продвинутый',
                            h4: 'Подойдет для тестирования площадки и разработки плана',
                            price: '1500 ₽',
                        }}
                        varinat={'blue'}
                        content={['Преимущество первое', 'Преимущество втрое']}
                    />
                    <TariffCard
                        title={{
                            h1: 'Стандартный',
                            h4: 'Подойдет для тестирования площадки и разработки плана',
                            price: '1500 ₽',
                        }}
                        varinat={'yellow'}
                        content={['Преимущество первое', 'Преимущество втрое']}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default TariffPlan;
