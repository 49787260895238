/* eslint-disable react/jsx-key */
import React from 'react';
import {
    useTable,
    useBlockLayout,
    useResizeColumns,
    usePagination,
    useSortBy,
} from 'react-table';
import './AdminTable.scss';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { styled } from '@mui/system';

const CustomTablePagination = styled(TablePagination)`
    & .${classes.toolbar} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }

    & .${classes.selectLabel} {
        margin: 0;
    }

    & .${classes.displayedRows} {
        margin: 0;

        @media (min-width: 768px) {
            margin-left: auto;
        }
    }

    & .${classes.spacer} {
        display: none;
    }

    & .${classes.actions} {
        display: flex;
        gap: 0.25rem;
    }
`;

const DataTable = (props) => {
    // Memos
    const data = React.useMemo(() => props.data, [props.data]);
    const columns = React.useMemo(() => props.columns, [props.columns]);
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        }),
        []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { pageIndex },
        page,
        pageCount,
        gotoPage,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 20
            },
            defaultColumn,
            autoResetPage: false,
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy,
        usePagination
    );

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                    {column.canSort && (
                                        <span className="SortIcon">
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g clipPath="url(#clip0_253_7195)">
                                                            <path
                                                                id="asc"
                                                                d="M10.9999 5.85075H4.9999C4.94138 5.85076 4.88389 5.83537 4.83321 5.80612C4.78253 5.77687 4.74044 5.73479 4.71118 5.68411C4.68191 5.63344 4.66651 5.57595 4.6665 5.51743C4.6665 5.45892 4.6819 5.40143 4.71116 5.35075L7.71116 0.154786C7.7428 0.107184 7.78572 0.0681425 7.83609 0.0411371C7.88647 0.0141317 7.94274 0 7.9999 0C8.05706 0 8.11333 0.0141317 8.1637 0.0411371C8.21408 0.0681425 8.257 0.107184 8.28864 0.154786L11.2886 5.35075C11.3179 5.40143 11.3333 5.45892 11.3333 5.51743C11.3333 5.57595 11.3179 5.63344 11.2886 5.68411C11.2594 5.73479 11.2173 5.77687 11.1666 5.80612C11.1159 5.83537 11.0584 5.85076 10.9999 5.85075Z"
                                                                fill="#DCDCDC"
                                                            />
                                                            <g clipPath="url(#clip1_253_7195)">
                                                                <path
                                                                    id="desk"
                                                                    d="M5.0001 10.1492L11.0001 10.1492C11.0586 10.1492 11.1161 10.1646 11.1668 10.1939C11.2175 10.2231 11.2596 10.2652 11.2888 10.3159C11.3181 10.3666 11.3335 10.424 11.3335 10.4826C11.3335 10.5411 11.3181 10.5986 11.2888 10.6492L8.28884 15.8452C8.2572 15.8928 8.21428 15.9319 8.16391 15.9589C8.11353 15.9859 8.05726 16 8.0001 16C7.94294 16 7.88667 15.9859 7.8363 15.9589C7.78592 15.9319 7.743 15.8928 7.71136 15.8452L4.71136 10.6492C4.68211 10.5986 4.6667 10.5411 4.66671 10.4826C4.66671 10.424 4.68212 10.3666 4.71138 10.3159C4.74064 10.2652 4.78273 10.2231 4.83341 10.1939C4.88409 10.1646 4.94158 10.1492 5.0001 10.1492Z"
                                                                    fill="#8adb4a"
                                                                />
                                                            </g>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_253_7195">
                                                                <rect
                                                                    width="16"
                                                                    height="15.9999"
                                                                    fill="white"
                                                                />
                                                            </clipPath>
                                                            <clipPath id="clip1_253_7195">
                                                                <rect
                                                                    width="8"
                                                                    height="8"
                                                                    fill="white"
                                                                    transform="translate(12 16) rotate(180)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g clipPath="url(#clip0_253_7195)">
                                                            <path
                                                                id="asc"
                                                                d="M10.9999 5.85075H4.9999C4.94138 5.85076 4.88389 5.83537 4.83321 5.80612C4.78253 5.77687 4.74044 5.73479 4.71118 5.68411C4.68191 5.63344 4.66651 5.57595 4.6665 5.51743C4.6665 5.45892 4.6819 5.40143 4.71116 5.35075L7.71116 0.154786C7.7428 0.107184 7.78572 0.0681425 7.83609 0.0411371C7.88647 0.0141317 7.94274 0 7.9999 0C8.05706 0 8.11333 0.0141317 8.1637 0.0411371C8.21408 0.0681425 8.257 0.107184 8.28864 0.154786L11.2886 5.35075C11.3179 5.40143 11.3333 5.45892 11.3333 5.51743C11.3333 5.57595 11.3179 5.63344 11.2886 5.68411C11.2594 5.73479 11.2173 5.77687 11.1666 5.80612C11.1159 5.83537 11.0584 5.85076 10.9999 5.85075Z"
                                                                fill="#8adb4a"
                                                            />
                                                            <g clipPath="url(#clip1_253_7195)">
                                                                <path
                                                                    id="desk"
                                                                    d="M5.0001 10.1492L11.0001 10.1492C11.0586 10.1492 11.1161 10.1646 11.1668 10.1939C11.2175 10.2231 11.2596 10.2652 11.2888 10.3159C11.3181 10.3666 11.3335 10.424 11.3335 10.4826C11.3335 10.5411 11.3181 10.5986 11.2888 10.6492L8.28884 15.8452C8.2572 15.8928 8.21428 15.9319 8.16391 15.9589C8.11353 15.9859 8.05726 16 8.0001 16C7.94294 16 7.88667 15.9859 7.8363 15.9589C7.78592 15.9319 7.743 15.8928 7.71136 15.8452L4.71136 10.6492C4.68211 10.5986 4.6667 10.5411 4.66671 10.4826C4.66671 10.424 4.68212 10.3666 4.71138 10.3159C4.74064 10.2652 4.78273 10.2231 4.83341 10.1939C4.88409 10.1646 4.94158 10.1492 5.0001 10.1492Z"
                                                                    fill="#DCDCDC"
                                                                />
                                                            </g>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_253_7195">
                                                                <rect
                                                                    width="16"
                                                                    height="15.9999"
                                                                    fill="white"
                                                                />
                                                            </clipPath>
                                                            <clipPath id="clip1_253_7195">
                                                                <rect
                                                                    width="8"
                                                                    height="8"
                                                                    fill="white"
                                                                    transform="translate(12 16) rotate(180)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )
                                            ) : (
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_253_7195)">
                                                        <path
                                                            id="asc"
                                                            d="M10.9999 5.85075H4.9999C4.94138 5.85076 4.88389 5.83537 4.83321 5.80612C4.78253 5.77687 4.74044 5.73479 4.71118 5.68411C4.68191 5.63344 4.66651 5.57595 4.6665 5.51743C4.6665 5.45892 4.6819 5.40143 4.71116 5.35075L7.71116 0.154786C7.7428 0.107184 7.78572 0.0681425 7.83609 0.0411371C7.88647 0.0141317 7.94274 0 7.9999 0C8.05706 0 8.11333 0.0141317 8.1637 0.0411371C8.21408 0.0681425 8.257 0.107184 8.28864 0.154786L11.2886 5.35075C11.3179 5.40143 11.3333 5.45892 11.3333 5.51743C11.3333 5.57595 11.3179 5.63344 11.2886 5.68411C11.2594 5.73479 11.2173 5.77687 11.1666 5.80612C11.1159 5.83537 11.0584 5.85076 10.9999 5.85075Z"
                                                            fill="#DCDCDC"
                                                        />
                                                        <g clipPath="url(#clip1_253_7195)">
                                                            <path
                                                                id="desk"
                                                                d="M5.0001 10.1492L11.0001 10.1492C11.0586 10.1492 11.1161 10.1646 11.1668 10.1939C11.2175 10.2231 11.2596 10.2652 11.2888 10.3159C11.3181 10.3666 11.3335 10.424 11.3335 10.4826C11.3335 10.5411 11.3181 10.5986 11.2888 10.6492L8.28884 15.8452C8.2572 15.8928 8.21428 15.9319 8.16391 15.9589C8.11353 15.9859 8.05726 16 8.0001 16C7.94294 16 7.88667 15.9859 7.8363 15.9589C7.78592 15.9319 7.743 15.8928 7.71136 15.8452L4.71136 10.6492C4.68211 10.5986 4.6667 10.5411 4.66671 10.4826C4.66671 10.424 4.68212 10.3666 4.71138 10.3159C4.74064 10.2652 4.78273 10.2231 4.83341 10.1939C4.88409 10.1646 4.94158 10.1492 5.0001 10.1492Z"
                                                                fill="#DCDCDC"
                                                            />
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_253_7195">
                                                            <rect
                                                                width="16"
                                                                height="15.9999"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                        <clipPath id="clip1_253_7195">
                                                            <rect
                                                                width="8"
                                                                height="8"
                                                                fill="white"
                                                                transform="translate(12 16) rotate(180)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            )}
                                        </span>
                                    )}
                                    <div
                                        {...column.getResizerProps()}
                                        className={`resizer ${
                                            column.isResizing
                                                ? 'isResizing'
                                                : ''
                                        }`}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {pageCount > 1 && (
                        <tr className="pagination">
                            <CustomTablePagination
                                //colSpan={3}
                                count={rows.length}
                                rowsPerPage={20}
                                page={pageIndex}
                                slotProps={{
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                    },
                                }}
                                onPageChange={(e, newPage) => {
                                    console.log(newPage);
                                    gotoPage(newPage);
                                }}
                            />
                        </tr>
                    )}
                </tfoot>
            </table>
        </>
    );
};

export default DataTable;
