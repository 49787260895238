import { Navigate, Outlet } from 'react-router-dom';
import Menu from '../Menu/Menu';
import './Layout.scss';
import Header from '../Header/Header';
import useUser from '../../hooks/useUser';
import { CircularProgress } from '@mui/material';

// eslint-disable-next-line react/prop-types, no-unused-vars
function Layout({ children }) {
    const { loggedOut, user, isLoading } = useUser();

    if (isLoading)
        return (
            <div>
                <CircularProgress />;
            </div>
        );
    if (loggedOut) return <Navigate to="/start" />;

    return (
        <div className="layout">
            <Menu />
            <Header user={user} />
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
}

export default Layout;
