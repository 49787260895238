import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SWRConfig } from 'swr';
import fetcher from './hooks/fetcher';
import './index.scss'
import './assets/fonts/Cygre-Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
const swrConfig = {
    fetcher,
    revalidateOnFocus: true,
    revalidateIfStale: true,
};
root.render(
    <React.StrictMode>
        <SWRConfig value={swrConfig}>
            <App />
        </SWRConfig>
    </React.StrictMode>
);
