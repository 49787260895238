import { useSelectContext } from "./SelectContext";


const Option= ({ children, value }) => {
  const { changeSelectedOption } = useSelectContext();

  return (
    <li className="select-option" onClick={() => changeSelectedOption(value, children)}>
      {children}
    </li>
  );
};

export default Option;
