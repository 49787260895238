const tree = [
    {
        label: 'Центральный',
        value: 'Центральный',
        children: [
            {
                value: 'Москва и Московская область',
                label: 'Москва и Московская область',
                children: [
                    {
                        value: 'Москва',
                        label: 'Москва',
                    },
                    {
                        value: 'Абрамцево',
                        label: 'Абрамцево',
                    },
                    {
                        value: 'Алабино',
                        label: 'Алабино',
                    },
                    {
                        value: 'Апрелевка',
                        label: 'Апрелевка',
                    },
                    {
                        value: 'Архангельское',
                        label: 'Архангельское',
                    },
                    {
                        value: 'Ашитково',
                        label: 'Ашитково',
                    },
                    {
                        value: 'Бакшеево',
                        label: 'Бакшеево',
                    },
                    {
                        value: 'Балашиха',
                        label: 'Балашиха',
                    },
                    {
                        value: 'Барыбино',
                        label: 'Барыбино',
                    },
                    {
                        value: 'Белозёрский',
                        label: 'Белозёрский',
                    },
                    {
                        value: 'Белоомут',
                        label: 'Белоомут',
                    },
                    {
                        value: 'Белые Столбы',
                        label: 'Белые Столбы',
                    },
                    {
                        value: 'Бородино (Московская обл.)',
                        label: 'Бородино (Московская обл.)',
                    },
                    {
                        value: 'Бронницы',
                        label: 'Бронницы',
                    },
                    {
                        value: 'Быково (Московская обл.)',
                        label: 'Быково (Московская обл.)',
                    },
                    {
                        value: 'Валуево',
                        label: 'Валуево',
                    },
                    {
                        value: 'Вербилки',
                        label: 'Вербилки',
                    },
                    {
                        value: 'Верея',
                        label: 'Верея',
                    },
                    {
                        value: 'Видное',
                        label: 'Видное',
                    },
                    {
                        value: 'Внуково',
                        label: 'Внуково',
                    },
                    {
                        value: 'Вождь Пролетариата',
                        label: 'Вождь Пролетариата',
                    },
                    {
                        value: 'Волоколамск',
                        label: 'Волоколамск',
                    },
                    {
                        value: 'Вороново',
                        label: 'Вороново',
                    },
                    {
                        value: 'Воскресенск',
                        label: 'Воскресенск',
                    },
                    {
                        value: 'Восточный',
                        label: 'Восточный',
                    },
                    {
                        value: 'Востряково',
                        label: 'Востряково',
                    },
                    {
                        value: 'Высоковск',
                        label: 'Высоковск',
                    },
                    {
                        value: 'Голицыно (Московская обл.)',
                        label: 'Голицыно (Московская обл.)',
                    },
                    {
                        value: 'Деденево',
                        label: 'Деденево',
                    },
                    {
                        value: 'Дедовск',
                        label: 'Дедовск',
                    },
                    {
                        value: 'Дзержинский',
                        label: 'Дзержинский',
                    },
                    {
                        value: 'Дмитров',
                        label: 'Дмитров',
                    },
                    {
                        value: 'Долгопрудный',
                        label: 'Долгопрудный',
                    },
                    {
                        value: 'Домодедово',
                        label: 'Домодедово',
                    },
                    {
                        value: 'Дорохово',
                        label: 'Дорохово',
                    },
                    {
                        value: 'Дрезна',
                        label: 'Дрезна',
                    },
                    {
                        value: 'Дубки',
                        label: 'Дубки',
                    },
                    {
                        value: 'Дубна',
                        label: 'Дубна',
                    },
                    {
                        value: 'Егорьевск',
                        label: 'Егорьевск',
                    },
                    {
                        value: 'Железнодорожный (Московск.)',
                        label: 'Железнодорожный (Московск.)',
                    },
                    {
                        value: 'Жилево',
                        label: 'Жилево',
                    },
                    {
                        value: 'Жуковка',
                        label: 'Жуковка',
                    },
                    {
                        value: 'Жуковский',
                        label: 'Жуковский',
                    },
                    {
                        value: 'Загорск',
                        label: 'Загорск',
                    },
                    {
                        value: 'Загорянский',
                        label: 'Загорянский',
                    },
                    {
                        value: 'Запрудная',
                        label: 'Запрудная',
                    },
                    {
                        value: 'Зарайск',
                        label: 'Зарайск',
                    },
                    {
                        value: 'Звенигород',
                        label: 'Звенигород',
                    },
                    {
                        value: 'Зеленоград',
                        label: 'Зеленоград',
                    },
                    {
                        value: 'Ивантеевка (Московская обл.)',
                        label: 'Ивантеевка (Московская обл.)',
                    },
                    {
                        value: 'Икша',
                        label: 'Икша',
                    },
                    {
                        value: 'Ильинский (Московская обл.)',
                        label: 'Ильинский (Московская обл.)',
                    },
                    {
                        value: 'Истра',
                        label: 'Истра',
                    },
                    {
                        value: 'Калининец',
                        label: 'Калининец',
                    },
                    {
                        value: 'Кашира',
                        label: 'Кашира',
                    },
                    {
                        value: 'Керва',
                        label: 'Керва',
                    },
                    {
                        value: 'Климовск',
                        label: 'Климовск',
                    },
                    {
                        value: 'Клин',
                        label: 'Клин',
                    },
                    {
                        value: 'Клязьма',
                        label: 'Клязьма',
                    },
                    {
                        value: 'Кожино',
                        label: 'Кожино',
                    },
                    {
                        value: 'Кокошкино',
                        label: 'Кокошкино',
                    },
                    {
                        value: 'Коломна',
                        label: 'Коломна',
                    },
                    {
                        value: 'Колюбакино',
                        label: 'Колюбакино',
                    },
                    {
                        value: 'Королев',
                        label: 'Королев',
                    },
                    {
                        value: 'Косино',
                        label: 'Косино',
                    },
                    {
                        value: 'Котельники',
                        label: 'Котельники',
                    },
                    {
                        value: 'Красково',
                        label: 'Красково',
                    },
                    {
                        value: 'Красноармейск (Московская обл.)',
                        label: 'Красноармейск (Московская обл.)',
                    },
                    {
                        value: 'Красногорск',
                        label: 'Красногорск',
                    },
                    {
                        value: 'Краснозаводск',
                        label: 'Краснозаводск',
                    },
                    {
                        value: 'Краснознаменск (Московская обл.)',
                        label: 'Краснознаменск (Московская обл.)',
                    },
                    {
                        value: 'Красный Ткач',
                        label: 'Красный Ткач',
                    },
                    {
                        value: 'Крюково',
                        label: 'Крюково',
                    },
                    {
                        value: 'Кубинка',
                        label: 'Кубинка',
                    },
                    {
                        value: 'Купавна',
                        label: 'Купавна',
                    },
                    {
                        value: 'Куровское',
                        label: 'Куровское',
                    },
                    {
                        value: 'Лесной Городок',
                        label: 'Лесной Городок',
                    },
                    {
                        value: 'Ликино-Дулево',
                        label: 'Ликино-Дулево',
                    },
                    {
                        value: 'Лобня',
                        label: 'Лобня',
                    },
                    {
                        value: 'Лопатинский',
                        label: 'Лопатинский',
                    },
                    {
                        value: 'Лосино-Петровский',
                        label: 'Лосино-Петровский',
                    },
                    {
                        value: 'Лотошино',
                        label: 'Лотошино',
                    },
                    {
                        value: 'Лукино',
                        label: 'Лукино',
                    },
                    {
                        value: 'Луховицы',
                        label: 'Луховицы',
                    },
                    {
                        value: 'Лыткарино',
                        label: 'Лыткарино',
                    },
                    {
                        value: 'Львовский',
                        label: 'Львовский',
                    },
                    {
                        value: 'Люберцы',
                        label: 'Люберцы',
                    },
                    {
                        value: 'Малаховка',
                        label: 'Малаховка',
                    },
                    {
                        value: 'Михайловское',
                        label: 'Михайловское',
                    },
                    {
                        value: 'Михнево',
                        label: 'Михнево',
                    },
                    {
                        value: 'Можайск',
                        label: 'Можайск',
                    },
                    {
                        value: 'Монино',
                        label: 'Монино',
                    },
                    {
                        value: 'Московский',
                        label: 'Московский',
                    },
                    {
                        value: 'Муханово',
                        label: 'Муханово',
                    },
                    {
                        value: 'Мытищи',
                        label: 'Мытищи',
                    },
                    {
                        value: 'Нарофоминск',
                        label: 'Нарофоминск',
                    },
                    {
                        value: 'Нахабино',
                        label: 'Нахабино',
                    },
                    {
                        value: 'Некрасовка',
                        label: 'Некрасовка',
                    },
                    {
                        value: 'Немчиновка',
                        label: 'Немчиновка',
                    },
                    {
                        value: 'Новобратцевский',
                        label: 'Новобратцевский',
                    },
                    {
                        value: 'Новоподрезково',
                        label: 'Новоподрезково',
                    },
                    {
                        value: 'Ногинск',
                        label: 'Ногинск',
                    },
                    {
                        value: 'Обухово',
                        label: 'Обухово',
                    },
                    {
                        value: 'Одинцово',
                        label: 'Одинцово',
                    },
                    {
                        value: 'Ожерелье',
                        label: 'Ожерелье',
                    },
                    {
                        value: 'Озеры',
                        label: 'Озеры',
                    },
                    {
                        value: 'Октябрьский (Московская обл.)',
                        label: 'Октябрьский (Московская обл.)',
                    },
                    {
                        value: 'Опалиха',
                        label: 'Опалиха',
                    },
                    {
                        value: 'Орехово-Зуево',
                        label: 'Орехово-Зуево',
                    },
                    {
                        value: 'Павловский Посад',
                        label: 'Павловский Посад',
                    },
                    {
                        value: 'Первомайский (Московская обл.)',
                        label: 'Первомайский (Московская обл.)',
                    },
                    {
                        value: 'Пески (Московская обл.)',
                        label: 'Пески (Московская обл.)',
                    },
                    {
                        value: 'Пироговский',
                        label: 'Пироговский',
                    },
                    {
                        value: 'Подольск',
                        label: 'Подольск',
                    },
                    {
                        value: 'Полушкино',
                        label: 'Полушкино',
                    },
                    {
                        value: 'Правдинский',
                        label: 'Правдинский',
                    },
                    {
                        value: 'Привокзальный',
                        label: 'Привокзальный',
                    },
                    {
                        value: 'Пролетарский',
                        label: 'Пролетарский',
                    },
                    {
                        value: 'Протвино (Московская обл.)',
                        label: 'Протвино (Московская обл.)',
                    },
                    {
                        value: 'Пушкино',
                        label: 'Пушкино',
                    },
                    {
                        value: 'Пущино',
                        label: 'Пущино',
                    },
                    {
                        value: 'Раменское',
                        label: 'Раменское',
                    },
                    {
                        value: 'Реутов',
                        label: 'Реутов',
                    },
                    {
                        value: 'Решетниково',
                        label: 'Решетниково',
                    },
                    {
                        value: 'Родники (Московская обл.)',
                        label: 'Родники (Московская обл.)',
                    },
                    {
                        value: 'Рошаль',
                        label: 'Рошаль',
                    },
                    {
                        value: 'Рублево',
                        label: 'Рублево',
                    },
                    {
                        value: 'Руза',
                        label: 'Руза',
                    },
                    {
                        value: 'Салтыковка',
                        label: 'Салтыковка',
                    },
                    {
                        value: 'Северный',
                        label: 'Северный',
                    },
                    {
                        value: 'Сергиев Посад',
                        label: 'Сергиев Посад',
                    },
                    {
                        value: 'Серебряные Пруды',
                        label: 'Серебряные Пруды',
                    },
                    {
                        value: 'Серпухов',
                        label: 'Серпухов',
                    },
                    {
                        value: 'Солнечногорск',
                        label: 'Солнечногорск',
                    },
                    {
                        value: 'Солнцево',
                        label: 'Солнцево',
                    },
                    {
                        value: 'Софрино',
                        label: 'Софрино',
                    },
                    {
                        value: 'Старая Купавна',
                        label: 'Старая Купавна',
                    },
                    {
                        value: 'Старбеево',
                        label: 'Старбеево',
                    },
                    {
                        value: 'Ступино',
                        label: 'Ступино',
                    },
                    {
                        value: 'Сходня',
                        label: 'Сходня',
                    },
                    {
                        value: 'Талдом',
                        label: 'Талдом',
                    },
                    {
                        value: 'Текстильщик',
                        label: 'Текстильщик',
                    },
                    {
                        value: 'Темпы',
                        label: 'Темпы',
                    },
                    {
                        value: 'Томилино',
                        label: 'Томилино',
                    },
                    {
                        value: 'Троицк',
                        label: 'Троицк',
                    },
                    {
                        value: 'Туголесский Бор',
                        label: 'Туголесский Бор',
                    },
                    {
                        value: 'Тучково',
                        label: 'Тучково',
                    },
                    {
                        value: 'Уваровка',
                        label: 'Уваровка',
                    },
                    {
                        value: 'Удельная',
                        label: 'Удельная',
                    },
                    {
                        value: 'Успенское',
                        label: 'Успенское',
                    },
                    {
                        value: 'Фирсановка',
                        label: 'Фирсановка',
                    },
                    {
                        value: 'Фрязино',
                        label: 'Фрязино',
                    },
                    {
                        value: 'Фряново',
                        label: 'Фряново',
                    },
                    {
                        value: 'Химки',
                        label: 'Химки',
                    },
                    {
                        value: 'Хотьково',
                        label: 'Хотьково',
                    },
                    {
                        value: 'Черкизово',
                        label: 'Черкизово',
                    },
                    {
                        value: 'Черноголовка',
                        label: 'Черноголовка',
                    },
                    {
                        value: 'Черусти',
                        label: 'Черусти',
                    },
                    {
                        value: 'Чехов',
                        label: 'Чехов',
                    },
                    {
                        value: 'Шарапово',
                        label: 'Шарапово',
                    },
                    {
                        value: 'Шатура',
                        label: 'Шатура',
                    },
                    {
                        value: 'Шатурторф',
                        label: 'Шатурторф',
                    },
                    {
                        value: 'Шаховская',
                        label: 'Шаховская',
                    },
                    {
                        value: 'Шереметьевский',
                        label: 'Шереметьевский',
                    },
                    {
                        value: 'Щелково',
                        label: 'Щелково',
                    },
                    {
                        value: 'Щербинка',
                        label: 'Щербинка',
                    },
                    {
                        value: 'Электрогорск',
                        label: 'Электрогорск',
                    },
                    {
                        value: 'Электросталь',
                        label: 'Электросталь',
                    },
                    {
                        value: 'Электроугли',
                        label: 'Электроугли',
                    },
                    {
                        value: 'Юбилейный (Московская обл.)',
                        label: 'Юбилейный (Московская обл.)',
                    },
                    {
                        value: 'Яхрома',
                        label: 'Яхрома',
                    },
                ],
            },
            {
                value: 'Белгородская область',
                label: 'Белгородская область',
                children: [
                    {
                        value: 'Алексеевка (Белгородская обл.)',
                        label: 'Алексеевка (Белгородская обл.)',
                    },
                    {
                        value: 'Белгород',
                        label: 'Белгород',
                    },
                    {
                        value: 'Борисовка',
                        label: 'Борисовка',
                    },
                    {
                        value: 'Валуйки',
                        label: 'Валуйки',
                    },
                    {
                        value: 'Вейделевка',
                        label: 'Вейделевка',
                    },
                    {
                        value: 'Волоконовка',
                        label: 'Волоконовка',
                    },
                    {
                        value: 'Грайворон',
                        label: 'Грайворон',
                    },
                    {
                        value: 'Губкин',
                        label: 'Губкин',
                    },
                    {
                        value: 'Ивня',
                        label: 'Ивня',
                    },
                    {
                        value: 'Короча',
                        label: 'Короча',
                    },
                    {
                        value: 'Красногвардейское (Белгород.)',
                        label: 'Красногвардейское (Белгород.)',
                    },
                    {
                        value: 'Новый Оскол',
                        label: 'Новый Оскол',
                    },
                    {
                        value: 'Ракитное',
                        label: 'Ракитное',
                    },
                    {
                        value: 'Ровеньки',
                        label: 'Ровеньки',
                    },
                    {
                        value: 'Старый Оскол',
                        label: 'Старый Оскол',
                    },
                    {
                        value: 'Строитель',
                        label: 'Строитель',
                    },
                    {
                        value: 'Чернянка',
                        label: 'Чернянка',
                    },
                    {
                        value: 'Шебекино',
                        label: 'Шебекино',
                    },
                ],
            },
            {
                value: 'Брянская область',
                label: 'Брянская область',
                children: [
                    {
                        value: 'Алтухово',
                        label: 'Алтухово',
                    },
                    {
                        value: 'Белая Березка',
                        label: 'Белая Березка',
                    },
                    {
                        value: 'Белые Берега',
                        label: 'Белые Берега',
                    },
                    {
                        value: 'Большое Полпино',
                        label: 'Большое Полпино',
                    },
                    {
                        value: 'Брянск',
                        label: 'Брянск',
                    },
                    {
                        value: 'Бытошь',
                        label: 'Бытошь',
                    },
                    {
                        value: 'Выгоничи',
                        label: 'Выгоничи',
                    },
                    {
                        value: 'Вышков',
                        label: 'Вышков',
                    },
                    {
                        value: 'Гордеевка',
                        label: 'Гордеевка',
                    },
                    {
                        value: 'Дубровка (Брянская обл.)',
                        label: 'Дубровка (Брянская обл.)',
                    },
                    {
                        value: 'Дятьково',
                        label: 'Дятьково',
                    },
                    {
                        value: 'Жирятино',
                        label: 'Жирятино',
                    },
                    {
                        value: 'Злынка',
                        label: 'Злынка',
                    },
                    {
                        value: 'Ивот',
                        label: 'Ивот',
                    },
                    {
                        value: 'Карачев',
                        label: 'Карачев',
                    },
                    {
                        value: 'Клетня',
                        label: 'Клетня',
                    },
                    {
                        value: 'Климово',
                        label: 'Климово',
                    },
                    {
                        value: 'Клинцы',
                        label: 'Клинцы',
                    },
                    {
                        value: 'Кокаревка',
                        label: 'Кокаревка',
                    },
                    {
                        value: 'Комаричи',
                        label: 'Комаричи',
                    },
                    {
                        value: 'Красная Гора',
                        label: 'Красная Гора',
                    },
                    {
                        value: 'Локоть (Брянская обл.)',
                        label: 'Локоть (Брянская обл.)',
                    },
                    {
                        value: 'Мглин',
                        label: 'Мглин',
                    },
                    {
                        value: 'Навля',
                        label: 'Навля',
                    },
                    {
                        value: 'Новозыбков',
                        label: 'Новозыбков',
                    },
                    {
                        value: 'Погар',
                        label: 'Погар',
                    },
                    {
                        value: 'Почеп',
                        label: 'Почеп',
                    },
                    {
                        value: 'Ржаница',
                        label: 'Ржаница',
                    },
                    {
                        value: 'Рогнедино',
                        label: 'Рогнедино',
                    },
                    {
                        value: 'Севск',
                        label: 'Севск',
                    },
                    {
                        value: 'Стародуб',
                        label: 'Стародуб',
                    },
                    {
                        value: 'Суземка',
                        label: 'Суземка',
                    },
                    {
                        value: 'Сураж',
                        label: 'Сураж',
                    },
                    {
                        value: 'Трубчевск',
                        label: 'Трубчевск',
                    },
                    {
                        value: 'Унеча',
                        label: 'Унеча',
                    },
                ],
            },
            {
                value: 'Владимирская область',
                label: 'Владимирская область',
                children: [
                    {
                        value: 'Александров',
                        label: 'Александров',
                    },
                    {
                        value: 'Андреево',
                        label: 'Андреево',
                    },
                    {
                        value: 'Анопино',
                        label: 'Анопино',
                    },
                    {
                        value: 'Бавлены',
                        label: 'Бавлены',
                    },
                    {
                        value: 'Балакирево',
                        label: 'Балакирево',
                    },
                    {
                        value: 'Боголюбово',
                        label: 'Боголюбово',
                    },
                    {
                        value: 'Великодворский',
                        label: 'Великодворский',
                    },
                    {
                        value: 'Вербовский',
                        label: 'Вербовский',
                    },
                    {
                        value: 'Владимир',
                        label: 'Владимир',
                    },
                    {
                        value: 'Вязники',
                        label: 'Вязники',
                    },
                    {
                        value: 'Городищи (Владимирская обл.)',
                        label: 'Городищи (Владимирская обл.)',
                    },
                    {
                        value: 'Гороховец',
                        label: 'Гороховец',
                    },
                    {
                        value: 'Гусевский',
                        label: 'Гусевский',
                    },
                    {
                        value: 'Гусь Хрустальный',
                        label: 'Гусь Хрустальный',
                    },
                    {
                        value: 'Гусь-Хрустальный',
                        label: 'Гусь-Хрустальный',
                    },
                    {
                        value: 'Золотково',
                        label: 'Золотково',
                    },
                    {
                        value: 'Иванищи',
                        label: 'Иванищи',
                    },
                    {
                        value: 'Камешково',
                        label: 'Камешково',
                    },
                    {
                        value: 'Карабаново',
                        label: 'Карабаново',
                    },
                    {
                        value: 'Киржач',
                        label: 'Киржач',
                    },
                    {
                        value: 'Ковров',
                        label: 'Ковров',
                    },
                    {
                        value: 'Кольчугино',
                        label: 'Кольчугино',
                    },
                    {
                        value: 'Красная Горбатка',
                        label: 'Красная Горбатка',
                    },
                    {
                        value: 'Лакинск',
                        label: 'Лакинск',
                    },
                    {
                        value: 'Меленки',
                        label: 'Меленки',
                    },
                    {
                        value: 'Муром',
                        label: 'Муром',
                    },
                    {
                        value: 'Петушки',
                        label: 'Петушки',
                    },
                    {
                        value: 'Покров',
                        label: 'Покров',
                    },
                    {
                        value: 'Радужный (Владимирская обл.)',
                        label: 'Радужный (Владимирская обл.)',
                    },
                    {
                        value: 'Собинка',
                        label: 'Собинка',
                    },
                    {
                        value: 'Судогда',
                        label: 'Судогда',
                    },
                    {
                        value: 'Суздаль',
                        label: 'Суздаль',
                    },
                    {
                        value: 'Юрьев-Польский',
                        label: 'Юрьев-Польский',
                    },
                ],
            },
            {
                value: 'Воронежская область',
                label: 'Воронежская область',
                children: [
                    {
                        value: 'Калач',
                        label: 'Калач',
                    },
                    {
                        value: 'Анна',
                        label: 'Анна',
                    },
                    {
                        value: 'Бобров',
                        label: 'Бобров',
                    },
                    {
                        value: 'Богучар',
                        label: 'Богучар',
                    },
                    {
                        value: 'Борисоглебск',
                        label: 'Борисоглебск',
                    },
                    {
                        value: 'Бутурлиновка',
                        label: 'Бутурлиновка',
                    },
                    {
                        value: 'Верхний Мамон',
                        label: 'Верхний Мамон',
                    },
                    {
                        value: 'Верхняя Хава',
                        label: 'Верхняя Хава',
                    },
                    {
                        value: 'Воробьевка',
                        label: 'Воробьевка',
                    },
                    {
                        value: 'Воронеж',
                        label: 'Воронеж',
                    },
                    {
                        value: 'Грибановский',
                        label: 'Грибановский',
                    },
                    {
                        value: 'Давыдовка',
                        label: 'Давыдовка',
                    },
                    {
                        value: 'Елань-Коленовский',
                        label: 'Елань-Коленовский',
                    },
                    {
                        value: 'Кантемировка',
                        label: 'Кантемировка',
                    },
                    {
                        value: 'Лиски (Воронежская обл.)',
                        label: 'Лиски (Воронежская обл.)',
                    },
                    {
                        value: 'Нижнедевицк',
                        label: 'Нижнедевицк',
                    },
                    {
                        value: 'Новая Усмань',
                        label: 'Новая Усмань',
                    },
                    {
                        value: 'Нововоронеж',
                        label: 'Нововоронеж',
                    },
                    {
                        value: 'Новохоперск',
                        label: 'Новохоперск',
                    },
                    {
                        value: 'Ольховатка',
                        label: 'Ольховатка',
                    },
                    {
                        value: 'Острогожск',
                        label: 'Острогожск',
                    },
                    {
                        value: 'Павловск (Воронежская обл.)',
                        label: 'Павловск (Воронежская обл.)',
                    },
                    {
                        value: 'Панино',
                        label: 'Панино',
                    },
                    {
                        value: 'Петропавловка',
                        label: 'Петропавловка',
                    },
                    {
                        value: 'Поворино',
                        label: 'Поворино',
                    },
                    {
                        value: 'Подгоренский',
                        label: 'Подгоренский',
                    },
                    {
                        value: 'Рамонь',
                        label: 'Рамонь',
                    },
                    {
                        value: 'Репьевка',
                        label: 'Репьевка',
                    },
                    {
                        value: 'Россошь',
                        label: 'Россошь',
                    },
                    {
                        value: 'Семилуки',
                        label: 'Семилуки',
                    },
                    {
                        value: 'Таловая',
                        label: 'Таловая',
                    },
                    {
                        value: 'Терновка',
                        label: 'Терновка',
                    },
                    {
                        value: 'Хохольский',
                        label: 'Хохольский',
                    },
                    {
                        value: 'Эртиль',
                        label: 'Эртиль',
                    },
                ],
            },
            {
                value: 'Ивановская область',
                label: 'Ивановская область',
                children: [
                    {
                        value: 'Архиповка',
                        label: 'Архиповка',
                    },
                    {
                        value: 'Верхний Ландех',
                        label: 'Верхний Ландех',
                    },
                    {
                        value: 'Вичуга',
                        label: 'Вичуга',
                    },
                    {
                        value: 'Гаврилов Посад',
                        label: 'Гаврилов Посад',
                    },
                    {
                        value: 'Долматовский',
                        label: 'Долматовский',
                    },
                    {
                        value: 'Дуляпино',
                        label: 'Дуляпино',
                    },
                    {
                        value: 'Заволжск',
                        label: 'Заволжск',
                    },
                    {
                        value: 'Заречный (Ивановская обл.)',
                        label: 'Заречный (Ивановская обл.)',
                    },
                    {
                        value: 'Иваново',
                        label: 'Иваново',
                    },
                    {
                        value: 'Иваньковский',
                        label: 'Иваньковский',
                    },
                    {
                        value: 'Ильинское-Хованское',
                        label: 'Ильинское-Хованское',
                    },
                    {
                        value: 'Каминский',
                        label: 'Каминский',
                    },
                    {
                        value: 'Кинешма',
                        label: 'Кинешма',
                    },
                    {
                        value: 'Комсомольск',
                        label: 'Комсомольск',
                    },
                    {
                        value: 'Кохма',
                        label: 'Кохма',
                    },
                    {
                        value: 'Лух',
                        label: 'Лух',
                    },
                    {
                        value: 'Палех',
                        label: 'Палех',
                    },
                    {
                        value: 'Пестяки',
                        label: 'Пестяки',
                    },
                    {
                        value: 'Приволжск',
                        label: 'Приволжск',
                    },
                    {
                        value: 'Пучеж',
                        label: 'Пучеж',
                    },
                    {
                        value: 'Родники (Ивановская обл.)',
                        label: 'Родники (Ивановская обл.)',
                    },
                    {
                        value: 'Савино',
                        label: 'Савино',
                    },
                    {
                        value: 'Сокольское',
                        label: 'Сокольское',
                    },
                    {
                        value: 'Тейково',
                        label: 'Тейково',
                    },
                    {
                        value: 'Фурманов',
                        label: 'Фурманов',
                    },
                    {
                        value: 'Шуя',
                        label: 'Шуя',
                    },
                    {
                        value: 'Южа',
                        label: 'Южа',
                    },
                    {
                        value: 'Юрьевец',
                        label: 'Юрьевец',
                    },
                ],
            },
            {
                value: 'Калужская область',
                label: 'Калужская область',
                children: [
                    {
                        value: 'Бабынино',
                        label: 'Бабынино',
                    },
                    {
                        value: 'Балабаново',
                        label: 'Балабаново',
                    },
                    {
                        value: 'Барятино',
                        label: 'Барятино',
                    },
                    {
                        value: 'Белоусово',
                        label: 'Белоусово',
                    },
                    {
                        value: 'Бетлица',
                        label: 'Бетлица',
                    },
                    {
                        value: 'Боровск',
                        label: 'Боровск',
                    },
                    {
                        value: 'Дудоровский',
                        label: 'Дудоровский',
                    },
                    {
                        value: 'Думиничи',
                        label: 'Думиничи',
                    },
                    {
                        value: 'Еленский',
                        label: 'Еленский',
                    },
                    {
                        value: 'Жиздра',
                        label: 'Жиздра',
                    },
                    {
                        value: 'Жуков',
                        label: 'Жуков',
                    },
                    {
                        value: 'Износки',
                        label: 'Износки',
                    },
                    {
                        value: 'Калуга',
                        label: 'Калуга',
                    },
                    {
                        value: 'Киров (Калужская обл.)',
                        label: 'Киров (Калужская обл.)',
                    },
                    {
                        value: 'Козельск',
                        label: 'Козельск',
                    },
                    {
                        value: 'Кондрово',
                        label: 'Кондрово',
                    },
                    {
                        value: 'Кремёнки',
                        label: 'Кремёнки',
                    },
                    {
                        value: 'Людиново',
                        label: 'Людиново',
                    },
                    {
                        value: 'Малоярославец',
                        label: 'Малоярославец',
                    },
                    {
                        value: 'Медынь',
                        label: 'Медынь',
                    },
                    {
                        value: 'Мещовск',
                        label: 'Мещовск',
                    },
                    {
                        value: 'Мосальск',
                        label: 'Мосальск',
                    },
                    {
                        value: 'Обнинск',
                        label: 'Обнинск',
                    },
                    {
                        value: 'Перемышль',
                        label: 'Перемышль',
                    },
                    {
                        value: 'Спас-Деменск',
                        label: 'Спас-Деменск',
                    },
                    {
                        value: 'Сухиничи',
                        label: 'Сухиничи',
                    },
                    {
                        value: 'Таруса',
                        label: 'Таруса',
                    },
                    {
                        value: 'Ульяново',
                        label: 'Ульяново',
                    },
                    {
                        value: 'Ферзиково',
                        label: 'Ферзиково',
                    },
                    {
                        value: 'Хвастовичи',
                        label: 'Хвастовичи',
                    },
                    {
                        value: 'Юхнов',
                        label: 'Юхнов',
                    },
                ],
            },
            {
                value: 'Костромская область',
                label: 'Костромская область',
                children: [
                    {
                        value: 'Антропово',
                        label: 'Антропово',
                    },
                    {
                        value: 'Боговарово',
                        label: 'Боговарово',
                    },
                    {
                        value: 'Буй',
                        label: 'Буй',
                    },
                    {
                        value: 'Волгореченск',
                        label: 'Волгореченск',
                    },
                    {
                        value: 'Галич',
                        label: 'Галич',
                    },
                    {
                        value: 'Горчуха',
                        label: 'Горчуха',
                    },
                    {
                        value: 'Зебляки',
                        label: 'Зебляки',
                    },
                    {
                        value: 'Кадый',
                        label: 'Кадый',
                    },
                    {
                        value: 'Кологрив',
                        label: 'Кологрив',
                    },
                    {
                        value: 'Кострома',
                        label: 'Кострома',
                    },
                    {
                        value: 'Красное-на-Волге',
                        label: 'Красное-на-Волге',
                    },
                    {
                        value: 'Макарьев',
                        label: 'Макарьев',
                    },
                    {
                        value: 'Мантурово',
                        label: 'Мантурово',
                    },
                    {
                        value: 'Нерехта',
                        label: 'Нерехта',
                    },
                    {
                        value: 'Нея',
                        label: 'Нея',
                    },
                    {
                        value: 'Островское',
                        label: 'Островское',
                    },
                    {
                        value: 'Павино',
                        label: 'Павино',
                    },
                    {
                        value: 'Парфентьево',
                        label: 'Парфентьево',
                    },
                    {
                        value: 'Поназырево',
                        label: 'Поназырево',
                    },
                    {
                        value: 'Солигалич',
                        label: 'Солигалич',
                    },
                    {
                        value: 'Судиславль',
                        label: 'Судиславль',
                    },
                    {
                        value: 'Сусанино',
                        label: 'Сусанино',
                    },
                    {
                        value: 'Чухлома',
                        label: 'Чухлома',
                    },
                    {
                        value: 'Шарья',
                        label: 'Шарья',
                    },
                    {
                        value: 'Шемятино',
                        label: 'Шемятино',
                    },
                ],
            },
            {
                value: 'Курская область',
                label: 'Курская область',
                children: [
                    {
                        value: 'Альменево',
                        label: 'Альменево',
                    },
                    {
                        value: 'Белая',
                        label: 'Белая',
                    },
                    {
                        value: 'Большое Солдатское',
                        label: 'Большое Солдатское',
                    },
                    {
                        value: 'Глушково',
                        label: 'Глушково',
                    },
                    {
                        value: 'Горшечное',
                        label: 'Горшечное',
                    },
                    {
                        value: 'Дмитриев-Льговский',
                        label: 'Дмитриев-Льговский',
                    },
                    {
                        value: 'Железногорск',
                        label: 'Железногорск',
                    },
                    {
                        value: 'Золотухино',
                        label: 'Золотухино',
                    },
                    {
                        value: 'Касторное',
                        label: 'Касторное',
                    },
                    {
                        value: 'Конышевка',
                        label: 'Конышевка',
                    },
                    {
                        value: 'Коренево',
                        label: 'Коренево',
                    },
                    {
                        value: 'Курск',
                        label: 'Курск',
                    },
                    {
                        value: 'Курчатов',
                        label: 'Курчатов',
                    },
                    {
                        value: 'Кшенский',
                        label: 'Кшенский',
                    },
                    {
                        value: 'Льгов',
                        label: 'Льгов',
                    },
                    {
                        value: 'Медвенка',
                        label: 'Медвенка',
                    },
                    {
                        value: 'Обоянь',
                        label: 'Обоянь',
                    },
                    {
                        value: 'Поныри',
                        label: 'Поныри',
                    },
                    {
                        value: 'Пристень',
                        label: 'Пристень',
                    },
                    {
                        value: 'Прямицыно',
                        label: 'Прямицыно',
                    },
                    {
                        value: 'Рыльск',
                        label: 'Рыльск',
                    },
                    {
                        value: 'Суджа',
                        label: 'Суджа',
                    },
                    {
                        value: 'Тим',
                        label: 'Тим',
                    },
                    {
                        value: 'Фатеж',
                        label: 'Фатеж',
                    },
                    {
                        value: 'Хомутовка',
                        label: 'Хомутовка',
                    },
                    {
                        value: 'Черемисиново',
                        label: 'Черемисиново',
                    },
                    {
                        value: 'Щигры',
                        label: 'Щигры',
                    },
                ],
            },
            {
                value: 'Липецкая область',
                label: 'Липецкая область',
                children: [
                    {
                        value: 'Грязи',
                        label: 'Грязи',
                    },
                    {
                        value: 'Данков',
                        label: 'Данков',
                    },
                    {
                        value: 'Доброе',
                        label: 'Доброе',
                    },
                    {
                        value: 'Долгоруково',
                        label: 'Долгоруково',
                    },
                    {
                        value: 'Елец',
                        label: 'Елец',
                    },
                    {
                        value: 'Задонск',
                        label: 'Задонск',
                    },
                    {
                        value: 'Измалково',
                        label: 'Измалково',
                    },
                    {
                        value: 'Казинка',
                        label: 'Казинка',
                    },
                    {
                        value: 'Лебедянь',
                        label: 'Лебедянь',
                    },
                    {
                        value: 'Лев Толстой',
                        label: 'Лев Толстой',
                    },
                    {
                        value: 'Липецк',
                        label: 'Липецк',
                    },
                    {
                        value: 'Тербуны',
                        label: 'Тербуны',
                    },
                    {
                        value: 'Усмань',
                        label: 'Усмань',
                    },
                    {
                        value: 'Хлевное',
                        label: 'Хлевное',
                    },
                    {
                        value: 'Чаплыгин',
                        label: 'Чаплыгин',
                    },
                ],
            },
            {
                value: 'Орловская область',
                label: 'Орловская область',
                children: [
                    {
                        value: 'Болхов',
                        label: 'Болхов',
                    },
                    {
                        value: 'Верховье',
                        label: 'Верховье',
                    },
                    {
                        value: 'Глазуновка',
                        label: 'Глазуновка',
                    },
                    {
                        value: 'Дмитровск-Орловский',
                        label: 'Дмитровск-Орловский',
                    },
                    {
                        value: 'Долгое',
                        label: 'Долгое',
                    },
                    {
                        value: 'Залегощь',
                        label: 'Залегощь',
                    },
                    {
                        value: 'Змиевка',
                        label: 'Змиевка',
                    },
                    {
                        value: 'Знаменское (Орловская обл.)',
                        label: 'Знаменское (Орловская обл.)',
                    },
                    {
                        value: 'Колпны',
                        label: 'Колпны',
                    },
                    {
                        value: 'Красная Заря',
                        label: 'Красная Заря',
                    },
                    {
                        value: 'Кромы',
                        label: 'Кромы',
                    },
                    {
                        value: 'Ливны',
                        label: 'Ливны',
                    },
                    {
                        value: 'Малоархангельск',
                        label: 'Малоархангельск',
                    },
                    {
                        value: 'Мценск',
                        label: 'Мценск',
                    },
                    {
                        value: 'Нарышкино',
                        label: 'Нарышкино',
                    },
                    {
                        value: 'Новосиль',
                        label: 'Новосиль',
                    },
                    {
                        value: 'Орел',
                        label: 'Орел',
                    },
                    {
                        value: 'Сосково',
                        label: 'Сосково',
                    },
                    {
                        value: 'Тросна',
                        label: 'Тросна',
                    },
                    {
                        value: 'Хомутово',
                        label: 'Хомутово',
                    },
                    {
                        value: 'Хотынец',
                        label: 'Хотынец',
                    },
                    {
                        value: 'Шаблыкино',
                        label: 'Шаблыкино',
                    },
                ],
            },
            {
                value: 'Рязанская область',
                label: 'Рязанская область',
                children: [
                    {
                        value: 'Александро-Невский',
                        label: 'Александро-Невский',
                    },
                    {
                        value: 'Горняк',
                        label: 'Горняк',
                    },
                    {
                        value: 'Гусь Железный',
                        label: 'Гусь Железный',
                    },
                    {
                        value: 'Елатьма',
                        label: 'Елатьма',
                    },
                    {
                        value: 'Ермишь',
                        label: 'Ермишь',
                    },
                    {
                        value: 'Заречный (Рязанская обл.)',
                        label: 'Заречный (Рязанская обл.)',
                    },
                    {
                        value: 'Захарово',
                        label: 'Захарово',
                    },
                    {
                        value: 'Кадом',
                        label: 'Кадом',
                    },
                    {
                        value: 'Касимов',
                        label: 'Касимов',
                    },
                    {
                        value: 'Кораблино',
                        label: 'Кораблино',
                    },
                    {
                        value: 'Милославское',
                        label: 'Милославское',
                    },
                    {
                        value: 'Михайлов',
                        label: 'Михайлов',
                    },
                    {
                        value: 'Новомичуринск',
                        label: 'Новомичуринск',
                    },
                    {
                        value: 'Пителино',
                        label: 'Пителино',
                    },
                    {
                        value: 'Пронск',
                        label: 'Пронск',
                    },
                    {
                        value: 'Путятино',
                        label: 'Путятино',
                    },
                    {
                        value: 'Рыбное',
                        label: 'Рыбное',
                    },
                    {
                        value: 'Ряжск',
                        label: 'Ряжск',
                    },
                    {
                        value: 'Рязань',
                        label: 'Рязань',
                    },
                    {
                        value: 'Сапожок',
                        label: 'Сапожок',
                    },
                    {
                        value: 'Сараи',
                        label: 'Сараи',
                    },
                    {
                        value: 'Сасово',
                        label: 'Сасово',
                    },
                    {
                        value: 'Скопин',
                        label: 'Скопин',
                    },
                    {
                        value: 'Спас-Клепики',
                        label: 'Спас-Клепики',
                    },
                    {
                        value: 'Спасск-Рязанский',
                        label: 'Спасск-Рязанский',
                    },
                    {
                        value: 'Старожилово',
                        label: 'Старожилово',
                    },
                    {
                        value: 'Ухолово',
                        label: 'Ухолово',
                    },
                    {
                        value: 'Чучково',
                        label: 'Чучково',
                    },
                    {
                        value: 'Шацк',
                        label: 'Шацк',
                    },
                    {
                        value: 'Шилово',
                        label: 'Шилово',
                    },
                ],
            },
            {
                value: 'Смоленская область',
                label: 'Смоленская область',
                children: [
                    {
                        value: 'Велиж',
                        label: 'Велиж',
                    },
                    {
                        value: 'Верхнеднепровский',
                        label: 'Верхнеднепровский',
                    },
                    {
                        value: 'Ворга',
                        label: 'Ворга',
                    },
                    {
                        value: 'Вязьма',
                        label: 'Вязьма',
                    },
                    {
                        value: 'Гагарин',
                        label: 'Гагарин',
                    },
                    {
                        value: 'Глинка',
                        label: 'Глинка',
                    },
                    {
                        value: 'Голынки',
                        label: 'Голынки',
                    },
                    {
                        value: 'Демидов',
                        label: 'Демидов',
                    },
                    {
                        value: 'Десногорск',
                        label: 'Десногорск',
                    },
                    {
                        value: 'Дорогобуж',
                        label: 'Дорогобуж',
                    },
                    {
                        value: 'Духовщина',
                        label: 'Духовщина',
                    },
                    {
                        value: 'Ельня',
                        label: 'Ельня',
                    },
                    {
                        value: 'Ершичи',
                        label: 'Ершичи',
                    },
                    {
                        value: 'Издешково',
                        label: 'Издешково',
                    },
                    {
                        value: 'Кардымово',
                        label: 'Кардымово',
                    },
                    {
                        value: 'Красный',
                        label: 'Красный',
                    },
                    {
                        value: 'Монастырщина',
                        label: 'Монастырщина',
                    },
                    {
                        value: 'Новодугино',
                        label: 'Новодугино',
                    },
                    {
                        value: 'Починок',
                        label: 'Починок',
                    },
                    {
                        value: 'Рославль',
                        label: 'Рославль',
                    },
                    {
                        value: 'Рудня',
                        label: 'Рудня',
                    },
                    {
                        value: 'Сафоново',
                        label: 'Сафоново',
                    },
                    {
                        value: 'Смоленск',
                        label: 'Смоленск',
                    },
                    {
                        value: 'Сычевка',
                        label: 'Сычевка',
                    },
                    {
                        value: 'Угра',
                        label: 'Угра',
                    },
                    {
                        value: 'Хиславичи',
                        label: 'Хиславичи',
                    },
                    {
                        value: 'Холм-Жирковский',
                        label: 'Холм-Жирковский',
                    },
                    {
                        value: 'Шумячи',
                        label: 'Шумячи',
                    },
                    {
                        value: 'Ярцево',
                        label: 'Ярцево',
                    },
                ],
            },
            {
                value: 'Тамбовская область',
                label: 'Тамбовская область',
                children: [
                    {
                        value: 'Бондари',
                        label: 'Бондари',
                    },
                    {
                        value: 'Гавриловка Вторая',
                        label: 'Гавриловка Вторая',
                    },
                    {
                        value: 'Жердевка',
                        label: 'Жердевка',
                    },
                    {
                        value: 'Знаменка',
                        label: 'Знаменка',
                    },
                    {
                        value: 'Инжавино',
                        label: 'Инжавино',
                    },
                    {
                        value: 'Кирсанов',
                        label: 'Кирсанов',
                    },
                    {
                        value: 'Котовск',
                        label: 'Котовск',
                    },
                    {
                        value: 'Мичуринск',
                        label: 'Мичуринск',
                    },
                    {
                        value: 'Мордово',
                        label: 'Мордово',
                    },
                    {
                        value: 'Моршанск',
                        label: 'Моршанск',
                    },
                    {
                        value: 'Мучкапский',
                        label: 'Мучкапский',
                    },
                    {
                        value: 'Первомайский (Тамбовская обл.)',
                        label: 'Первомайский (Тамбовская обл.)',
                    },
                    {
                        value: 'Петровское',
                        label: 'Петровское',
                    },
                    {
                        value: 'Пичаево',
                        label: 'Пичаево',
                    },
                    {
                        value: 'Рассказово',
                        label: 'Рассказово',
                    },
                    {
                        value: 'Ржакса',
                        label: 'Ржакса',
                    },
                    {
                        value: 'Сосновка',
                        label: 'Сосновка',
                    },
                    {
                        value: 'Староюрьево',
                        label: 'Староюрьево',
                    },
                    {
                        value: 'Тамбов',
                        label: 'Тамбов',
                    },
                    {
                        value: 'Токаревка',
                        label: 'Токаревка',
                    },
                    {
                        value: 'Уварово',
                        label: 'Уварово',
                    },
                    {
                        value: 'Умет',
                        label: 'Умет',
                    },
                ],
            },
            {
                value: 'Тверская область',
                label: 'Тверская область',
                children: [
                    {
                        value: 'Андреаполь',
                        label: 'Андреаполь',
                    },
                    {
                        value: 'Бежецк',
                        label: 'Бежецк',
                    },
                    {
                        value: 'Белый',
                        label: 'Белый',
                    },
                    {
                        value: 'Белый Городок',
                        label: 'Белый Городок',
                    },
                    {
                        value: 'Березайка',
                        label: 'Березайка',
                    },
                    {
                        value: 'Бологое',
                        label: 'Бологое',
                    },
                    {
                        value: 'Васильевский Мох',
                        label: 'Васильевский Мох',
                    },
                    {
                        value: 'Весьегонск',
                        label: 'Весьегонск',
                    },
                    {
                        value: 'Выползово',
                        label: 'Выползово',
                    },
                    {
                        value: 'Вышний Волочек',
                        label: 'Вышний Волочек',
                    },
                    {
                        value: 'Жарковский',
                        label: 'Жарковский',
                    },
                    {
                        value: 'Западная Двина',
                        label: 'Западная Двина',
                    },
                    {
                        value: 'Зубцов',
                        label: 'Зубцов',
                    },
                    {
                        value: 'Изоплит',
                        label: 'Изоплит',
                    },
                    {
                        value: 'Калашниково',
                        label: 'Калашниково',
                    },
                    {
                        value: 'Калинин',
                        label: 'Калинин',
                    },
                    {
                        value: 'Калязин',
                        label: 'Калязин',
                    },
                    {
                        value: 'Кашин',
                        label: 'Кашин',
                    },
                    {
                        value: 'Кесова Гора',
                        label: 'Кесова Гора',
                    },
                    {
                        value: 'Кимры',
                        label: 'Кимры',
                    },
                    {
                        value: 'Конаково',
                        label: 'Конаково',
                    },
                    {
                        value: 'Красный Холм',
                        label: 'Красный Холм',
                    },
                    {
                        value: 'Кувшиново',
                        label: 'Кувшиново',
                    },
                    {
                        value: 'Лесное',
                        label: 'Лесное',
                    },
                    {
                        value: 'Лихославль',
                        label: 'Лихославль',
                    },
                    {
                        value: 'Максатиха',
                        label: 'Максатиха',
                    },
                    {
                        value: 'Молоково',
                        label: 'Молоково',
                    },
                    {
                        value: 'Нелидово',
                        label: 'Нелидово',
                    },
                    {
                        value: 'Оленино',
                        label: 'Оленино',
                    },
                    {
                        value: 'Осташков',
                        label: 'Осташков',
                    },
                    {
                        value: 'Пено',
                        label: 'Пено',
                    },
                    {
                        value: 'Рамешки',
                        label: 'Рамешки',
                    },
                    {
                        value: 'Ржев',
                        label: 'Ржев',
                    },
                    {
                        value: 'Сандово',
                        label: 'Сандово',
                    },
                    {
                        value: 'Селижарово',
                        label: 'Селижарово',
                    },
                    {
                        value: 'Сонково',
                        label: 'Сонково',
                    },
                    {
                        value: 'Спирово',
                        label: 'Спирово',
                    },
                    {
                        value: 'Старица',
                        label: 'Старица',
                    },
                    {
                        value: 'Тверь',
                        label: 'Тверь',
                    },
                    {
                        value: 'Торжок',
                        label: 'Торжок',
                    },
                    {
                        value: 'Торопец',
                        label: 'Торопец',
                    },
                    {
                        value: 'Удомля',
                        label: 'Удомля',
                    },
                    {
                        value: 'Фирово',
                        label: 'Фирово',
                    },
                ],
            },
            {
                value: 'Тульская область',
                label: 'Тульская область',
                children: [
                    {
                        value: 'Агеево',
                        label: 'Агеево',
                    },
                    {
                        value: 'Алексин',
                        label: 'Алексин',
                    },
                    {
                        value: 'Арсеньево',
                        label: 'Арсеньево',
                    },
                    {
                        value: 'Барсуки',
                        label: 'Барсуки',
                    },
                    {
                        value: 'Белев',
                        label: 'Белев',
                    },
                    {
                        value: 'Богородицк',
                        label: 'Богородицк',
                    },
                    {
                        value: 'Болохово',
                        label: 'Болохово',
                    },
                    {
                        value: 'Велегож',
                        label: 'Велегож',
                    },
                    {
                        value: 'Венев',
                        label: 'Венев',
                    },
                    {
                        value: 'Волово',
                        label: 'Волово',
                    },
                    {
                        value: 'Горелки',
                        label: 'Горелки',
                    },
                    {
                        value: 'Донской',
                        label: 'Донской',
                    },
                    {
                        value: 'Дубна (Тульская обл.)',
                        label: 'Дубна (Тульская обл.)',
                    },
                    {
                        value: 'Епифань',
                        label: 'Епифань',
                    },
                    {
                        value: 'Ефремов',
                        label: 'Ефремов',
                    },
                    {
                        value: 'Заокский',
                        label: 'Заокский',
                    },
                    {
                        value: 'Казановка',
                        label: 'Казановка',
                    },
                    {
                        value: 'Кимовск',
                        label: 'Кимовск',
                    },
                    {
                        value: 'Киреевск',
                        label: 'Киреевск',
                    },
                    {
                        value: 'Куркино',
                        label: 'Куркино',
                    },
                    {
                        value: 'Ленинский',
                        label: 'Ленинский',
                    },
                    {
                        value: 'Новомосковск',
                        label: 'Новомосковск',
                    },
                    {
                        value: 'Одоев',
                        label: 'Одоев',
                    },
                    {
                        value: 'Плавск',
                        label: 'Плавск',
                    },
                    {
                        value: 'Советск (Тульская обл.)',
                        label: 'Советск (Тульская обл.)',
                    },
                    {
                        value: 'Суворов',
                        label: 'Суворов',
                    },
                    {
                        value: 'Тула',
                        label: 'Тула',
                    },
                    {
                        value: 'Узловая',
                        label: 'Узловая',
                    },
                    {
                        value: 'Щекино',
                        label: 'Щекино',
                    },
                    {
                        value: 'Ясногорск',
                        label: 'Ясногорск',
                    },
                ],
            },
            {
                value: 'Ярославская область',
                label: 'Ярославская область',
                children: [
                    {
                        value: 'Берендеево',
                        label: 'Берендеево',
                    },
                    {
                        value: 'Большое Село',
                        label: 'Большое Село',
                    },
                    {
                        value: 'Борисоглебский',
                        label: 'Борисоглебский',
                    },
                    {
                        value: 'Брейтово',
                        label: 'Брейтово',
                    },
                    {
                        value: 'Бурмакино',
                        label: 'Бурмакино',
                    },
                    {
                        value: 'Варегово',
                        label: 'Варегово',
                    },
                    {
                        value: 'Волга',
                        label: 'Волга',
                    },
                    {
                        value: 'Гаврилов Ям',
                        label: 'Гаврилов Ям',
                    },
                    {
                        value: 'Данилов',
                        label: 'Данилов',
                    },
                    {
                        value: 'Любим',
                        label: 'Любим',
                    },
                    {
                        value: 'Мышкин',
                        label: 'Мышкин',
                    },
                    {
                        value: 'Некрасовское',
                        label: 'Некрасовское',
                    },
                    {
                        value: 'Новый Некоуз',
                        label: 'Новый Некоуз',
                    },
                    {
                        value: 'Переславль-Залесский',
                        label: 'Переславль-Залесский',
                    },
                    {
                        value: 'Пошехонье-Володарск',
                        label: 'Пошехонье-Володарск',
                    },
                    {
                        value: 'Ростов',
                        label: 'Ростов',
                    },
                    {
                        value: 'Рыбинск',
                        label: 'Рыбинск',
                    },
                    {
                        value: 'Тутаев',
                        label: 'Тутаев',
                    },
                    {
                        value: 'Углич',
                        label: 'Углич',
                    },
                    {
                        value: 'Ярославль',
                        label: 'Ярославль',
                    },
                ],
            },
        ],
    },
    {
        label: 'Южный',
        value: 'Южный',
        children: [
            {
                value: 'Республика Адыгея',
                label: 'Республика Адыгея',
                children: [
                    {
                        value: 'Адыгейск',
                        label: 'Адыгейск',
                    },
                    {
                        value: 'Майкоп (Адыгея)',
                        label: 'Майкоп (Адыгея)',
                    },
                ],
            },
            {
                value: 'Астраханская область',
                label: 'Астраханская область',
                children: [
                    {
                        value: 'Астрахань',
                        label: 'Астрахань',
                    },
                    {
                        value: 'Ахтубинск',
                        label: 'Ахтубинск',
                    },
                    {
                        value: 'Верхний Баскунчак',
                        label: 'Верхний Баскунчак',
                    },
                    {
                        value: 'Володарский',
                        label: 'Володарский',
                    },
                    {
                        value: 'Енотаевка',
                        label: 'Енотаевка',
                    },
                    {
                        value: 'Икряное',
                        label: 'Икряное',
                    },
                    {
                        value: 'Камызяк',
                        label: 'Камызяк',
                    },
                    {
                        value: 'Капустин Яр',
                        label: 'Капустин Яр',
                    },
                    {
                        value: 'Красный Яр (Астраханская обл.)',
                        label: 'Красный Яр (Астраханская обл.)',
                    },
                    {
                        value: 'Лиман',
                        label: 'Лиман',
                    },
                    {
                        value: 'Началово',
                        label: 'Началово',
                    },
                    {
                        value: 'Харабали',
                        label: 'Харабали',
                    },
                    {
                        value: 'Черный Яр',
                        label: 'Черный Яр',
                    },
                ],
            },
            {
                value: 'Волгоградская область',
                label: 'Волгоградская область',
                children: [
                    {
                        value: 'Алексеевская',
                        label: 'Алексеевская',
                    },
                    {
                        value: 'Быково (Волгоградская обл.)',
                        label: 'Быково (Волгоградская обл.)',
                    },
                    {
                        value: 'Волгоград',
                        label: 'Волгоград',
                    },
                    {
                        value: 'Волжский (Волгоградская обл.)',
                        label: 'Волжский (Волгоградская обл.)',
                    },
                    {
                        value: 'Городище (Волгоградская обл.)',
                        label: 'Городище (Волгоградская обл.)',
                    },
                    {
                        value: 'Дубовка (Волгоградская обл.)',
                        label: 'Дубовка (Волгоградская обл.)',
                    },
                    {
                        value: 'Елань',
                        label: 'Елань',
                    },
                    {
                        value: 'Жирновск',
                        label: 'Жирновск',
                    },
                    {
                        value: 'Иловля',
                        label: 'Иловля',
                    },
                    {
                        value: 'Калач-на-Дону',
                        label: 'Калач-на-Дону',
                    },
                    {
                        value: 'Камышин',
                        label: 'Камышин',
                    },
                    {
                        value: 'Кириллов',
                        label: 'Кириллов',
                    },
                    {
                        value: 'Клетский',
                        label: 'Клетский',
                    },
                    {
                        value: 'Котельниково',
                        label: 'Котельниково',
                    },
                    {
                        value: 'Котово',
                        label: 'Котово',
                    },
                    {
                        value: 'Кумылженская',
                        label: 'Кумылженская',
                    },
                    {
                        value: 'Ленинск',
                        label: 'Ленинск',
                    },
                    {
                        value: 'Михайловка',
                        label: 'Михайловка',
                    },
                    {
                        value: 'Нехаевский',
                        label: 'Нехаевский',
                    },
                    {
                        value: 'Николаевск',
                        label: 'Николаевск',
                    },
                    {
                        value: 'Новоаннинский',
                        label: 'Новоаннинский',
                    },
                    {
                        value: 'Новониколаевский',
                        label: 'Новониколаевский',
                    },
                    {
                        value: 'Ольховка',
                        label: 'Ольховка',
                    },
                    {
                        value: 'Палласовка (Волгоградская обл.)',
                        label: 'Палласовка (Волгоградская обл.)',
                    },
                    {
                        value: 'Рудня (Волгоградская обл.)',
                        label: 'Рудня (Волгоградская обл.)',
                    },
                    {
                        value: 'Светлый Яр',
                        label: 'Светлый Яр',
                    },
                    {
                        value: 'Серафимович',
                        label: 'Серафимович',
                    },
                    {
                        value: 'Средняя Ахтуба',
                        label: 'Средняя Ахтуба',
                    },
                    {
                        value: 'Сталинград',
                        label: 'Сталинград',
                    },
                    {
                        value: 'Старая Полтавка',
                        label: 'Старая Полтавка',
                    },
                    {
                        value: 'Суровикино',
                        label: 'Суровикино',
                    },
                    {
                        value: 'Урюпинск',
                        label: 'Урюпинск',
                    },
                    {
                        value: 'Фролово',
                        label: 'Фролово',
                    },
                    {
                        value: 'Чернышковский',
                        label: 'Чернышковский',
                    },
                ],
            },
            {
                value: 'Республика Калмыкия',
                label: 'Республика Калмыкия',
                children: [
                    {
                        value: 'Аршань',
                        label: 'Аршань',
                    },
                    {
                        value: 'Городовиковск',
                        label: 'Городовиковск',
                    },
                    {
                        value: 'Каспийский',
                        label: 'Каспийский',
                    },
                    {
                        value: 'Комсомольский (Калмыкия)',
                        label: 'Комсомольский (Калмыкия)',
                    },
                    {
                        value: 'Малые Дербеты',
                        label: 'Малые Дербеты',
                    },
                    {
                        value: 'Приютное',
                        label: 'Приютное',
                    },
                    {
                        value: 'Советское (Калмыкия)',
                        label: 'Советское (Калмыкия)',
                    },
                    {
                        value: 'Троицкое (Калмыкия)',
                        label: 'Троицкое (Калмыкия)',
                    },
                    {
                        value: 'Утта',
                        label: 'Утта',
                    },
                    {
                        value: 'Цаган-Аман',
                        label: 'Цаган-Аман',
                    },
                    {
                        value: 'Элиста',
                        label: 'Элиста',
                    },
                    {
                        value: 'Юста',
                        label: 'Юста',
                    },
                    {
                        value: 'Яшалта',
                        label: 'Яшалта',
                    },
                    {
                        value: 'Яшкуль',
                        label: 'Яшкуль',
                    },
                ],
            },
            {
                value: 'Краснодарский край',
                label: 'Краснодарский край',
                children: [
                    {
                        value: 'Абинск',
                        label: 'Абинск',
                    },
                    {
                        value: 'Абрау-Дюрсо',
                        label: 'Абрау-Дюрсо',
                    },
                    {
                        value: 'Адлер',
                        label: 'Адлер',
                    },
                    {
                        value: 'Анапа',
                        label: 'Анапа',
                    },
                    {
                        value: 'Апшеронск',
                        label: 'Апшеронск',
                    },
                    {
                        value: 'Армавир',
                        label: 'Армавир',
                    },
                    {
                        value: 'Архипо-Осиповка',
                        label: 'Архипо-Осиповка',
                    },
                    {
                        value: 'Афипский',
                        label: 'Афипский',
                    },
                    {
                        value: 'Ахтырский',
                        label: 'Ахтырский',
                    },
                    {
                        value: 'Ачуево',
                        label: 'Ачуево',
                    },
                    {
                        value: 'Белая Глина',
                        label: 'Белая Глина',
                    },
                    {
                        value: 'Белореченск',
                        label: 'Белореченск',
                    },
                    {
                        value: 'Верхнебаканский',
                        label: 'Верхнебаканский',
                    },
                    {
                        value: 'Выселки',
                        label: 'Выселки',
                    },
                    {
                        value: 'Геленджик',
                        label: 'Геленджик',
                    },
                    {
                        value: 'Гиагинская',
                        label: 'Гиагинская',
                    },
                    {
                        value: 'Горячий Ключ',
                        label: 'Горячий Ключ',
                    },
                    {
                        value: 'Гулькевичи',
                        label: 'Гулькевичи',
                    },
                    {
                        value: 'Джубга',
                        label: 'Джубга',
                    },
                    {
                        value: 'Динская',
                        label: 'Динская',
                    },
                    {
                        value: 'Ейск',
                        label: 'Ейск',
                    },
                    {
                        value: 'Ильский',
                        label: 'Ильский',
                    },
                    {
                        value: 'Кабардинка',
                        label: 'Кабардинка',
                    },
                    {
                        value: 'Калинино',
                        label: 'Калинино',
                    },
                    {
                        value: 'Калининская',
                        label: 'Калининская',
                    },
                    {
                        value: 'Каменномостский',
                        label: 'Каменномостский',
                    },
                    {
                        value: 'Каневская',
                        label: 'Каневская',
                    },
                    {
                        value: 'Кореновск',
                        label: 'Кореновск',
                    },
                    {
                        value: 'Красноармейская',
                        label: 'Красноармейская',
                    },
                    {
                        value: 'Краснодар',
                        label: 'Краснодар',
                    },
                    {
                        value: 'Кропоткин',
                        label: 'Кропоткин',
                    },
                    {
                        value: 'Крыловская',
                        label: 'Крыловская',
                    },
                    {
                        value: 'Крымск',
                        label: 'Крымск',
                    },
                    {
                        value: 'Курганинск',
                        label: 'Курганинск',
                    },
                    {
                        value: 'Кущевская',
                        label: 'Кущевская',
                    },
                    {
                        value: 'Лабинск',
                        label: 'Лабинск',
                    },
                    {
                        value: 'Лазаревское',
                        label: 'Лазаревское',
                    },
                    {
                        value: 'Ленинградская',
                        label: 'Ленинградская',
                    },
                    {
                        value: 'Майкоп (Краснодарский край)',
                        label: 'Майкоп (Краснодарский край)',
                    },
                    {
                        value: 'Мостовской',
                        label: 'Мостовской',
                    },
                    {
                        value: 'Новокубанск',
                        label: 'Новокубанск',
                    },
                    {
                        value: 'Новороссийск',
                        label: 'Новороссийск',
                    },
                    {
                        value: 'Отрадная',
                        label: 'Отрадная',
                    },
                    {
                        value: 'Павловская',
                        label: 'Павловская',
                    },
                    {
                        value: 'Приморско-Ахтарск',
                        label: 'Приморско-Ахтарск',
                    },
                    {
                        value: 'Северская',
                        label: 'Северская',
                    },
                    {
                        value: 'Славянск-на-Кубани',
                        label: 'Славянск-на-Кубани',
                    },
                    {
                        value: 'Сочи',
                        label: 'Сочи',
                    },
                    {
                        value: 'Староминская',
                        label: 'Староминская',
                    },
                    {
                        value: 'Старощербиновская',
                        label: 'Старощербиновская',
                    },
                    {
                        value: 'Тбилисская',
                        label: 'Тбилисская',
                    },
                    {
                        value: 'Темрюк',
                        label: 'Темрюк',
                    },
                    {
                        value: 'Тимашевск',
                        label: 'Тимашевск',
                    },
                    {
                        value: 'Тихорецк',
                        label: 'Тихорецк',
                    },
                    {
                        value: 'Туапсе',
                        label: 'Туапсе',
                    },
                    {
                        value: 'Тульский',
                        label: 'Тульский',
                    },
                    {
                        value: 'Усть-Лабинск',
                        label: 'Усть-Лабинск',
                    },
                    {
                        value: 'Хадыженск',
                        label: 'Хадыженск',
                    },
                    {
                        value: 'Хоста',
                        label: 'Хоста',
                    },
                    {
                        value: 'Шовгеновский',
                        label: 'Шовгеновский',
                    },
                ],
            },
            {
                value: 'Ростовская область',
                label: 'Ростовская область',
                children: [
                    {
                        value: 'Азов',
                        label: 'Азов',
                    },
                    {
                        value: 'Аксай (Ростовская обл.)',
                        label: 'Аксай (Ростовская обл.)',
                    },
                    {
                        value: 'Алмазный',
                        label: 'Алмазный',
                    },
                    {
                        value: 'Аютинск',
                        label: 'Аютинск',
                    },
                    {
                        value: 'Багаевский',
                        label: 'Багаевский',
                    },
                    {
                        value: 'Батайск',
                        label: 'Батайск',
                    },
                    {
                        value: 'Белая Калитва',
                        label: 'Белая Калитва',
                    },
                    {
                        value: 'Боковская',
                        label: 'Боковская',
                    },
                    {
                        value: 'Большая Мартыновка',
                        label: 'Большая Мартыновка',
                    },
                    {
                        value: 'Вешенская',
                        label: 'Вешенская',
                    },
                    {
                        value: 'Волгодонск',
                        label: 'Волгодонск',
                    },
                    {
                        value: 'Восход',
                        label: 'Восход',
                    },
                    {
                        value: 'Гигант',
                        label: 'Гигант',
                    },
                    {
                        value: 'Горняцкий',
                        label: 'Горняцкий',
                    },
                    {
                        value: 'Гуково',
                        label: 'Гуково',
                    },
                    {
                        value: 'Донецк',
                        label: 'Донецк',
                    },
                    {
                        value: 'Донской (Ростовская обл.)',
                        label: 'Донской (Ростовская обл.)',
                    },
                    {
                        value: 'Дубовское',
                        label: 'Дубовское',
                    },
                    {
                        value: 'Егорлыкская',
                        label: 'Егорлыкская',
                    },
                    {
                        value: 'Жирнов',
                        label: 'Жирнов',
                    },
                    {
                        value: 'Заветное',
                        label: 'Заветное',
                    },
                    {
                        value: 'Заводской',
                        label: 'Заводской',
                    },
                    {
                        value: 'Зверево',
                        label: 'Зверево',
                    },
                    {
                        value: 'Зерноград',
                        label: 'Зерноград',
                    },
                    {
                        value: 'Зимовники',
                        label: 'Зимовники',
                    },
                    {
                        value: 'Кагальницкая',
                        label: 'Кагальницкая',
                    },
                    {
                        value: 'Казанская',
                        label: 'Казанская',
                    },
                    {
                        value: 'Каменоломни',
                        label: 'Каменоломни',
                    },
                    {
                        value: 'Каменск-Шахтинский',
                        label: 'Каменск-Шахтинский',
                    },
                    {
                        value: 'Кашары',
                        label: 'Кашары',
                    },
                    {
                        value: 'Коксовый',
                        label: 'Коксовый',
                    },
                    {
                        value: 'Константиновск',
                        label: 'Константиновск',
                    },
                    {
                        value: 'Красный Сулин',
                        label: 'Красный Сулин',
                    },
                    {
                        value: 'Куйбышево',
                        label: 'Куйбышево',
                    },
                    {
                        value: 'Матвеев Курган',
                        label: 'Матвеев Курган',
                    },
                    {
                        value: 'Мигулинская',
                        label: 'Мигулинская',
                    },
                    {
                        value: 'Миллерово',
                        label: 'Миллерово',
                    },
                    {
                        value: 'Милютинская',
                        label: 'Милютинская',
                    },
                    {
                        value: 'Морозовск',
                        label: 'Морозовск',
                    },
                    {
                        value: 'Новочеркасск',
                        label: 'Новочеркасск',
                    },
                    {
                        value: 'Новошахтинск',
                        label: 'Новошахтинск',
                    },
                    {
                        value: 'Обливская',
                        label: 'Обливская',
                    },
                    {
                        value: 'Орловский',
                        label: 'Орловский',
                    },
                    {
                        value: 'Песчанокопское',
                        label: 'Песчанокопское',
                    },
                    {
                        value: 'Покровское',
                        label: 'Покровское',
                    },
                    {
                        value: 'Пролетарск',
                        label: 'Пролетарск',
                    },
                    {
                        value: 'Ремонтное',
                        label: 'Ремонтное',
                    },
                    {
                        value: 'Родионово-Несветайская',
                        label: 'Родионово-Несветайская',
                    },
                    {
                        value: 'Ростов-на-Дону',
                        label: 'Ростов-на-Дону',
                    },
                    {
                        value: 'Сальск',
                        label: 'Сальск',
                    },
                    {
                        value: 'Семикаракорск',
                        label: 'Семикаракорск',
                    },
                    {
                        value: 'Таганрог',
                        label: 'Таганрог',
                    },
                    {
                        value: 'Тарасовский',
                        label: 'Тарасовский',
                    },
                    {
                        value: 'Тацинский',
                        label: 'Тацинский',
                    },
                    {
                        value: 'Усть-Донецкий',
                        label: 'Усть-Донецкий',
                    },
                    {
                        value: 'Целина',
                        label: 'Целина',
                    },
                    {
                        value: 'Цимлянск',
                        label: 'Цимлянск',
                    },
                    {
                        value: 'Чалтырь',
                        label: 'Чалтырь',
                    },
                    {
                        value: 'Чертково',
                        label: 'Чертково',
                    },
                    {
                        value: 'Шахты',
                        label: 'Шахты',
                    },
                    {
                        value: 'Шолоховский',
                        label: 'Шолоховский',
                    },
                ],
            },
        ],
    },
    {
        label: 'Северо-Западный',
        value: 'Северо-Западный',
        children: [
            {
                value: 'Санкт-Петербург и область',
                label: 'Санкт-Петербург и область',
                children: [
                    {
                        value: 'Санкт-Петербург',
                        label: 'Санкт-Петербург',
                    },
                    {
                        value: 'Александровская',
                        label: 'Александровская',
                    },
                    {
                        value: 'Бокситогорск',
                        label: 'Бокситогорск',
                    },
                    {
                        value: 'Большая Ижора',
                        label: 'Большая Ижора',
                    },
                    {
                        value: 'Будогощь',
                        label: 'Будогощь',
                    },
                    {
                        value: 'Вознесенье',
                        label: 'Вознесенье',
                    },
                    {
                        value: 'Волосово',
                        label: 'Волосово',
                    },
                    {
                        value: 'Волхов',
                        label: 'Волхов',
                    },
                    {
                        value: 'Всеволожск',
                        label: 'Всеволожск',
                    },
                    {
                        value: 'Выборг',
                        label: 'Выборг',
                    },
                    {
                        value: 'Вырица',
                        label: 'Вырица',
                    },
                    {
                        value: 'Высоцк',
                        label: 'Высоцк',
                    },
                    {
                        value: 'Гатчина',
                        label: 'Гатчина',
                    },
                    {
                        value: 'Дружная Горка',
                        label: 'Дружная Горка',
                    },
                    {
                        value: 'Дубровка',
                        label: 'Дубровка',
                    },
                    {
                        value: 'Ефимовский',
                        label: 'Ефимовский',
                    },
                    {
                        value: 'Зеленогорск (Ленинградская обл.)',
                        label: 'Зеленогорск (Ленинградская обл.)',
                    },
                    {
                        value: 'Ивангород',
                        label: 'Ивангород',
                    },
                    {
                        value: 'Каменногорск',
                        label: 'Каменногорск',
                    },
                    {
                        value: 'Кикерино',
                        label: 'Кикерино',
                    },
                    {
                        value: 'Кингисепп',
                        label: 'Кингисепп',
                    },
                    {
                        value: 'Кириши',
                        label: 'Кириши',
                    },
                    {
                        value: 'Кировск',
                        label: 'Кировск',
                    },
                    {
                        value: 'Кобринское',
                        label: 'Кобринское',
                    },
                    {
                        value: 'Колпино',
                        label: 'Колпино',
                    },
                    {
                        value: 'Коммунар',
                        label: 'Коммунар',
                    },
                    {
                        value: 'Кронштадт',
                        label: 'Кронштадт',
                    },
                    {
                        value: 'Лисий Нос',
                        label: 'Лисий Нос',
                    },
                    {
                        value: 'Лодейное Поле',
                        label: 'Лодейное Поле',
                    },
                    {
                        value: 'Ломоносов',
                        label: 'Ломоносов',
                    },
                    {
                        value: 'Луга',
                        label: 'Луга',
                    },
                    {
                        value: 'Павловск (Ленинградская обл.)',
                        label: 'Павловск (Ленинградская обл.)',
                    },
                    {
                        value: 'Парголово',
                        label: 'Парголово',
                    },
                    {
                        value: 'Петродворец',
                        label: 'Петродворец',
                    },
                    {
                        value: 'Пикалёво',
                        label: 'Пикалёво',
                    },
                    {
                        value: 'Подпорожье',
                        label: 'Подпорожье',
                    },
                    {
                        value: 'Приозерск',
                        label: 'Приозерск',
                    },
                    {
                        value: 'Пушкин',
                        label: 'Пушкин',
                    },
                    {
                        value: 'Светогорск',
                        label: 'Светогорск',
                    },
                    {
                        value: 'Сертолово',
                        label: 'Сертолово',
                    },
                    {
                        value: 'Сестрорецк',
                        label: 'Сестрорецк',
                    },
                    {
                        value: 'Сланцы',
                        label: 'Сланцы',
                    },
                    {
                        value: 'Сосновый Бор',
                        label: 'Сосновый Бор',
                    },
                    {
                        value: 'Тихвин',
                        label: 'Тихвин',
                    },
                    {
                        value: 'Тосно',
                        label: 'Тосно',
                    },
                    {
                        value: 'Шлиссельбург',
                        label: 'Шлиссельбург',
                    },
                ],
            },
            {
                value: 'Архангельская область',
                label: 'Архангельская область',
                children: [
                    {
                        value: 'Амдерма',
                        label: 'Амдерма',
                    },
                    {
                        value: 'Архангельск',
                        label: 'Архангельск',
                    },
                    {
                        value: 'Березник',
                        label: 'Березник',
                    },
                    {
                        value: 'Вельск',
                        label: 'Вельск',
                    },
                    {
                        value: 'Верхняя Тойма',
                        label: 'Верхняя Тойма',
                    },
                    {
                        value: 'Волошка',
                        label: 'Волошка',
                    },
                    {
                        value: 'Вычегодский',
                        label: 'Вычегодский',
                    },
                    {
                        value: 'Емца',
                        label: 'Емца',
                    },
                    {
                        value: 'Илеза',
                        label: 'Илеза',
                    },
                    {
                        value: 'Ильинско-Подомское',
                        label: 'Ильинско-Подомское',
                    },
                    {
                        value: 'Каргополь (Архангельская обл.)',
                        label: 'Каргополь (Архангельская обл.)',
                    },
                    {
                        value: 'Карпогоры',
                        label: 'Карпогоры',
                    },
                    {
                        value: 'Кодино',
                        label: 'Кодино',
                    },
                    {
                        value: 'Коноша',
                        label: 'Коноша',
                    },
                    {
                        value: 'Коряжма',
                        label: 'Коряжма',
                    },
                    {
                        value: 'Котлас',
                        label: 'Котлас',
                    },
                    {
                        value: 'Красноборск',
                        label: 'Красноборск',
                    },
                    {
                        value: 'Лешуконское',
                        label: 'Лешуконское',
                    },
                    {
                        value: 'Мезень',
                        label: 'Мезень',
                    },
                    {
                        value: 'Мирный (Архангельская обл.)',
                        label: 'Мирный (Архангельская обл.)',
                    },
                    {
                        value: 'Нарьян-Мар',
                        label: 'Нарьян-Мар',
                    },
                    {
                        value: 'Новодвинск',
                        label: 'Новодвинск',
                    },
                    {
                        value: 'Няндома',
                        label: 'Няндома',
                    },
                    {
                        value: 'Онега',
                        label: 'Онега',
                    },
                    {
                        value: 'Пинега',
                        label: 'Пинега',
                    },
                    {
                        value: 'Плесецк',
                        label: 'Плесецк',
                    },
                    {
                        value: 'Савинск',
                        label: 'Савинск',
                    },
                    {
                        value: 'Северодвинск',
                        label: 'Северодвинск',
                    },
                    {
                        value: 'Сольвычегодск',
                        label: 'Сольвычегодск',
                    },
                    {
                        value: 'Холмогоры',
                        label: 'Холмогоры',
                    },
                    {
                        value: 'Шенкурск',
                        label: 'Шенкурск',
                    },
                    {
                        value: 'Яренск',
                        label: 'Яренск',
                    },
                ],
            },
            {
                value: 'Вологодская область',
                label: 'Вологодская область',
                children: [
                    {
                        value: 'Бабаево',
                        label: 'Бабаево',
                    },
                    {
                        value: 'Белозерск',
                        label: 'Белозерск',
                    },
                    {
                        value: 'Великий Устюг',
                        label: 'Великий Устюг',
                    },
                    {
                        value: 'Верховажье',
                        label: 'Верховажье',
                    },
                    {
                        value: 'Вожега',
                        label: 'Вожега',
                    },
                    {
                        value: 'Вологда',
                        label: 'Вологда',
                    },
                    {
                        value: 'Вохтога',
                        label: 'Вохтога',
                    },
                    {
                        value: 'Вытегра',
                        label: 'Вытегра',
                    },
                    {
                        value: 'Грязовец',
                        label: 'Грязовец',
                    },
                    {
                        value: 'Кадников',
                        label: 'Кадников',
                    },
                    {
                        value: 'Кадуй',
                        label: 'Кадуй',
                    },
                    {
                        value: 'Кичменгский Городок',
                        label: 'Кичменгский Городок',
                    },
                    {
                        value: 'Липин Бор',
                        label: 'Липин Бор',
                    },
                    {
                        value: 'Никольск',
                        label: 'Никольск',
                    },
                    {
                        value: 'Нюксеница',
                        label: 'Нюксеница',
                    },
                    {
                        value: 'Сокол',
                        label: 'Сокол',
                    },
                    {
                        value: 'Сямжа',
                        label: 'Сямжа',
                    },
                    {
                        value: 'Тарногский Городок',
                        label: 'Тарногский Городок',
                    },
                    {
                        value: 'Тотьма',
                        label: 'Тотьма',
                    },
                    {
                        value: 'Устюжна',
                        label: 'Устюжна',
                    },
                    {
                        value: 'Харовск',
                        label: 'Харовск',
                    },
                    {
                        value: 'Чагода',
                        label: 'Чагода',
                    },
                    {
                        value: 'Череповец',
                        label: 'Череповец',
                    },
                    {
                        value: 'Шексна',
                        label: 'Шексна',
                    },
                    {
                        value: 'Шуйское',
                        label: 'Шуйское',
                    },
                ],
            },
            {
                value: 'Калининградская область',
                label: 'Калининградская область',
                children: [
                    {
                        value: 'Багратионовск',
                        label: 'Багратионовск',
                    },
                    {
                        value: 'Балтийск',
                        label: 'Балтийск',
                    },
                    {
                        value: 'Гвардейск',
                        label: 'Гвардейск',
                    },
                    {
                        value: 'Гурьевск (Калининградская обл.)',
                        label: 'Гурьевск (Калининградская обл.)',
                    },
                    {
                        value: 'Гусев',
                        label: 'Гусев',
                    },
                    {
                        value: 'Железнодорожный (Калининград.)',
                        label: 'Железнодорожный (Калининград.)',
                    },
                    {
                        value: 'Зеленоградск',
                        label: 'Зеленоградск',
                    },
                    {
                        value: 'Знаменск',
                        label: 'Знаменск',
                    },
                    {
                        value: 'Калининград (Кенигсберг)',
                        label: 'Калининград (Кенигсберг)',
                    },
                    {
                        value: 'Краснознаменск (Калининград.)',
                        label: 'Краснознаменск (Калининград.)',
                    },
                    {
                        value: 'Ладушкин',
                        label: 'Ладушкин',
                    },
                    {
                        value: 'Мамоново',
                        label: 'Мамоново',
                    },
                    {
                        value: 'Неман',
                        label: 'Неман',
                    },
                    {
                        value: 'Нестеров',
                        label: 'Нестеров',
                    },
                    {
                        value: 'Озерск(Калининградская обл.)',
                        label: 'Озерск(Калининградская обл.)',
                    },
                    {
                        value: 'Пионерск',
                        label: 'Пионерск',
                    },
                    {
                        value: 'Полесск',
                        label: 'Полесск',
                    },
                    {
                        value: 'Правдинск',
                        label: 'Правдинск',
                    },
                    {
                        value: 'Светлогорск',
                        label: 'Светлогорск',
                    },
                    {
                        value: 'Светлый (Калининградская обл.)',
                        label: 'Светлый (Калининградская обл.)',
                    },
                    {
                        value: 'Славск',
                        label: 'Славск',
                    },
                    {
                        value: 'Советск (Калининградская обл.)',
                        label: 'Советск (Калининградская обл.)',
                    },
                    {
                        value: 'Черняховск',
                        label: 'Черняховск',
                    },
                ],
            },
            {
                value: 'Республика Карелия',
                label: 'Республика Карелия',
                children: [
                    {
                        value: 'Амбарный',
                        label: 'Амбарный',
                    },
                    {
                        value: 'Беломорск',
                        label: 'Беломорск',
                    },
                    {
                        value: 'Валаам',
                        label: 'Валаам',
                    },
                    {
                        value: 'Вирандозеро',
                        label: 'Вирандозеро',
                    },
                    {
                        value: 'Гирвас',
                        label: 'Гирвас',
                    },
                    {
                        value: 'Деревянка',
                        label: 'Деревянка',
                    },
                    {
                        value: 'Идель',
                        label: 'Идель',
                    },
                    {
                        value: 'Ильинский (Карелия)',
                        label: 'Ильинский (Карелия)',
                    },
                    {
                        value: 'Калевала',
                        label: 'Калевала',
                    },
                    {
                        value: 'Кемь',
                        label: 'Кемь',
                    },
                    {
                        value: 'Кестеньга',
                        label: 'Кестеньга',
                    },
                    {
                        value: 'Кондопога',
                        label: 'Кондопога',
                    },
                    {
                        value: 'Костомукша',
                        label: 'Костомукша',
                    },
                    {
                        value: 'Лахденпохья',
                        label: 'Лахденпохья',
                    },
                    {
                        value: 'Лоухи',
                        label: 'Лоухи',
                    },
                    {
                        value: 'Медвежьегорск',
                        label: 'Медвежьегорск',
                    },
                    {
                        value: 'Муезерский',
                        label: 'Муезерский',
                    },
                    {
                        value: 'Олонец',
                        label: 'Олонец',
                    },
                    {
                        value: 'Петрозаводск',
                        label: 'Петрозаводск',
                    },
                    {
                        value: 'Питкяранта',
                        label: 'Питкяранта',
                    },
                    {
                        value: 'Повенец',
                        label: 'Повенец',
                    },
                    {
                        value: 'Пряжа',
                        label: 'Пряжа',
                    },
                    {
                        value: 'Пудож',
                        label: 'Пудож',
                    },
                    {
                        value: 'Сегежа',
                        label: 'Сегежа',
                    },
                    {
                        value: 'Сортавала',
                        label: 'Сортавала',
                    },
                    {
                        value: 'Суоярви',
                        label: 'Суоярви',
                    },
                    {
                        value: 'Хийденсельга',
                        label: 'Хийденсельга',
                    },
                ],
            },
            {
                value: 'Республика Коми',
                label: 'Республика Коми',
                children: [
                    {
                        value: 'Абезь',
                        label: 'Абезь',
                    },
                    {
                        value: 'Адзьвавом',
                        label: 'Адзьвавом',
                    },
                    {
                        value: 'Айкино',
                        label: 'Айкино',
                    },
                    {
                        value: 'Верхняя Инта',
                        label: 'Верхняя Инта',
                    },
                    {
                        value: 'Визинга',
                        label: 'Визинга',
                    },
                    {
                        value: 'Водный',
                        label: 'Водный',
                    },
                    {
                        value: 'Воркута',
                        label: 'Воркута',
                    },
                    {
                        value: 'Вуктыл',
                        label: 'Вуктыл',
                    },
                    {
                        value: 'Елецкий',
                        label: 'Елецкий',
                    },
                    {
                        value: 'Емва',
                        label: 'Емва',
                    },
                    {
                        value: 'Жешарт',
                        label: 'Жешарт',
                    },
                    {
                        value: 'Ижма',
                        label: 'Ижма',
                    },
                    {
                        value: 'Инта',
                        label: 'Инта',
                    },
                    {
                        value: 'Ираель',
                        label: 'Ираель',
                    },
                    {
                        value: 'Каджером',
                        label: 'Каджером',
                    },
                    {
                        value: 'Кажым',
                        label: 'Кажым',
                    },
                    {
                        value: 'Кожым',
                        label: 'Кожым',
                    },
                    {
                        value: 'Койгородок',
                        label: 'Койгородок',
                    },
                    {
                        value: 'Корткерос',
                        label: 'Корткерос',
                    },
                    {
                        value: 'Кослан',
                        label: 'Кослан',
                    },
                    {
                        value: 'Микунь',
                        label: 'Микунь',
                    },
                    {
                        value: 'Нижний Одес',
                        label: 'Нижний Одес',
                    },
                    {
                        value: 'Объячево',
                        label: 'Объячево',
                    },
                    {
                        value: 'Печора',
                        label: 'Печора',
                    },
                    {
                        value: 'Сосногорск',
                        label: 'Сосногорск',
                    },
                    {
                        value: 'Сыктывкар',
                        label: 'Сыктывкар',
                    },
                    {
                        value: 'Троицко-Печерск',
                        label: 'Троицко-Печерск',
                    },
                    {
                        value: 'Усинск',
                        label: 'Усинск',
                    },
                    {
                        value: 'Усогорск',
                        label: 'Усогорск',
                    },
                    {
                        value: 'Усть-Кулом',
                        label: 'Усть-Кулом',
                    },
                    {
                        value: 'Усть-Цильма',
                        label: 'Усть-Цильма',
                    },
                    {
                        value: 'Ухта',
                        label: 'Ухта',
                    },
                ],
            },
            {
                value: 'Мурманская область',
                label: 'Мурманская область',
                children: [
                    {
                        value: 'Апатиты',
                        label: 'Апатиты',
                    },
                    {
                        value: 'Африканда',
                        label: 'Африканда',
                    },
                    {
                        value: 'Верхнетуломский',
                        label: 'Верхнетуломский',
                    },
                    {
                        value: 'Видяево',
                        label: 'Видяево',
                    },
                    {
                        value: 'Гаджиево',
                        label: 'Гаджиево',
                    },
                    {
                        value: 'Заозерск',
                        label: 'Заозерск',
                    },
                    {
                        value: 'Заполярный',
                        label: 'Заполярный',
                    },
                    {
                        value: 'Зареченск',
                        label: 'Зареченск',
                    },
                    {
                        value: 'Зашеек',
                        label: 'Зашеек',
                    },
                    {
                        value: 'Зеленоборский',
                        label: 'Зеленоборский',
                    },
                    {
                        value: 'Кандалакша',
                        label: 'Кандалакша',
                    },
                    {
                        value: 'Кильдинстрой',
                        label: 'Кильдинстрой',
                    },
                    {
                        value: 'Ковдор',
                        label: 'Ковдор',
                    },
                    {
                        value: 'Кола',
                        label: 'Кола',
                    },
                    {
                        value: 'Конда',
                        label: 'Конда',
                    },
                    {
                        value: 'Ловозеро',
                        label: 'Ловозеро',
                    },
                    {
                        value: 'Мончегорск',
                        label: 'Мончегорск',
                    },
                    {
                        value: 'Мурманск',
                        label: 'Мурманск',
                    },
                    {
                        value: 'Мурмаши',
                        label: 'Мурмаши',
                    },
                    {
                        value: 'Никель',
                        label: 'Никель',
                    },
                    {
                        value: 'Оленегорск',
                        label: 'Оленегорск',
                    },
                    {
                        value: 'Полярные Зори',
                        label: 'Полярные Зори',
                    },
                    {
                        value: 'Полярный',
                        label: 'Полярный',
                    },
                    {
                        value: 'Ревда (Мурманская обл.)',
                        label: 'Ревда (Мурманская обл.)',
                    },
                    {
                        value: 'Североморск',
                        label: 'Североморск',
                    },
                    {
                        value: 'Снежногорск',
                        label: 'Снежногорск',
                    },
                    {
                        value: 'Умба',
                        label: 'Умба',
                    },
                ],
            },
            {
                value: 'Новгородская область',
                label: 'Новгородская область',
                children: [
                    {
                        value: 'Анциферово',
                        label: 'Анциферово',
                    },
                    {
                        value: 'Батецкий',
                        label: 'Батецкий',
                    },
                    {
                        value: 'Большая Вишера',
                        label: 'Большая Вишера',
                    },
                    {
                        value: 'Боровичи',
                        label: 'Боровичи',
                    },
                    {
                        value: 'Валдай',
                        label: 'Валдай',
                    },
                    {
                        value: 'Великий Новгород (Новгород)',
                        label: 'Великий Новгород (Новгород)',
                    },
                    {
                        value: 'Волот',
                        label: 'Волот',
                    },
                    {
                        value: 'Крестцы',
                        label: 'Крестцы',
                    },
                    {
                        value: 'Любытино',
                        label: 'Любытино',
                    },
                    {
                        value: 'Малая Вишера',
                        label: 'Малая Вишера',
                    },
                    {
                        value: 'Марево',
                        label: 'Марево',
                    },
                    {
                        value: 'Мошенское',
                        label: 'Мошенское',
                    },
                    {
                        value: 'Окуловка',
                        label: 'Окуловка',
                    },
                    {
                        value: 'Парфино',
                        label: 'Парфино',
                    },
                    {
                        value: 'Пестово',
                        label: 'Пестово',
                    },
                    {
                        value: 'Поддорье',
                        label: 'Поддорье',
                    },
                    {
                        value: 'Сольцы',
                        label: 'Сольцы',
                    },
                    {
                        value: 'Старая Русса',
                        label: 'Старая Русса',
                    },
                    {
                        value: 'Хвойная',
                        label: 'Хвойная',
                    },
                    {
                        value: 'Холм',
                        label: 'Холм',
                    },
                    {
                        value: 'Чудово',
                        label: 'Чудово',
                    },
                    {
                        value: 'Шимск',
                        label: 'Шимск',
                    },
                ],
            },
            {
                value: 'Псковская область',
                label: 'Псковская область',
                children: [
                    {
                        value: 'Бежаницы',
                        label: 'Бежаницы',
                    },
                    {
                        value: 'Великие Луки',
                        label: 'Великие Луки',
                    },
                    {
                        value: 'Гдов',
                        label: 'Гдов',
                    },
                    {
                        value: 'Дедовичи',
                        label: 'Дедовичи',
                    },
                    {
                        value: 'Дно',
                        label: 'Дно',
                    },
                    {
                        value: 'Заплюсье',
                        label: 'Заплюсье',
                    },
                    {
                        value: 'Идрица',
                        label: 'Идрица',
                    },
                    {
                        value: 'Красногородское',
                        label: 'Красногородское',
                    },
                    {
                        value: 'Кунья',
                        label: 'Кунья',
                    },
                    {
                        value: 'Локня',
                        label: 'Локня',
                    },
                    {
                        value: 'Невель',
                        label: 'Невель',
                    },
                    {
                        value: 'Новоржев',
                        label: 'Новоржев',
                    },
                    {
                        value: 'Новосокольники',
                        label: 'Новосокольники',
                    },
                    {
                        value: 'Опочка',
                        label: 'Опочка',
                    },
                    {
                        value: 'Остров',
                        label: 'Остров',
                    },
                    {
                        value: 'Палкино',
                        label: 'Палкино',
                    },
                    {
                        value: 'Печоры',
                        label: 'Печоры',
                    },
                    {
                        value: 'Плюсса',
                        label: 'Плюсса',
                    },
                    {
                        value: 'Порхов',
                        label: 'Порхов',
                    },
                    {
                        value: 'Псков',
                        label: 'Псков',
                    },
                    {
                        value: 'Пустошка',
                        label: 'Пустошка',
                    },
                    {
                        value: 'Пушкинские Горы',
                        label: 'Пушкинские Горы',
                    },
                    {
                        value: 'Пыталово',
                        label: 'Пыталово',
                    },
                    {
                        value: 'Себеж',
                        label: 'Себеж',
                    },
                    {
                        value: 'Струги-Красные',
                        label: 'Струги-Красные',
                    },
                    {
                        value: 'Усвяты',
                        label: 'Усвяты',
                    },
                ],
            },
        ],
    },
    {
        label: 'Дальневосточный',
        value: 'Дальневосточный',
        children: [
            {
                value: 'Амурская область',
                label: 'Амурская область',
                children: [
                    {
                        value: 'Айгунь',
                        label: 'Айгунь',
                    },
                    {
                        value: 'Архара',
                        label: 'Архара',
                    },
                    {
                        value: 'Белогорск',
                        label: 'Белогорск',
                    },
                    {
                        value: 'Благовещенск (Амурская обл.)',
                        label: 'Благовещенск (Амурская обл.)',
                    },
                    {
                        value: 'Бурея',
                        label: 'Бурея',
                    },
                    {
                        value: 'Возжаевка',
                        label: 'Возжаевка',
                    },
                    {
                        value: 'Екатеринославка',
                        label: 'Екатеринославка',
                    },
                    {
                        value: 'Ерофей Павлович',
                        label: 'Ерофей Павлович',
                    },
                    {
                        value: 'Завитинск',
                        label: 'Завитинск',
                    },
                    {
                        value: 'Зея',
                        label: 'Зея',
                    },
                    {
                        value: 'Златоустовск',
                        label: 'Златоустовск',
                    },
                    {
                        value: 'Ивановка',
                        label: 'Ивановка',
                    },
                    {
                        value: 'Коболдо',
                        label: 'Коболдо',
                    },
                    {
                        value: 'Магдагачи',
                        label: 'Магдагачи',
                    },
                    {
                        value: 'Новобурейский',
                        label: 'Новобурейский',
                    },
                    {
                        value: 'Поярково',
                        label: 'Поярково',
                    },
                    {
                        value: 'Райчихинск',
                        label: 'Райчихинск',
                    },
                    {
                        value: 'Ромны',
                        label: 'Ромны',
                    },
                    {
                        value: 'Свободный',
                        label: 'Свободный',
                    },
                    {
                        value: 'Серышево',
                        label: 'Серышево',
                    },
                    {
                        value: 'Сковородино',
                        label: 'Сковородино',
                    },
                    {
                        value: 'Стойба',
                        label: 'Стойба',
                    },
                    {
                        value: 'Тамбовка',
                        label: 'Тамбовка',
                    },
                    {
                        value: 'Тында',
                        label: 'Тында',
                    },
                    {
                        value: 'Февральск',
                        label: 'Февральск',
                    },
                    {
                        value: 'Шимановск',
                        label: 'Шимановск',
                    },
                    {
                        value: 'Экимчан',
                        label: 'Экимчан',
                    },
                    {
                        value: 'Ядрино',
                        label: 'Ядрино',
                    },
                ],
            },
            {
                value: 'Еврейская АО',
                label: 'Еврейская АО',
                children: [
                    {
                        value: 'Биробиджан',
                        label: 'Биробиджан',
                    },
                ],
            },
            {
                value: 'Камчатская область',
                label: 'Камчатская область',
                children: [
                    {
                        value: 'Атласово',
                        label: 'Атласово',
                    },
                    {
                        value: 'Аянка',
                        label: 'Аянка',
                    },
                    {
                        value: 'Большерецк',
                        label: 'Большерецк',
                    },
                    {
                        value: 'Вилючинск',
                        label: 'Вилючинск',
                    },
                    {
                        value: 'Елизово (Камчатская обл.)',
                        label: 'Елизово (Камчатская обл.)',
                    },
                    {
                        value: 'Ильпырский',
                        label: 'Ильпырский',
                    },
                    {
                        value: 'Каменское',
                        label: 'Каменское',
                    },
                    {
                        value: 'Кировский',
                        label: 'Кировский',
                    },
                    {
                        value: 'Ключи (Камчатская обл.)',
                        label: 'Ключи (Камчатская обл.)',
                    },
                    {
                        value: 'Крапивная',
                        label: 'Крапивная',
                    },
                    {
                        value: 'Мильково',
                        label: 'Мильково',
                    },
                    {
                        value: 'Никольское',
                        label: 'Никольское',
                    },
                    {
                        value: 'Озерновский',
                        label: 'Озерновский',
                    },
                    {
                        value: 'Оссора',
                        label: 'Оссора',
                    },
                    {
                        value: 'Палана',
                        label: 'Палана',
                    },
                    {
                        value: 'Пахачи',
                        label: 'Пахачи',
                    },
                    {
                        value: 'Петропавловск-Камчатский',
                        label: 'Петропавловск-Камчатский',
                    },
                    {
                        value: 'Тигиль',
                        label: 'Тигиль',
                    },
                    {
                        value: 'Тиличики',
                        label: 'Тиличики',
                    },
                    {
                        value: 'Усть-Большерецк',
                        label: 'Усть-Большерецк',
                    },
                    {
                        value: 'Усть-Камчатск',
                        label: 'Усть-Камчатск',
                    },
                ],
            },
            {
                value: 'Магаданская область',
                label: 'Магаданская область',
                children: [
                    {
                        value: 'Анадырь (Магаданская обл.)',
                        label: 'Анадырь (Магаданская обл.)',
                    },
                    {
                        value: 'Атка',
                        label: 'Атка',
                    },
                    {
                        value: 'Беринговский',
                        label: 'Беринговский',
                    },
                    {
                        value: 'Большевик',
                        label: 'Большевик',
                    },
                    {
                        value: 'Ванкарем',
                        label: 'Ванкарем',
                    },
                    {
                        value: 'Кадыкчан',
                        label: 'Кадыкчан',
                    },
                    {
                        value: 'Лаврентия',
                        label: 'Лаврентия',
                    },
                    {
                        value: 'Магадан',
                        label: 'Магадан',
                    },
                    {
                        value: 'Мыс Шмидта',
                        label: 'Мыс Шмидта',
                    },
                    {
                        value: 'Ола',
                        label: 'Ола',
                    },
                    {
                        value: 'Омсукчан',
                        label: 'Омсукчан',
                    },
                    {
                        value: 'Палатка',
                        label: 'Палатка',
                    },
                    {
                        value: 'Певек',
                        label: 'Певек',
                    },
                    {
                        value: 'Провидения',
                        label: 'Провидения',
                    },
                    {
                        value: 'Сеймчан',
                        label: 'Сеймчан',
                    },
                    {
                        value: 'Синегорье',
                        label: 'Синегорье',
                    },
                    {
                        value: 'Сусуман',
                        label: 'Сусуман',
                    },
                    {
                        value: 'Усть-Омчуг',
                        label: 'Усть-Омчуг',
                    },
                    {
                        value: 'Эвенск',
                        label: 'Эвенск',
                    },
                    {
                        value: 'Эгвекинот',
                        label: 'Эгвекинот',
                    },
                    {
                        value: 'Ягодное',
                        label: 'Ягодное',
                    },
                ],
            },
            {
                value: 'Приморский край',
                label: 'Приморский край',
                children: [
                    {
                        value: 'Анучино',
                        label: 'Анучино',
                    },
                    {
                        value: 'Арсеньев',
                        label: 'Арсеньев',
                    },
                    {
                        value: 'Артем',
                        label: 'Артем',
                    },
                    {
                        value: 'Артемовский (Приморский край)',
                        label: 'Артемовский (Приморский край)',
                    },
                    {
                        value: 'Большой Камень',
                        label: 'Большой Камень',
                    },
                    {
                        value: 'Валентин',
                        label: 'Валентин',
                    },
                    {
                        value: 'Владивосток',
                        label: 'Владивосток',
                    },
                    {
                        value: 'Высокогорск',
                        label: 'Высокогорск',
                    },
                    {
                        value: 'Горные Ключи',
                        label: 'Горные Ключи',
                    },
                    {
                        value: 'Горный',
                        label: 'Горный',
                    },
                    {
                        value: 'Дальнегорск',
                        label: 'Дальнегорск',
                    },
                    {
                        value: 'Дальнереченск',
                        label: 'Дальнереченск',
                    },
                    {
                        value: 'Зарубино',
                        label: 'Зарубино',
                    },
                    {
                        value: 'Кавалерово',
                        label: 'Кавалерово',
                    },
                    {
                        value: 'Каменка (Приморский край)',
                        label: 'Каменка (Приморский край)',
                    },
                    {
                        value: 'Камень-Рыболов',
                        label: 'Камень-Рыболов',
                    },
                    {
                        value: 'Лазо',
                        label: 'Лазо',
                    },
                    {
                        value: 'Лесозаводск',
                        label: 'Лесозаводск',
                    },
                    {
                        value: 'Лучегорск',
                        label: 'Лучегорск',
                    },
                    {
                        value: 'Михайловка (Приморский край)',
                        label: 'Михайловка (Приморский край)',
                    },
                    {
                        value: 'Находка (Приморский край)',
                        label: 'Находка (Приморский край)',
                    },
                    {
                        value: 'Новопокровка',
                        label: 'Новопокровка',
                    },
                    {
                        value: 'Ольга',
                        label: 'Ольга',
                    },
                    {
                        value: 'Партизанск',
                        label: 'Партизанск',
                    },
                    {
                        value: 'Пластун',
                        label: 'Пластун',
                    },
                    {
                        value: 'Пограничный',
                        label: 'Пограничный',
                    },
                    {
                        value: 'Покровка',
                        label: 'Покровка',
                    },
                    {
                        value: 'Посьет',
                        label: 'Посьет',
                    },
                    {
                        value: 'Русский',
                        label: 'Русский',
                    },
                    {
                        value: 'Славянка',
                        label: 'Славянка',
                    },
                    {
                        value: 'Спасск-Дальний',
                        label: 'Спасск-Дальний',
                    },
                    {
                        value: 'Терней',
                        label: 'Терней',
                    },
                    {
                        value: 'Уссурийск',
                        label: 'Уссурийск',
                    },
                    {
                        value: 'Фокино',
                        label: 'Фокино',
                    },
                    {
                        value: 'Хасан',
                        label: 'Хасан',
                    },
                    {
                        value: 'Хороль',
                        label: 'Хороль',
                    },
                    {
                        value: 'Черниговка',
                        label: 'Черниговка',
                    },
                    {
                        value: 'Чугуевка',
                        label: 'Чугуевка',
                    },
                    {
                        value: 'Яковлевка',
                        label: 'Яковлевка',
                    },
                    {
                        value: 'Ярославский',
                        label: 'Ярославский',
                    },
                ],
            },
            {
                value: 'Республика Саха (Якутия)',
                label: 'Республика Саха (Якутия)',
                children: [
                    {
                        value: 'Абый',
                        label: 'Абый',
                    },
                    {
                        value: 'Айхал',
                        label: 'Айхал',
                    },
                    {
                        value: 'Алдан',
                        label: 'Алдан',
                    },
                    {
                        value: 'Амга',
                        label: 'Амга',
                    },
                    {
                        value: 'Батагай',
                        label: 'Батагай',
                    },
                    {
                        value: 'Бердигестях',
                        label: 'Бердигестях',
                    },
                    {
                        value: 'Беркакит',
                        label: 'Беркакит',
                    },
                    {
                        value: 'Бестях',
                        label: 'Бестях',
                    },
                    {
                        value: 'Борогонцы',
                        label: 'Борогонцы',
                    },
                    {
                        value: 'Верхневилюйск',
                        label: 'Верхневилюйск',
                    },
                    {
                        value: 'Верхоянск',
                        label: 'Верхоянск',
                    },
                    {
                        value: 'Вилюйск',
                        label: 'Вилюйск',
                    },
                    {
                        value: 'Витим',
                        label: 'Витим',
                    },
                    {
                        value: 'Власово',
                        label: 'Власово',
                    },
                    {
                        value: 'Депутатский',
                        label: 'Депутатский',
                    },
                    {
                        value: 'Жиганск',
                        label: 'Жиганск',
                    },
                    {
                        value: 'Зырянка',
                        label: 'Зырянка',
                    },
                    {
                        value: 'Кангалассы',
                        label: 'Кангалассы',
                    },
                    {
                        value: 'Ленск',
                        label: 'Ленск',
                    },
                    {
                        value: 'Майя',
                        label: 'Майя',
                    },
                    {
                        value: 'Мирный (Саха)',
                        label: 'Мирный (Саха)',
                    },
                    {
                        value: 'Нерюнгри',
                        label: 'Нерюнгри',
                    },
                    {
                        value: 'Нижний Куранах',
                        label: 'Нижний Куранах',
                    },
                    {
                        value: 'Нюрба',
                        label: 'Нюрба',
                    },
                    {
                        value: 'Олекминск',
                        label: 'Олекминск',
                    },
                    {
                        value: 'Покровск',
                        label: 'Покровск',
                    },
                    {
                        value: 'Сангар',
                        label: 'Сангар',
                    },
                    {
                        value: 'Саскылах',
                        label: 'Саскылах',
                    },
                    {
                        value: 'Среднеколымск',
                        label: 'Среднеколымск',
                    },
                    {
                        value: 'Сунтар',
                        label: 'Сунтар',
                    },
                    {
                        value: 'Тикси',
                        label: 'Тикси',
                    },
                    {
                        value: 'Удачный',
                        label: 'Удачный',
                    },
                    {
                        value: 'Усть-Мая',
                        label: 'Усть-Мая',
                    },
                    {
                        value: 'Усть-Нера',
                        label: 'Усть-Нера',
                    },
                    {
                        value: 'Хандыга',
                        label: 'Хандыга',
                    },
                    {
                        value: 'Хонуу',
                        label: 'Хонуу',
                    },
                    {
                        value: 'Чернышевский',
                        label: 'Чернышевский',
                    },
                    {
                        value: 'Черский',
                        label: 'Черский',
                    },
                    {
                        value: 'Чокурдах',
                        label: 'Чокурдах',
                    },
                    {
                        value: 'Чульман',
                        label: 'Чульман',
                    },
                    {
                        value: 'Чурапча',
                        label: 'Чурапча',
                    },
                    {
                        value: 'Якутск',
                        label: 'Якутск',
                    },
                ],
            },
            {
                value: 'Сахалинская область',
                label: 'Сахалинская область',
                children: [
                    {
                        value: 'Александровск-Сахалинский',
                        label: 'Александровск-Сахалинский',
                    },
                    {
                        value: 'Анива',
                        label: 'Анива',
                    },
                    {
                        value: 'Бошняково',
                        label: 'Бошняково',
                    },
                    {
                        value: 'Быков',
                        label: 'Быков',
                    },
                    {
                        value: 'Вахрушев',
                        label: 'Вахрушев',
                    },
                    {
                        value: 'Взморье',
                        label: 'Взморье',
                    },
                    {
                        value: 'Гастелло',
                        label: 'Гастелло',
                    },
                    {
                        value: 'Горнозаводск (Сахалин)',
                        label: 'Горнозаводск (Сахалин)',
                    },
                    {
                        value: 'Долинск',
                        label: 'Долинск',
                    },
                    {
                        value: 'Ильинский (Сахалин)',
                        label: 'Ильинский (Сахалин)',
                    },
                    {
                        value: 'Катангли',
                        label: 'Катангли',
                    },
                    {
                        value: 'Корсаков',
                        label: 'Корсаков',
                    },
                    {
                        value: 'Курильск',
                        label: 'Курильск',
                    },
                    {
                        value: 'Макаров',
                        label: 'Макаров',
                    },
                    {
                        value: 'Невельск',
                        label: 'Невельск',
                    },
                    {
                        value: 'Ноглики',
                        label: 'Ноглики',
                    },
                    {
                        value: 'Оха',
                        label: 'Оха',
                    },
                    {
                        value: 'Поронайск',
                        label: 'Поронайск',
                    },
                    {
                        value: 'Северо-Курильск',
                        label: 'Северо-Курильск',
                    },
                    {
                        value: 'Смирных',
                        label: 'Смирных',
                    },
                    {
                        value: 'Томари',
                        label: 'Томари',
                    },
                    {
                        value: 'Тымовское',
                        label: 'Тымовское',
                    },
                    {
                        value: 'Углегорск',
                        label: 'Углегорск',
                    },
                    {
                        value: 'Холмск',
                        label: 'Холмск',
                    },
                    {
                        value: 'Шахтерск',
                        label: 'Шахтерск',
                    },
                    {
                        value: 'Южно-Курильск',
                        label: 'Южно-Курильск',
                    },
                    {
                        value: 'Южно-Сахалинск',
                        label: 'Южно-Сахалинск',
                    },
                ],
            },
            {
                value: 'Хабаровский край',
                label: 'Хабаровский край',
                children: [
                    {
                        value: 'Амурск',
                        label: 'Амурск',
                    },
                    {
                        value: 'Аян',
                        label: 'Аян',
                    },
                    {
                        value: 'Березовый',
                        label: 'Березовый',
                    },
                    {
                        value: 'Бикин',
                        label: 'Бикин',
                    },
                    {
                        value: 'Бира',
                        label: 'Бира',
                    },
                    {
                        value: 'Биракан',
                        label: 'Биракан',
                    },
                    {
                        value: 'Богородское (Хабаровский край)',
                        label: 'Богородское (Хабаровский край)',
                    },
                    {
                        value: 'Ванино',
                        label: 'Ванино',
                    },
                    {
                        value: 'Волочаевка Вторая',
                        label: 'Волочаевка Вторая',
                    },
                    {
                        value: 'Высокогорный',
                        label: 'Высокогорный',
                    },
                    {
                        value: 'Вяземский',
                        label: 'Вяземский',
                    },
                    {
                        value: 'Гурское',
                        label: 'Гурское',
                    },
                    {
                        value: 'Дормидонтовка',
                        label: 'Дормидонтовка',
                    },
                    {
                        value: 'Заветы Ильича',
                        label: 'Заветы Ильича',
                    },
                    {
                        value: 'Известковый',
                        label: 'Известковый',
                    },
                    {
                        value: 'Иннокентьевка',
                        label: 'Иннокентьевка',
                    },
                    {
                        value: 'Комсомольск-на-Амуре',
                        label: 'Комсомольск-на-Амуре',
                    },
                    {
                        value: 'Нелькан',
                        label: 'Нелькан',
                    },
                    {
                        value: 'Николаевск-на-Амуре',
                        label: 'Николаевск-на-Амуре',
                    },
                    {
                        value: 'Облучье',
                        label: 'Облучье',
                    },
                    {
                        value: 'Охотск',
                        label: 'Охотск',
                    },
                    {
                        value: 'Переяславка',
                        label: 'Переяславка',
                    },
                    {
                        value: 'Смидович',
                        label: 'Смидович',
                    },
                    {
                        value: 'Советская Гавань',
                        label: 'Советская Гавань',
                    },
                    {
                        value: 'Софийск',
                        label: 'Софийск',
                    },
                    {
                        value: 'Троицкое',
                        label: 'Троицкое',
                    },
                    {
                        value: 'Тугур',
                        label: 'Тугур',
                    },
                    {
                        value: 'Хабаровск',
                        label: 'Хабаровск',
                    },
                    {
                        value: 'Чегдомын',
                        label: 'Чегдомын',
                    },
                    {
                        value: 'Чумикан',
                        label: 'Чумикан',
                    },
                ],
            },
            {
                value: 'Чукотский АО',
                label: 'Чукотский АО',
                children: [
                    {
                        value: 'Анадырь (Чукотский АО)',
                        label: 'Анадырь (Чукотский АО)',
                    },
                    {
                        value: 'Билибино',
                        label: 'Билибино',
                    },
                ],
            },
        ],
    },
    {
        label: 'Сибирский',
        value: 'Сибирский',
        children: [
            {
                value: 'Алтайский край',
                label: 'Алтайский край',
                children: [
                    {
                        value: 'Акташ',
                        label: 'Акташ',
                    },
                    {
                        value: 'Акутиха',
                        label: 'Акутиха',
                    },
                    {
                        value: 'Алейск',
                        label: 'Алейск',
                    },
                    {
                        value: 'Алтайский',
                        label: 'Алтайский',
                    },
                    {
                        value: 'Баево',
                        label: 'Баево',
                    },
                    {
                        value: 'Барнаул',
                        label: 'Барнаул',
                    },
                    {
                        value: 'Белово (Алтайский край)',
                        label: 'Белово (Алтайский край)',
                    },
                    {
                        value: 'Белокуриха',
                        label: 'Белокуриха',
                    },
                    {
                        value: 'Белоярск',
                        label: 'Белоярск',
                    },
                    {
                        value: 'Бийск',
                        label: 'Бийск',
                    },
                    {
                        value: 'Благовещенск',
                        label: 'Благовещенск',
                    },
                    {
                        value: 'Боровлянка',
                        label: 'Боровлянка',
                    },
                    {
                        value: 'Бурла',
                        label: 'Бурла',
                    },
                    {
                        value: 'Бурсоль',
                        label: 'Бурсоль',
                    },
                    {
                        value: 'Быстрый Исток',
                        label: 'Быстрый Исток',
                    },
                    {
                        value: 'Волчиха',
                        label: 'Волчиха',
                    },
                    {
                        value: 'Горно-Алтайск',
                        label: 'Горно-Алтайск',
                    },
                    {
                        value: 'Ельцовка',
                        label: 'Ельцовка',
                    },
                    {
                        value: 'Залесово',
                        label: 'Залесово',
                    },
                    {
                        value: 'Заринск',
                        label: 'Заринск',
                    },
                    {
                        value: 'Заток',
                        label: 'Заток',
                    },
                    {
                        value: 'Змеиногорск',
                        label: 'Змеиногорск',
                    },
                    {
                        value: 'Камень-на-Оби',
                        label: 'Камень-на-Оби',
                    },
                    {
                        value: 'Ключи (Алтайский край)',
                        label: 'Ключи (Алтайский край)',
                    },
                    {
                        value: 'Кош-Агач',
                        label: 'Кош-Агач',
                    },
                    {
                        value: 'Красногорское (Алтайский край)',
                        label: 'Красногорское (Алтайский край)',
                    },
                    {
                        value: 'Краснощеково',
                        label: 'Краснощеково',
                    },
                    {
                        value: 'Кулунда',
                        label: 'Кулунда',
                    },
                    {
                        value: 'Кытманово',
                        label: 'Кытманово',
                    },
                    {
                        value: 'Мамонтово',
                        label: 'Мамонтово',
                    },
                    {
                        value: 'Новичиха',
                        label: 'Новичиха',
                    },
                    {
                        value: 'Новоалтайск',
                        label: 'Новоалтайск',
                    },
                    {
                        value: 'Онгудай',
                        label: 'Онгудай',
                    },
                    {
                        value: 'Павловск (Алтайский край)',
                        label: 'Павловск (Алтайский край)',
                    },
                    {
                        value: 'Петропавловское',
                        label: 'Петропавловское',
                    },
                    {
                        value: 'Поспелиха',
                        label: 'Поспелиха',
                    },
                    {
                        value: 'Ребриха',
                        label: 'Ребриха',
                    },
                    {
                        value: 'Родино',
                        label: 'Родино',
                    },
                    {
                        value: 'Рубцовск',
                        label: 'Рубцовск',
                    },
                    {
                        value: 'Славгород',
                        label: 'Славгород',
                    },
                    {
                        value: 'Смоленское',
                        label: 'Смоленское',
                    },
                    {
                        value: 'Солонешное',
                        label: 'Солонешное',
                    },
                    {
                        value: 'Солтон',
                        label: 'Солтон',
                    },
                    {
                        value: 'Староаллейское',
                        label: 'Староаллейское',
                    },
                    {
                        value: 'Табуны',
                        label: 'Табуны',
                    },
                    {
                        value: 'Тальменка',
                        label: 'Тальменка',
                    },
                    {
                        value: 'Тогул',
                        label: 'Тогул',
                    },
                    {
                        value: 'Топчиха',
                        label: 'Топчиха',
                    },
                    {
                        value: 'Троицкое (Алтайский край)',
                        label: 'Троицкое (Алтайский край)',
                    },
                    {
                        value: 'Турочак',
                        label: 'Турочак',
                    },
                    {
                        value: 'Тюменцево',
                        label: 'Тюменцево',
                    },
                    {
                        value: 'Угловское',
                        label: 'Угловское',
                    },
                    {
                        value: 'Усть-Калманка',
                        label: 'Усть-Калманка',
                    },
                    {
                        value: 'Усть-Кан',
                        label: 'Усть-Кан',
                    },
                    {
                        value: 'Усть-Кокса',
                        label: 'Усть-Кокса',
                    },
                    {
                        value: 'Усть-Улаган',
                        label: 'Усть-Улаган',
                    },
                    {
                        value: 'Усть-Чарышская Пристань',
                        label: 'Усть-Чарышская Пристань',
                    },
                    {
                        value: 'Хабары',
                        label: 'Хабары',
                    },
                    {
                        value: 'Целинное',
                        label: 'Целинное',
                    },
                    {
                        value: 'Чарышское',
                        label: 'Чарышское',
                    },
                    {
                        value: 'Шебалино',
                        label: 'Шебалино',
                    },
                    {
                        value: 'Шелаболиха',
                        label: 'Шелаболиха',
                    },
                    {
                        value: 'Шипуново',
                        label: 'Шипуново',
                    },
                ],
            },
            {
                value: 'Республика Бурятия',
                label: 'Республика Бурятия',
                children: [
                    {
                        value: 'Бабушкин',
                        label: 'Бабушкин',
                    },
                    {
                        value: 'Багдарин',
                        label: 'Багдарин',
                    },
                    {
                        value: 'Баргузин',
                        label: 'Баргузин',
                    },
                    {
                        value: 'Баянгол',
                        label: 'Баянгол',
                    },
                    {
                        value: 'Бичура',
                        label: 'Бичура',
                    },
                    {
                        value: 'Выдрино',
                        label: 'Выдрино',
                    },
                    {
                        value: 'Гусиное Озеро',
                        label: 'Гусиное Озеро',
                    },
                    {
                        value: 'Гусиноозерск',
                        label: 'Гусиноозерск',
                    },
                    {
                        value: 'Заиграево',
                        label: 'Заиграево',
                    },
                    {
                        value: 'Закаменск',
                        label: 'Закаменск',
                    },
                    {
                        value: 'Иволгинск',
                        label: 'Иволгинск',
                    },
                    {
                        value: 'Илька',
                        label: 'Илька',
                    },
                    {
                        value: 'Кабанск',
                        label: 'Кабанск',
                    },
                    {
                        value: 'Каменск',
                        label: 'Каменск',
                    },
                    {
                        value: 'Кижинга',
                        label: 'Кижинга',
                    },
                    {
                        value: 'Курумкан',
                        label: 'Курумкан',
                    },
                    {
                        value: 'Кырен',
                        label: 'Кырен',
                    },
                    {
                        value: 'Кяхта',
                        label: 'Кяхта',
                    },
                    {
                        value: 'Монды',
                        label: 'Монды',
                    },
                    {
                        value: 'Мухоршибирь',
                        label: 'Мухоршибирь',
                    },
                    {
                        value: 'Нижнеангарск',
                        label: 'Нижнеангарск',
                    },
                    {
                        value: 'Орлик',
                        label: 'Орлик',
                    },
                    {
                        value: 'Петропавловка (Бурятия)',
                        label: 'Петропавловка (Бурятия)',
                    },
                    {
                        value: 'Романовка (Бурятия)',
                        label: 'Романовка (Бурятия)',
                    },
                    {
                        value: 'Северобайкальск (Бурятия)',
                        label: 'Северобайкальск (Бурятия)',
                    },
                    {
                        value: 'Селенгинск',
                        label: 'Селенгинск',
                    },
                    {
                        value: 'Сосново-Озерское',
                        label: 'Сосново-Озерское',
                    },
                    {
                        value: 'Таксимо (Бурятия)',
                        label: 'Таксимо (Бурятия)',
                    },
                    {
                        value: 'Турунтаево',
                        label: 'Турунтаево',
                    },
                    {
                        value: 'Улан-Удэ',
                        label: 'Улан-Удэ',
                    },
                    {
                        value: 'Хоринск',
                        label: 'Хоринск',
                    },
                ],
            },
            {
                value: 'Иркутская область',
                label: 'Иркутская область',
                children: [
                    {
                        value: 'Алексеевск',
                        label: 'Алексеевск',
                    },
                    {
                        value: 'Алзамай',
                        label: 'Алзамай',
                    },
                    {
                        value: 'Алыгжер',
                        label: 'Алыгжер',
                    },
                    {
                        value: 'Ангарск',
                        label: 'Ангарск',
                    },
                    {
                        value: 'Артемовский (Иркутская обл.)',
                        label: 'Артемовский (Иркутская обл.)',
                    },
                    {
                        value: 'Байкал',
                        label: 'Байкал',
                    },
                    {
                        value: 'Байкальск',
                        label: 'Байкальск',
                    },
                    {
                        value: 'Балаганск',
                        label: 'Балаганск',
                    },
                    {
                        value: 'Баяндай',
                        label: 'Баяндай',
                    },
                    {
                        value: 'Бирюсинск',
                        label: 'Бирюсинск',
                    },
                    {
                        value: 'Бодайбо',
                        label: 'Бодайбо',
                    },
                    {
                        value: 'Большая Речка',
                        label: 'Большая Речка',
                    },
                    {
                        value: 'Большой Луг',
                        label: 'Большой Луг',
                    },
                    {
                        value: 'Бохан',
                        label: 'Бохан',
                    },
                    {
                        value: 'Братск',
                        label: 'Братск',
                    },
                    {
                        value: 'Видим',
                        label: 'Видим',
                    },
                    {
                        value: 'Витимский',
                        label: 'Витимский',
                    },
                    {
                        value: 'Вихоревка',
                        label: 'Вихоревка',
                    },
                    {
                        value: 'Еланцы',
                        label: 'Еланцы',
                    },
                    {
                        value: 'Ербогачен',
                        label: 'Ербогачен',
                    },
                    {
                        value: 'Железногорск-Илимский',
                        label: 'Железногорск-Илимский',
                    },
                    {
                        value: 'Жигалово',
                        label: 'Жигалово',
                    },
                    {
                        value: 'Забитуй',
                        label: 'Забитуй',
                    },
                    {
                        value: 'Залари',
                        label: 'Залари',
                    },
                    {
                        value: 'Звездный',
                        label: 'Звездный',
                    },
                    {
                        value: 'Зима',
                        label: 'Зима',
                    },
                    {
                        value: 'Иркутск',
                        label: 'Иркутск',
                    },
                    {
                        value: 'Казачинское (Иркутская обл.)',
                        label: 'Казачинское (Иркутская обл.)',
                    },
                    {
                        value: 'Качуг',
                        label: 'Качуг',
                    },
                    {
                        value: 'Квиток',
                        label: 'Квиток',
                    },
                    {
                        value: 'Киренск',
                        label: 'Киренск',
                    },
                    {
                        value: 'Куйтун',
                        label: 'Куйтун',
                    },
                    {
                        value: 'Култук',
                        label: 'Култук',
                    },
                    {
                        value: 'Кутулик',
                        label: 'Кутулик',
                    },
                    {
                        value: 'Мама',
                        label: 'Мама',
                    },
                    {
                        value: 'Нижнеудинск',
                        label: 'Нижнеудинск',
                    },
                    {
                        value: 'Оса',
                        label: 'Оса',
                    },
                    {
                        value: 'Саянск',
                        label: 'Саянск',
                    },
                    {
                        value: 'Слюдянка',
                        label: 'Слюдянка',
                    },
                    {
                        value: 'Тайшет',
                        label: 'Тайшет',
                    },
                    {
                        value: 'Тулун',
                        label: 'Тулун',
                    },
                    {
                        value: 'Усолье-Сибирское (Иркутская обл.)',
                        label: 'Усолье-Сибирское (Иркутская обл.)',
                    },
                    {
                        value: 'Усть-Илимск',
                        label: 'Усть-Илимск',
                    },
                    {
                        value: 'Усть-Кут',
                        label: 'Усть-Кут',
                    },
                    {
                        value: 'Усть-Ордынский',
                        label: 'Усть-Ордынский',
                    },
                    {
                        value: 'Усть-Уда',
                        label: 'Усть-Уда',
                    },
                    {
                        value: 'Черемхово',
                        label: 'Черемхово',
                    },
                    {
                        value: 'Чунский',
                        label: 'Чунский',
                    },
                    {
                        value: 'Шелехов',
                        label: 'Шелехов',
                    },
                ],
            },
            {
                value: 'Кемеровская область',
                label: 'Кемеровская область',
                children: [
                    {
                        value: 'Анжеро-Судженск',
                        label: 'Анжеро-Судженск',
                    },
                    {
                        value: 'Барзас',
                        label: 'Барзас',
                    },
                    {
                        value: 'Белово',
                        label: 'Белово',
                    },
                    {
                        value: 'Березовский (Кемеровская обл.)',
                        label: 'Березовский (Кемеровская обл.)',
                    },
                    {
                        value: 'Грамотеино',
                        label: 'Грамотеино',
                    },
                    {
                        value: 'Гурьевск',
                        label: 'Гурьевск',
                    },
                    {
                        value: 'Ижморский',
                        label: 'Ижморский',
                    },
                    {
                        value: 'Итатский',
                        label: 'Итатский',
                    },
                    {
                        value: 'Калтан',
                        label: 'Калтан',
                    },
                    {
                        value: 'Кедровка',
                        label: 'Кедровка',
                    },
                    {
                        value: 'Кемерово',
                        label: 'Кемерово',
                    },
                    {
                        value: 'Киселевск',
                        label: 'Киселевск',
                    },
                    {
                        value: 'Крапивинский',
                        label: 'Крапивинский',
                    },
                    {
                        value: 'Ленинск-Кузнецкий',
                        label: 'Ленинск-Кузнецкий',
                    },
                    {
                        value: 'Мариинск',
                        label: 'Мариинск',
                    },
                    {
                        value: 'Междуреченск',
                        label: 'Междуреченск',
                    },
                    {
                        value: 'Мыски',
                        label: 'Мыски',
                    },
                    {
                        value: 'Новокузнецк',
                        label: 'Новокузнецк',
                    },
                    {
                        value: 'Осинники',
                        label: 'Осинники',
                    },
                    {
                        value: 'Полысаево',
                        label: 'Полысаево',
                    },
                    {
                        value: 'Прокопьевск',
                        label: 'Прокопьевск',
                    },
                    {
                        value: 'Промышленная',
                        label: 'Промышленная',
                    },
                    {
                        value: 'Тайга',
                        label: 'Тайга',
                    },
                    {
                        value: 'Таштагол',
                        label: 'Таштагол',
                    },
                    {
                        value: 'Тисуль',
                        label: 'Тисуль',
                    },
                    {
                        value: 'Топки',
                        label: 'Топки',
                    },
                    {
                        value: 'Тяжинский',
                        label: 'Тяжинский',
                    },
                    {
                        value: 'Юрга',
                        label: 'Юрга',
                    },
                    {
                        value: 'Яшкино',
                        label: 'Яшкино',
                    },
                    {
                        value: 'Яя',
                        label: 'Яя',
                    },
                ],
            },
            {
                value: 'Красноярский край',
                label: 'Красноярский край',
                children: [
                    {
                        value: 'Абаза',
                        label: 'Абаза',
                    },
                    {
                        value: 'Агинское',
                        label: 'Агинское',
                    },
                    {
                        value: 'Артемовск',
                        label: 'Артемовск',
                    },
                    {
                        value: 'Аскиз',
                        label: 'Аскиз',
                    },
                    {
                        value: 'Ачинск',
                        label: 'Ачинск',
                    },
                    {
                        value: 'Байкит',
                        label: 'Байкит',
                    },
                    {
                        value: 'Балахта',
                        label: 'Балахта',
                    },
                    {
                        value: 'Балыкса',
                        label: 'Балыкса',
                    },
                    {
                        value: 'Бея',
                        label: 'Бея',
                    },
                    {
                        value: 'Бискамжа',
                        label: 'Бискамжа',
                    },
                    {
                        value: 'Боготол',
                        label: 'Боготол',
                    },
                    {
                        value: 'Боград',
                        label: 'Боград',
                    },
                    {
                        value: 'Богучаны',
                        label: 'Богучаны',
                    },
                    {
                        value: 'Большая Мурта',
                        label: 'Большая Мурта',
                    },
                    {
                        value: 'Большой Улуй',
                        label: 'Большой Улуй',
                    },
                    {
                        value: 'Бородино (Красноярский край)',
                        label: 'Бородино (Красноярский край)',
                    },
                    {
                        value: 'Ванавара',
                        label: 'Ванавара',
                    },
                    {
                        value: 'Горячегорск',
                        label: 'Горячегорск',
                    },
                    {
                        value: 'Дзержинское',
                        label: 'Дзержинское',
                    },
                    {
                        value: 'Дивногорск',
                        label: 'Дивногорск',
                    },
                    {
                        value: 'Диксон',
                        label: 'Диксон',
                    },
                    {
                        value: 'Дудинка',
                        label: 'Дудинка',
                    },
                    {
                        value: 'Емельяново',
                        label: 'Емельяново',
                    },
                    {
                        value: 'Енисейск',
                        label: 'Енисейск',
                    },
                    {
                        value: 'Ермаковское',
                        label: 'Ермаковское',
                    },
                    {
                        value: 'Заозерный',
                        label: 'Заозерный',
                    },
                    {
                        value: 'Зеленогорск (Красноярский край)',
                        label: 'Зеленогорск (Красноярский край)',
                    },
                    {
                        value: 'Игарка',
                        label: 'Игарка',
                    },
                    {
                        value: 'Идринское',
                        label: 'Идринское',
                    },
                    {
                        value: 'Иланский',
                        label: 'Иланский',
                    },
                    {
                        value: 'Ирбейское',
                        label: 'Ирбейское',
                    },
                    {
                        value: 'Казачинское (Красноярский край)',
                        label: 'Казачинское (Красноярский край)',
                    },
                    {
                        value: 'Канск',
                        label: 'Канск',
                    },
                    {
                        value: 'Каратузское',
                        label: 'Каратузское',
                    },
                    {
                        value: 'Караул',
                        label: 'Караул',
                    },
                    {
                        value: 'Кодинск',
                        label: 'Кодинск',
                    },
                    {
                        value: 'Козулька',
                        label: 'Козулька',
                    },
                    {
                        value: 'Копьево',
                        label: 'Копьево',
                    },
                    {
                        value: 'Краснотуранск',
                        label: 'Краснотуранск',
                    },
                    {
                        value: 'Красноярск',
                        label: 'Красноярск',
                    },
                    {
                        value: 'Курагино',
                        label: 'Курагино',
                    },
                    {
                        value: 'Лесосибирск',
                        label: 'Лесосибирск',
                    },
                    {
                        value: 'Минусинск',
                        label: 'Минусинск',
                    },
                    {
                        value: 'Мотыгино',
                        label: 'Мотыгино',
                    },
                    {
                        value: 'Назарово',
                        label: 'Назарово',
                    },
                    {
                        value: 'Нижний Ингаш',
                        label: 'Нижний Ингаш',
                    },
                    {
                        value: 'Новоселово',
                        label: 'Новоселово',
                    },
                    {
                        value: 'Норильск',
                        label: 'Норильск',
                    },
                    {
                        value: 'Партизанское',
                        label: 'Партизанское',
                    },
                    {
                        value: 'Пировское',
                        label: 'Пировское',
                    },
                    {
                        value: 'Северо-Енисейский',
                        label: 'Северо-Енисейский',
                    },
                    {
                        value: 'Сосновоборск (Красноярский край)',
                        label: 'Сосновоборск (Красноярский край)',
                    },
                    {
                        value: 'Тасеево',
                        label: 'Тасеево',
                    },
                    {
                        value: 'Таштып',
                        label: 'Таштып',
                    },
                    {
                        value: 'Тура',
                        label: 'Тура',
                    },
                    {
                        value: 'Туруханск',
                        label: 'Туруханск',
                    },
                    {
                        value: 'Тюхтет',
                        label: 'Тюхтет',
                    },
                    {
                        value: 'Ужур',
                        label: 'Ужур',
                    },
                    {
                        value: 'Уяр',
                        label: 'Уяр',
                    },
                    {
                        value: 'Хатанга',
                        label: 'Хатанга',
                    },
                    {
                        value: 'Черемушки',
                        label: 'Черемушки',
                    },
                    {
                        value: 'Шалинское',
                        label: 'Шалинское',
                    },
                    {
                        value: 'Шарыпово (Красноярский край)',
                        label: 'Шарыпово (Красноярский край)',
                    },
                    {
                        value: 'Шира',
                        label: 'Шира',
                    },
                    {
                        value: 'Шушенское',
                        label: 'Шушенское',
                    },
                ],
            },
            {
                value: 'Новосибирская область',
                label: 'Новосибирская область',
                children: [
                    {
                        value: 'Баган',
                        label: 'Баган',
                    },
                    {
                        value: 'Барабинск',
                        label: 'Барабинск',
                    },
                    {
                        value: 'Бердск',
                        label: 'Бердск',
                    },
                    {
                        value: 'Биаза',
                        label: 'Биаза',
                    },
                    {
                        value: 'Болотное',
                        label: 'Болотное',
                    },
                    {
                        value: 'Венгерово',
                        label: 'Венгерово',
                    },
                    {
                        value: 'Довольное',
                        label: 'Довольное',
                    },
                    {
                        value: 'Завьялово',
                        label: 'Завьялово',
                    },
                    {
                        value: 'Искитим',
                        label: 'Искитим',
                    },
                    {
                        value: 'Карасук',
                        label: 'Карасук',
                    },
                    {
                        value: 'Каргат',
                        label: 'Каргат',
                    },
                    {
                        value: 'Колывань',
                        label: 'Колывань',
                    },
                    {
                        value: 'Краснозерское',
                        label: 'Краснозерское',
                    },
                    {
                        value: 'Крутиха',
                        label: 'Крутиха',
                    },
                    {
                        value: 'Куйбышев (Новосибирская обл.)',
                        label: 'Куйбышев (Новосибирская обл.)',
                    },
                    {
                        value: 'Купино',
                        label: 'Купино',
                    },
                    {
                        value: 'Кыштовка',
                        label: 'Кыштовка',
                    },
                    {
                        value: 'Маслянино',
                        label: 'Маслянино',
                    },
                    {
                        value: 'Мошково',
                        label: 'Мошково',
                    },
                    {
                        value: 'Новосибирск',
                        label: 'Новосибирск',
                    },
                    {
                        value: 'Обь',
                        label: 'Обь',
                    },
                    {
                        value: 'Ордынское',
                        label: 'Ордынское',
                    },
                    {
                        value: 'Северное',
                        label: 'Северное',
                    },
                    {
                        value: 'Сузун',
                        label: 'Сузун',
                    },
                    {
                        value: 'Татарск',
                        label: 'Татарск',
                    },
                    {
                        value: 'Тогучин',
                        label: 'Тогучин',
                    },
                    {
                        value: 'Убинское',
                        label: 'Убинское',
                    },
                    {
                        value: 'Усть-Тарка',
                        label: 'Усть-Тарка',
                    },
                    {
                        value: 'Чаны',
                        label: 'Чаны',
                    },
                    {
                        value: 'Черепаново',
                        label: 'Черепаново',
                    },
                    {
                        value: 'Чистоозерное',
                        label: 'Чистоозерное',
                    },
                    {
                        value: 'Чулым',
                        label: 'Чулым',
                    },
                ],
            },
            {
                value: 'Омская область',
                label: 'Омская область',
                children: [
                    {
                        value: 'Береговой',
                        label: 'Береговой',
                    },
                    {
                        value: 'Большеречье',
                        label: 'Большеречье',
                    },
                    {
                        value: 'Большие Уки',
                        label: 'Большие Уки',
                    },
                    {
                        value: 'Горьковское',
                        label: 'Горьковское',
                    },
                    {
                        value: 'Знаменское (Омская обл.)',
                        label: 'Знаменское (Омская обл.)',
                    },
                    {
                        value: 'Исилькуль',
                        label: 'Исилькуль',
                    },
                    {
                        value: 'Калачинск',
                        label: 'Калачинск',
                    },
                    {
                        value: 'Колосовка',
                        label: 'Колосовка',
                    },
                    {
                        value: 'Кормиловка',
                        label: 'Кормиловка',
                    },
                    {
                        value: 'Крутинка',
                        label: 'Крутинка',
                    },
                    {
                        value: 'Любинский',
                        label: 'Любинский',
                    },
                    {
                        value: 'Марьяновка',
                        label: 'Марьяновка',
                    },
                    {
                        value: 'Муромцево',
                        label: 'Муромцево',
                    },
                    {
                        value: 'Называевск',
                        label: 'Называевск',
                    },
                    {
                        value: 'Нижняя Омка',
                        label: 'Нижняя Омка',
                    },
                    {
                        value: 'Нововаршавка',
                        label: 'Нововаршавка',
                    },
                    {
                        value: 'Одесское',
                        label: 'Одесское',
                    },
                    {
                        value: 'Оконешниково',
                        label: 'Оконешниково',
                    },
                    {
                        value: 'Омск',
                        label: 'Омск',
                    },
                    {
                        value: 'Павлоградка',
                        label: 'Павлоградка',
                    },
                    {
                        value: 'Полтавка',
                        label: 'Полтавка',
                    },
                    {
                        value: 'Русская Поляна',
                        label: 'Русская Поляна',
                    },
                    {
                        value: 'Саргатское',
                        label: 'Саргатское',
                    },
                    {
                        value: 'Седельниково',
                        label: 'Седельниково',
                    },
                    {
                        value: 'Таврическое',
                        label: 'Таврическое',
                    },
                    {
                        value: 'Тара',
                        label: 'Тара',
                    },
                    {
                        value: 'Тевриз',
                        label: 'Тевриз',
                    },
                    {
                        value: 'Тюкалинск',
                        label: 'Тюкалинск',
                    },
                    {
                        value: 'Усть-Ишим',
                        label: 'Усть-Ишим',
                    },
                    {
                        value: 'Черлак',
                        label: 'Черлак',
                    },
                    {
                        value: 'Шербакуль',
                        label: 'Шербакуль',
                    },
                ],
            },
            {
                value: 'Томская область',
                label: 'Томская область',
                children: [
                    {
                        value: 'Александровское (Томская обл.)',
                        label: 'Александровское (Томская обл.)',
                    },
                    {
                        value: 'Асино',
                        label: 'Асино',
                    },
                    {
                        value: 'Бакчар',
                        label: 'Бакчар',
                    },
                    {
                        value: 'Батурино',
                        label: 'Батурино',
                    },
                    {
                        value: 'Зырянское',
                        label: 'Зырянское',
                    },
                    {
                        value: 'Итатка',
                        label: 'Итатка',
                    },
                    {
                        value: 'Каргасок',
                        label: 'Каргасок',
                    },
                    {
                        value: 'Катайга',
                        label: 'Катайга',
                    },
                    {
                        value: 'Кожевниково',
                        label: 'Кожевниково',
                    },
                    {
                        value: 'Колпашево',
                        label: 'Колпашево',
                    },
                    {
                        value: 'Кривошеино',
                        label: 'Кривошеино',
                    },
                    {
                        value: 'Мельниково',
                        label: 'Мельниково',
                    },
                    {
                        value: 'Молчаново',
                        label: 'Молчаново',
                    },
                    {
                        value: 'Парабель',
                        label: 'Парабель',
                    },
                    {
                        value: 'Первомайское',
                        label: 'Первомайское',
                    },
                    {
                        value: 'Подгорное',
                        label: 'Подгорное',
                    },
                    {
                        value: 'Северск',
                        label: 'Северск',
                    },
                    {
                        value: 'Стрежевой',
                        label: 'Стрежевой',
                    },
                    {
                        value: 'Томск',
                        label: 'Томск',
                    },
                    {
                        value: 'Тымск',
                        label: 'Тымск',
                    },
                ],
            },
            {
                value: 'Тувинская Республика',
                label: 'Тувинская Республика',
                children: [
                    {
                        value: 'Ак-Довурак',
                        label: 'Ак-Довурак',
                    },
                    {
                        value: 'Бай Хаак',
                        label: 'Бай Хаак',
                    },
                    {
                        value: 'Кызыл',
                        label: 'Кызыл',
                    },
                    {
                        value: 'Самагалтай',
                        label: 'Самагалтай',
                    },
                    {
                        value: 'Сарыг-Сеп',
                        label: 'Сарыг-Сеп',
                    },
                    {
                        value: 'Тоора-Хем',
                        label: 'Тоора-Хем',
                    },
                    {
                        value: 'Туран',
                        label: 'Туран',
                    },
                    {
                        value: 'Тээли',
                        label: 'Тээли',
                    },
                    {
                        value: 'Хову-Аксы',
                        label: 'Хову-Аксы',
                    },
                    {
                        value: 'Чадан',
                        label: 'Чадан',
                    },
                    {
                        value: 'Шагонар',
                        label: 'Шагонар',
                    },
                    {
                        value: 'Эрзин',
                        label: 'Эрзин',
                    },
                ],
            },
            {
                value: 'Республика Хакасия',
                label: 'Республика Хакасия',
                children: [
                    {
                        value: 'Абакан',
                        label: 'Абакан',
                    },
                    {
                        value: 'Саяногорск',
                        label: 'Саяногорск',
                    },
                    {
                        value: 'Черногорск',
                        label: 'Черногорск',
                    },
                ],
            },
            {
                value: 'Забайкальский край',
                label: 'Забайкальский край',
                children: [
                    {
                        value: 'Аксеново-Зиловское',
                        label: 'Аксеново-Зиловское',
                    },
                    {
                        value: 'Акша',
                        label: 'Акша',
                    },
                    {
                        value: 'Арбагар',
                        label: 'Арбагар',
                    },
                    {
                        value: 'Атамановка',
                        label: 'Атамановка',
                    },
                    {
                        value: 'Балей',
                        label: 'Балей',
                    },
                    {
                        value: 'Борзя',
                        label: 'Борзя',
                    },
                    {
                        value: 'Букачача',
                        label: 'Букачача',
                    },
                    {
                        value: 'Газимурский Завод',
                        label: 'Газимурский Завод',
                    },
                    {
                        value: 'Давенда',
                        label: 'Давенда',
                    },
                    {
                        value: 'Дарасун',
                        label: 'Дарасун',
                    },
                    {
                        value: 'Домна',
                        label: 'Домна',
                    },
                    {
                        value: 'Дровяная',
                        label: 'Дровяная',
                    },
                    {
                        value: 'Дульдурга',
                        label: 'Дульдурга',
                    },
                    {
                        value: 'Забайкальск',
                        label: 'Забайкальск',
                    },
                    {
                        value: 'Карымское',
                        label: 'Карымское',
                    },
                    {
                        value: 'Ключевский',
                        label: 'Ключевский',
                    },
                    {
                        value: 'Кокуй',
                        label: 'Кокуй',
                    },
                    {
                        value: 'Краснокаменск',
                        label: 'Краснокаменск',
                    },
                    {
                        value: 'Красный Чикой',
                        label: 'Красный Чикой',
                    },
                    {
                        value: 'Кыра',
                        label: 'Кыра',
                    },
                    {
                        value: 'Моготуй',
                        label: 'Моготуй',
                    },
                    {
                        value: 'Могоча',
                        label: 'Могоча',
                    },
                    {
                        value: 'Нерчинск',
                        label: 'Нерчинск',
                    },
                    {
                        value: 'Нерчинский Завод',
                        label: 'Нерчинский Завод',
                    },
                    {
                        value: 'Нижний Цасучей',
                        label: 'Нижний Цасучей',
                    },
                    {
                        value: 'Оловянная',
                        label: 'Оловянная',
                    },
                    {
                        value: 'Первомайский (Читинская обл.)',
                        label: 'Первомайский (Читинская обл.)',
                    },
                    {
                        value: 'Петровск-Забайкальский',
                        label: 'Петровск-Забайкальский',
                    },
                    {
                        value: 'Приаргунск',
                        label: 'Приаргунск',
                    },
                    {
                        value: 'Сретенск',
                        label: 'Сретенск',
                    },
                    {
                        value: 'Тупик',
                        label: 'Тупик',
                    },
                    {
                        value: 'Улеты',
                        label: 'Улеты',
                    },
                    {
                        value: 'Хилок',
                        label: 'Хилок',
                    },
                    {
                        value: 'Чара',
                        label: 'Чара',
                    },
                    {
                        value: 'Чернышевск',
                        label: 'Чернышевск',
                    },
                    {
                        value: 'Чита',
                        label: 'Чита',
                    },
                    {
                        value: 'Шелопугино',
                        label: 'Шелопугино',
                    },
                    {
                        value: 'Шилка',
                        label: 'Шилка',
                    },
                ],
            },
        ],
    },
    {
        label: 'Уральский',
        value: 'Уральский',
        children: [
            {
                value: 'Курганская область',
                label: 'Курганская область',
                children: [
                    {
                        value: 'Варгаши',
                        label: 'Варгаши',
                    },
                    {
                        value: 'Глядянское',
                        label: 'Глядянское',
                    },
                    {
                        value: 'Далматово',
                        label: 'Далматово',
                    },
                    {
                        value: 'Каргаполье',
                        label: 'Каргаполье',
                    },
                    {
                        value: 'Катайск',
                        label: 'Катайск',
                    },
                    {
                        value: 'Кетово',
                        label: 'Кетово',
                    },
                    {
                        value: 'Курган',
                        label: 'Курган',
                    },
                    {
                        value: 'Куртамыш',
                        label: 'Куртамыш',
                    },
                    {
                        value: 'Лебяжье',
                        label: 'Лебяжье',
                    },
                    {
                        value: 'Макушино',
                        label: 'Макушино',
                    },
                    {
                        value: 'Мишкино',
                        label: 'Мишкино',
                    },
                    {
                        value: 'Мокроусово',
                        label: 'Мокроусово',
                    },
                    {
                        value: 'Петухово',
                        label: 'Петухово',
                    },
                    {
                        value: 'Половинное',
                        label: 'Половинное',
                    },
                    {
                        value: 'Сафакулево',
                        label: 'Сафакулево',
                    },
                    {
                        value: 'Шадринск',
                        label: 'Шадринск',
                    },
                    {
                        value: 'Шатрово',
                        label: 'Шатрово',
                    },
                    {
                        value: 'Шумиха',
                        label: 'Шумиха',
                    },
                    {
                        value: 'Щучье',
                        label: 'Щучье',
                    },
                    {
                        value: 'Юргамыш',
                        label: 'Юргамыш',
                    },
                ],
            },
            {
                value: 'Свердловская область',
                label: 'Свердловская область',
                children: [
                    {
                        value: 'Алапаевск',
                        label: 'Алапаевск',
                    },
                    {
                        value: 'Алтынай',
                        label: 'Алтынай',
                    },
                    {
                        value: 'Арамиль',
                        label: 'Арамиль',
                    },
                    {
                        value: 'Артемовский (Свердловская обл.)',
                        label: 'Артемовский (Свердловская обл.)',
                    },
                    {
                        value: 'Арти',
                        label: 'Арти',
                    },
                    {
                        value: 'Асбест',
                        label: 'Асбест',
                    },
                    {
                        value: 'Ачит',
                        label: 'Ачит',
                    },
                    {
                        value: 'Байкалово',
                        label: 'Байкалово',
                    },
                    {
                        value: 'Басьяновский',
                        label: 'Басьяновский',
                    },
                    {
                        value: 'Белоярский (Свердловская обл.)',
                        label: 'Белоярский (Свердловская обл.)',
                    },
                    {
                        value: 'Березовский (Свердловская обл.)',
                        label: 'Березовский (Свердловская обл.)',
                    },
                    {
                        value: 'Бисерть',
                        label: 'Бисерть',
                    },
                    {
                        value: 'Богданович',
                        label: 'Богданович',
                    },
                    {
                        value: 'Буланаш',
                        label: 'Буланаш',
                    },
                    {
                        value: 'Верхний Тагил',
                        label: 'Верхний Тагил',
                    },
                    {
                        value: 'Верхняя Пышма',
                        label: 'Верхняя Пышма',
                    },
                    {
                        value: 'Верхняя Салда',
                        label: 'Верхняя Салда',
                    },
                    {
                        value: 'Верхняя Синячиха',
                        label: 'Верхняя Синячиха',
                    },
                    {
                        value: 'Верхняя Сысерть',
                        label: 'Верхняя Сысерть',
                    },
                    {
                        value: 'Верхняя Тура',
                        label: 'Верхняя Тура',
                    },
                    {
                        value: 'Верхотурье',
                        label: 'Верхотурье',
                    },
                    {
                        value: 'Висим',
                        label: 'Висим',
                    },
                    {
                        value: 'Волчанск',
                        label: 'Волчанск',
                    },
                    {
                        value: 'Гари',
                        label: 'Гари',
                    },
                    {
                        value: 'Дегтярск',
                        label: 'Дегтярск',
                    },
                    {
                        value: 'Екатеринбург',
                        label: 'Екатеринбург',
                    },
                    {
                        value: 'Ертарский',
                        label: 'Ертарский',
                    },
                    {
                        value: 'Заводоуспенское',
                        label: 'Заводоуспенское',
                    },
                    {
                        value: 'Заречный',
                        label: 'Заречный',
                    },
                    {
                        value: 'Ивдель',
                        label: 'Ивдель',
                    },
                    {
                        value: 'Изумруд',
                        label: 'Изумруд',
                    },
                    {
                        value: 'Ирбит',
                        label: 'Ирбит',
                    },
                    {
                        value: 'Ис',
                        label: 'Ис',
                    },
                    {
                        value: 'Каменск-Уральский',
                        label: 'Каменск-Уральский',
                    },
                    {
                        value: 'Камышлов',
                        label: 'Камышлов',
                    },
                    {
                        value: 'Карпинск',
                        label: 'Карпинск',
                    },
                    {
                        value: 'Качканар',
                        label: 'Качканар',
                    },
                    {
                        value: 'Кировград',
                        label: 'Кировград',
                    },
                    {
                        value: 'Краснотурьинск',
                        label: 'Краснотурьинск',
                    },
                    {
                        value: 'Красноуральск',
                        label: 'Красноуральск',
                    },
                    {
                        value: 'Красноуфимск',
                        label: 'Красноуфимск',
                    },
                    {
                        value: 'Кушва',
                        label: 'Кушва',
                    },
                    {
                        value: 'Лесной',
                        label: 'Лесной',
                    },
                    {
                        value: 'Михайловск',
                        label: 'Михайловск',
                    },
                    {
                        value: 'Невьянск',
                        label: 'Невьянск',
                    },
                    {
                        value: 'Нижние Серги',
                        label: 'Нижние Серги',
                    },
                    {
                        value: 'Нижний Тагил',
                        label: 'Нижний Тагил',
                    },
                    {
                        value: 'Нижняя Салда',
                        label: 'Нижняя Салда',
                    },
                    {
                        value: 'Нижняя Тура',
                        label: 'Нижняя Тура',
                    },
                    {
                        value: 'Новая Ляля',
                        label: 'Новая Ляля',
                    },
                    {
                        value: 'Новоуральск',
                        label: 'Новоуральск',
                    },
                    {
                        value: 'Новоуральск (Свердловская обл.)',
                        label: 'Новоуральск (Свердловская обл.)',
                    },
                    {
                        value: 'Оус',
                        label: 'Оус',
                    },
                    {
                        value: 'Первоуральск',
                        label: 'Первоуральск',
                    },
                    {
                        value: 'Полевской',
                        label: 'Полевской',
                    },
                    {
                        value: 'Пышма',
                        label: 'Пышма',
                    },
                    {
                        value: 'Ревда (Свердловская обл.)',
                        label: 'Ревда (Свердловская обл.)',
                    },
                    {
                        value: 'Реж',
                        label: 'Реж',
                    },
                    {
                        value: 'Рефтинск',
                        label: 'Рефтинск',
                    },
                    {
                        value: 'Свердловск',
                        label: 'Свердловск',
                    },
                    {
                        value: 'Североуральск',
                        label: 'Североуральск',
                    },
                    {
                        value: 'Серов',
                        label: 'Серов',
                    },
                    {
                        value: 'Сосьва',
                        label: 'Сосьва',
                    },
                    {
                        value: 'Среднеуральск',
                        label: 'Среднеуральск',
                    },
                    {
                        value: 'Сухой Лог',
                        label: 'Сухой Лог',
                    },
                    {
                        value: 'Сысерть',
                        label: 'Сысерть',
                    },
                    {
                        value: 'Таборы',
                        label: 'Таборы',
                    },
                    {
                        value: 'Тавда',
                        label: 'Тавда',
                    },
                    {
                        value: 'Талица',
                        label: 'Талица',
                    },
                    {
                        value: 'Тугулым',
                        label: 'Тугулым',
                    },
                    {
                        value: 'Туринск',
                        label: 'Туринск',
                    },
                    {
                        value: 'Туринская Слобода',
                        label: 'Туринская Слобода',
                    },
                ],
            },
            {
                value: 'Тюменская область',
                label: 'Тюменская область',
                children: [
                    {
                        value: 'Яр-Сале',
                        label: 'Яр-Сале',
                    },
                    {
                        value: 'Абатский',
                        label: 'Абатский',
                    },
                    {
                        value: 'Аксарка',
                        label: 'Аксарка',
                    },
                    {
                        value: 'Армизонское',
                        label: 'Армизонское',
                    },
                    {
                        value: 'Аромашево',
                        label: 'Аромашево',
                    },
                    {
                        value: 'Белоярский (Тюменская обл.)',
                        label: 'Белоярский (Тюменская обл.)',
                    },
                    {
                        value: 'Бердюжье',
                        label: 'Бердюжье',
                    },
                    {
                        value: 'Березово',
                        label: 'Березово',
                    },
                    {
                        value: 'Большое Сорокино',
                        label: 'Большое Сорокино',
                    },
                    {
                        value: 'Вагай',
                        label: 'Вагай',
                    },
                    {
                        value: 'Викулово',
                        label: 'Викулово',
                    },
                    {
                        value: 'Винзили',
                        label: 'Винзили',
                    },
                    {
                        value: 'Голышманово',
                        label: 'Голышманово',
                    },
                    {
                        value: 'Губкинский (Тюменская обл.)',
                        label: 'Губкинский (Тюменская обл.)',
                    },
                    {
                        value: 'Заводопетровский',
                        label: 'Заводопетровский',
                    },
                    {
                        value: 'Заводоуковск',
                        label: 'Заводоуковск',
                    },
                    {
                        value: 'Игрим',
                        label: 'Игрим',
                    },
                    {
                        value: 'Исетское',
                        label: 'Исетское',
                    },
                    {
                        value: 'Ишим',
                        label: 'Ишим',
                    },
                    {
                        value: 'Казанское',
                        label: 'Казанское',
                    },
                    {
                        value: 'Казым-Мыс',
                        label: 'Казым-Мыс',
                    },
                    {
                        value: 'Когалым (Тюменская обл.)',
                        label: 'Когалым (Тюменская обл.)',
                    },
                    {
                        value: 'Кондинское',
                        label: 'Кондинское',
                    },
                    {
                        value: 'Красноселькуп',
                        label: 'Красноселькуп',
                    },
                    {
                        value: 'Лабытнанги',
                        label: 'Лабытнанги',
                    },
                    {
                        value: 'Ларьяк',
                        label: 'Ларьяк',
                    },
                    {
                        value: 'Лянторский',
                        label: 'Лянторский',
                    },
                    {
                        value: 'Мужи',
                        label: 'Мужи',
                    },
                    {
                        value: 'Муравленко (Тюменская обл.)',
                        label: 'Муравленко (Тюменская обл.)',
                    },
                    {
                        value: 'Надым (Тюменская обл.)',
                        label: 'Надым (Тюменская обл.)',
                    },
                    {
                        value: 'Находка (Тюменская обл.)',
                        label: 'Находка (Тюменская обл.)',
                    },
                    {
                        value: 'Нефтеюганск',
                        label: 'Нефтеюганск',
                    },
                    {
                        value: 'Нижневартовск',
                        label: 'Нижневартовск',
                    },
                    {
                        value: 'Нижняя Тавда',
                        label: 'Нижняя Тавда',
                    },
                    {
                        value: 'Новый Уренгой (Тюменская обл.)',
                        label: 'Новый Уренгой (Тюменская обл.)',
                    },
                    {
                        value: 'Ноябрьск (Тюменская обл.)',
                        label: 'Ноябрьск (Тюменская обл.)',
                    },
                    {
                        value: 'Нягань',
                        label: 'Нягань',
                    },
                    {
                        value: 'Октябрьское (Тюменская обл.)',
                        label: 'Октябрьское (Тюменская обл.)',
                    },
                    {
                        value: 'Омутинский',
                        label: 'Омутинский',
                    },
                    {
                        value: 'Покачи (Тюменская обл.)',
                        label: 'Покачи (Тюменская обл.)',
                    },
                    {
                        value: 'Приобье',
                        label: 'Приобье',
                    },
                    {
                        value: 'Радужный (Ханты-Мансийский АО)',
                        label: 'Радужный (Ханты-Мансийский АО)',
                    },
                    {
                        value: 'Сладково',
                        label: 'Сладково',
                    },
                    {
                        value: 'Советский (Тюменская обл.)',
                        label: 'Советский (Тюменская обл.)',
                    },
                    {
                        value: 'Сургут',
                        label: 'Сургут',
                    },
                    {
                        value: 'Тобольск',
                        label: 'Тобольск',
                    },
                    {
                        value: 'Тюмень',
                        label: 'Тюмень',
                    },
                    {
                        value: 'Уват',
                        label: 'Уват',
                    },
                    {
                        value: 'Унъюган',
                        label: 'Унъюган',
                    },
                    {
                        value: 'Упорово',
                        label: 'Упорово',
                    },
                    {
                        value: 'Юрибей',
                        label: 'Юрибей',
                    },
                    {
                        value: 'Ялуторовск',
                        label: 'Ялуторовск',
                    },
                    {
                        value: 'Ярково',
                        label: 'Ярково',
                    },
                ],
            },
            {
                value: 'Челябинская область',
                label: 'Челябинская область',
                children: [
                    {
                        value: 'Агаповка',
                        label: 'Агаповка',
                    },
                    {
                        value: 'Аргаяш',
                        label: 'Аргаяш',
                    },
                    {
                        value: 'Аша',
                        label: 'Аша',
                    },
                    {
                        value: 'Бакал',
                        label: 'Бакал',
                    },
                    {
                        value: 'Бреды',
                        label: 'Бреды',
                    },
                    {
                        value: 'Варна',
                        label: 'Варна',
                    },
                    {
                        value: 'Верхнеуральск',
                        label: 'Верхнеуральск',
                    },
                    {
                        value: 'Верхний Уфалей',
                        label: 'Верхний Уфалей',
                    },
                    {
                        value: 'Еманжелинск',
                        label: 'Еманжелинск',
                    },
                    {
                        value: 'Златоуст',
                        label: 'Златоуст',
                    },
                    {
                        value: 'Карабаш',
                        label: 'Карабаш',
                    },
                    {
                        value: 'Карталы',
                        label: 'Карталы',
                    },
                    {
                        value: 'Касли',
                        label: 'Касли',
                    },
                    {
                        value: 'Катав-Ивановск',
                        label: 'Катав-Ивановск',
                    },
                    {
                        value: 'Копейск',
                        label: 'Копейск',
                    },
                    {
                        value: 'Коркино',
                        label: 'Коркино',
                    },
                    {
                        value: 'Красногорский',
                        label: 'Красногорский',
                    },
                    {
                        value: 'Кунашак',
                        label: 'Кунашак',
                    },
                    {
                        value: 'Куса',
                        label: 'Куса',
                    },
                    {
                        value: 'Кыштым',
                        label: 'Кыштым',
                    },
                    {
                        value: 'Магнитогорск',
                        label: 'Магнитогорск',
                    },
                    {
                        value: 'Миасс',
                        label: 'Миасс',
                    },
                    {
                        value: 'Миньяр',
                        label: 'Миньяр',
                    },
                    {
                        value: 'Озерск(Челябинская обл.)',
                        label: 'Озерск(Челябинская обл.)',
                    },
                    {
                        value: 'Октябрьское (Челябинская обл.)',
                        label: 'Октябрьское (Челябинская обл.)',
                    },
                    {
                        value: 'Пласт',
                        label: 'Пласт',
                    },
                    {
                        value: 'Сатка',
                        label: 'Сатка',
                    },
                    {
                        value: 'Сим',
                        label: 'Сим',
                    },
                    {
                        value: 'Снежинск (Челябинская обл.)',
                        label: 'Снежинск (Челябинская обл.)',
                    },
                    {
                        value: 'Трехгорный',
                        label: 'Трехгорный',
                    },
                    {
                        value: 'Увельский',
                        label: 'Увельский',
                    },
                    {
                        value: 'Уйское',
                        label: 'Уйское',
                    },
                    {
                        value: 'Усть-Катав',
                        label: 'Усть-Катав',
                    },
                    {
                        value: 'Фершампенуаз',
                        label: 'Фершампенуаз',
                    },
                    {
                        value: 'Чебаркуль',
                        label: 'Чебаркуль',
                    },
                    {
                        value: 'Челябинск',
                        label: 'Челябинск',
                    },
                    {
                        value: 'Чесма',
                        label: 'Чесма',
                    },
                    {
                        value: 'Южно-Уральск',
                        label: 'Южно-Уральск',
                    },
                    {
                        value: 'Юрюзань',
                        label: 'Юрюзань',
                    },
                ],
            },
            {
                value: 'Ямало-Ненецкий АО',
                label: 'Ямало-Ненецкий АО',
                children: [
                    {
                        value: 'Губкинский (Ямало-Ненецкий АО)',
                        label: 'Губкинский (Ямало-Ненецкий АО)',
                    },
                    {
                        value: 'Заполярный (Ямало-Ненецкий АО)',
                        label: 'Заполярный (Ямало-Ненецкий АО)',
                    },
                    {
                        value: 'Муравленко',
                        label: 'Муравленко',
                    },
                    {
                        value: 'Надым',
                        label: 'Надым',
                    },
                    {
                        value: 'Новый Уренгой',
                        label: 'Новый Уренгой',
                    },
                    {
                        value: 'Ноябрьск',
                        label: 'Ноябрьск',
                    },
                    {
                        value: 'Пангоды',
                        label: 'Пангоды',
                    },
                    {
                        value: 'Пуровск',
                        label: 'Пуровск',
                    },
                    {
                        value: 'Салехард',
                        label: 'Салехард',
                    },
                    {
                        value: 'Тазовский',
                        label: 'Тазовский',
                    },
                    {
                        value: 'Тарко-Сале',
                        label: 'Тарко-Сале',
                    },
                ],
            },
            {
                value: 'Ханты-Мансийский АО',
                label: 'Ханты-Мансийский АО',
                children: [
                    {
                        value: 'Солнечный',
                        label: 'Солнечный',
                    },
                    {
                        value: 'Белый Яр',
                        label: 'Белый Яр',
                    },
                    {
                        value: 'Лянтор',
                        label: 'Лянтор',
                    },
                    {
                        value: 'Аган',
                        label: 'Аган',
                    },
                    {
                        value: 'Излучинск',
                        label: 'Излучинск',
                    },
                    {
                        value: 'Лангепас',
                        label: 'Лангепас',
                    },
                    {
                        value: 'Мегион',
                        label: 'Мегион',
                    },
                    {
                        value: 'Междуреченский',
                        label: 'Междуреченский',
                    },
                    {
                        value: 'Новоаганск',
                        label: 'Новоаганск',
                    },
                    {
                        value: 'Пыть-Ях',
                        label: 'Пыть-Ях',
                    },
                    {
                        value: 'Урай',
                        label: 'Урай',
                    },
                    {
                        value: 'Ханты-Мансийск',
                        label: 'Ханты-Мансийск',
                    },
                    {
                        value: 'Югорск',
                        label: 'Югорск',
                    },
                ],
            },
        ],
    },
    {
        label: 'Приволжский',
        value: 'Приволжский',
        children: [
            {
                value: 'Республика Башкортостан',
                label: 'Республика Башкортостан',
                children: [
                    {
                        value: 'Агидель',
                        label: 'Агидель',
                    },
                    {
                        value: 'Аксаково',
                        label: 'Аксаково',
                    },
                    {
                        value: 'Амзя',
                        label: 'Амзя',
                    },
                    {
                        value: 'Архангелькое',
                        label: 'Архангелькое',
                    },
                    {
                        value: 'Аскарово',
                        label: 'Аскарово',
                    },
                    {
                        value: 'Аскино',
                        label: 'Аскино',
                    },
                    {
                        value: 'Баймак',
                        label: 'Баймак',
                    },
                    {
                        value: 'Бакалы',
                        label: 'Бакалы',
                    },
                    {
                        value: 'Белебей',
                        label: 'Белебей',
                    },
                    {
                        value: 'Белорецк',
                        label: 'Белорецк',
                    },
                    {
                        value: 'Бижбуляк',
                        label: 'Бижбуляк',
                    },
                    {
                        value: 'Бирск',
                        label: 'Бирск',
                    },
                    {
                        value: 'Благовещенск (Башкирия)',
                        label: 'Благовещенск (Башкирия)',
                    },
                    {
                        value: 'Большеустьикинское',
                        label: 'Большеустьикинское',
                    },
                    {
                        value: 'Бураево',
                        label: 'Бураево',
                    },
                    {
                        value: 'Верхнеяркеево',
                        label: 'Верхнеяркеево',
                    },
                    {
                        value: 'Верхние Киги',
                        label: 'Верхние Киги',
                    },
                    {
                        value: 'Верхние Татышлы',
                        label: 'Верхние Татышлы',
                    },
                    {
                        value: 'Верхний Авзян',
                        label: 'Верхний Авзян',
                    },
                    {
                        value: 'Давлеканово',
                        label: 'Давлеканово',
                    },
                    {
                        value: 'Дуван',
                        label: 'Дуван',
                    },
                    {
                        value: 'Дюртюли',
                        label: 'Дюртюли',
                    },
                    {
                        value: 'Ермекеево',
                        label: 'Ермекеево',
                    },
                    {
                        value: 'Ермолаево',
                        label: 'Ермолаево',
                    },
                    {
                        value: 'Зилаир',
                        label: 'Зилаир',
                    },
                    {
                        value: 'Зирган',
                        label: 'Зирган',
                    },
                    {
                        value: 'Иглино',
                        label: 'Иглино',
                    },
                    {
                        value: 'Инзер',
                        label: 'Инзер',
                    },
                    {
                        value: 'Исянгулово',
                        label: 'Исянгулово',
                    },
                    {
                        value: 'Ишимбай',
                        label: 'Ишимбай',
                    },
                    {
                        value: 'Калтасы',
                        label: 'Калтасы',
                    },
                    {
                        value: 'Кананикольское',
                        label: 'Кананикольское',
                    },
                    {
                        value: 'Кандры',
                        label: 'Кандры',
                    },
                    {
                        value: 'Караидель',
                        label: 'Караидель',
                    },
                    {
                        value: 'Караидельский',
                        label: 'Караидельский',
                    },
                    {
                        value: 'Киргиз-Мияки',
                        label: 'Киргиз-Мияки',
                    },
                    {
                        value: 'Красноусольский',
                        label: 'Красноусольский',
                    },
                    {
                        value: 'Кумертау',
                        label: 'Кумертау',
                    },
                    {
                        value: 'Кушнаренково',
                        label: 'Кушнаренково',
                    },
                    {
                        value: 'Малояз',
                        label: 'Малояз',
                    },
                    {
                        value: 'Межгорье',
                        label: 'Межгорье',
                    },
                    {
                        value: 'Мелеуз',
                        label: 'Мелеуз',
                    },
                    {
                        value: 'Месягутово',
                        label: 'Месягутово',
                    },
                    {
                        value: 'Мраково',
                        label: 'Мраково',
                    },
                    {
                        value: 'Нефтекамск',
                        label: 'Нефтекамск',
                    },
                    {
                        value: 'Октябрьский (Башкирия)',
                        label: 'Октябрьский (Башкирия)',
                    },
                    {
                        value: 'Приютово',
                        label: 'Приютово',
                    },
                    {
                        value: 'Раевский',
                        label: 'Раевский',
                    },
                    {
                        value: 'Салават',
                        label: 'Салават',
                    },
                    {
                        value: 'Сибай',
                        label: 'Сибай',
                    },
                    {
                        value: 'Старобалтачево',
                        label: 'Старобалтачево',
                    },
                    {
                        value: 'Старосубхангулово',
                        label: 'Старосубхангулово',
                    },
                    {
                        value: 'Стерлибашево',
                        label: 'Стерлибашево',
                    },
                    {
                        value: 'Стерлитамак',
                        label: 'Стерлитамак',
                    },
                    {
                        value: 'Туймазы',
                        label: 'Туймазы',
                    },
                    {
                        value: 'Уфа',
                        label: 'Уфа',
                    },
                    {
                        value: 'Учалы',
                        label: 'Учалы',
                    },
                    {
                        value: 'Федоровка (Башкирия)',
                        label: 'Федоровка (Башкирия)',
                    },
                    {
                        value: 'Чекмагуш',
                        label: 'Чекмагуш',
                    },
                    {
                        value: 'Чишмы',
                        label: 'Чишмы',
                    },
                    {
                        value: 'Шаран',
                        label: 'Шаран',
                    },
                    {
                        value: 'Янаул',
                        label: 'Янаул',
                    },
                ],
            },
            {
                value: 'Кировская область',
                label: 'Кировская область',
                children: [
                    {
                        value: 'Кикнур',
                        label: 'Кикнур',
                    },
                    {
                        value: 'Кильмезь',
                        label: 'Кильмезь',
                    },
                    {
                        value: 'Арбаж',
                        label: 'Арбаж',
                    },
                    {
                        value: 'Аркуль',
                        label: 'Аркуль',
                    },
                    {
                        value: 'Белая Холуница',
                        label: 'Белая Холуница',
                    },
                    {
                        value: 'Богородское (Кировская обл.)',
                        label: 'Богородское (Кировская обл.)',
                    },
                    {
                        value: 'Боровой',
                        label: 'Боровой',
                    },
                    {
                        value: 'Верхошижемье',
                        label: 'Верхошижемье',
                    },
                    {
                        value: 'Вятские Поляны (Кировская обл.)',
                        label: 'Вятские Поляны (Кировская обл.)',
                    },
                    {
                        value: 'Зуевка',
                        label: 'Зуевка',
                    },
                    {
                        value: 'Каринторф',
                        label: 'Каринторф',
                    },
                    {
                        value: 'Киров (Кировская обл.)',
                        label: 'Киров (Кировская обл.)',
                    },
                    {
                        value: 'Кирово-Чепецк',
                        label: 'Кирово-Чепецк',
                    },
                    {
                        value: 'Кирс',
                        label: 'Кирс',
                    },
                    {
                        value: 'Кобра',
                        label: 'Кобра',
                    },
                    {
                        value: 'Котельнич',
                        label: 'Котельнич',
                    },
                    {
                        value: 'Кумены',
                        label: 'Кумены',
                    },
                    {
                        value: 'Ленинское',
                        label: 'Ленинское',
                    },
                    {
                        value: 'Луза',
                        label: 'Луза',
                    },
                    {
                        value: 'Малмыж',
                        label: 'Малмыж',
                    },
                    {
                        value: 'Мураши',
                        label: 'Мураши',
                    },
                    {
                        value: 'Нагорск',
                        label: 'Нагорск',
                    },
                    {
                        value: 'Нема',
                        label: 'Нема',
                    },
                    {
                        value: 'Нововятск',
                        label: 'Нововятск',
                    },
                    {
                        value: 'Нолинск',
                        label: 'Нолинск',
                    },
                    {
                        value: 'Омутнинск',
                        label: 'Омутнинск',
                    },
                    {
                        value: 'Опарино',
                        label: 'Опарино',
                    },
                    {
                        value: 'Оричи',
                        label: 'Оричи',
                    },
                    {
                        value: 'Пижанка',
                        label: 'Пижанка',
                    },
                    {
                        value: 'Подосиновец',
                        label: 'Подосиновец',
                    },
                    {
                        value: 'Санчурск',
                        label: 'Санчурск',
                    },
                    {
                        value: 'Свеча',
                        label: 'Свеча',
                    },
                    {
                        value: 'Слободской',
                        label: 'Слободской',
                    },
                    {
                        value: 'Советск (Кировская обл.)',
                        label: 'Советск (Кировская обл.)',
                    },
                    {
                        value: 'Суна',
                        label: 'Суна',
                    },
                    {
                        value: 'Тужа',
                        label: 'Тужа',
                    },
                    {
                        value: 'Уни',
                        label: 'Уни',
                    },
                    {
                        value: 'Уржум',
                        label: 'Уржум',
                    },
                    {
                        value: 'Фаленки',
                        label: 'Фаленки',
                    },
                    {
                        value: 'Халтурин',
                        label: 'Халтурин',
                    },
                    {
                        value: 'Юрья',
                        label: 'Юрья',
                    },
                    {
                        value: 'Яранск',
                        label: 'Яранск',
                    },
                ],
            },
            {
                value: 'Республика Марий Эл',
                label: 'Республика Марий Эл',
                children: [
                    {
                        value: 'Волжск',
                        label: 'Волжск',
                    },
                    {
                        value: 'Дубовский',
                        label: 'Дубовский',
                    },
                    {
                        value: 'Звенигово',
                        label: 'Звенигово',
                    },
                    {
                        value: 'Йошкар-Ола',
                        label: 'Йошкар-Ола',
                    },
                    {
                        value: 'Килемары',
                        label: 'Килемары',
                    },
                    {
                        value: 'Козьмодемьянск',
                        label: 'Козьмодемьянск',
                    },
                    {
                        value: 'Куженер',
                        label: 'Куженер',
                    },
                    {
                        value: 'Мари-Турек',
                        label: 'Мари-Турек',
                    },
                    {
                        value: 'Медведево',
                        label: 'Медведево',
                    },
                    {
                        value: 'Морки',
                        label: 'Морки',
                    },
                    {
                        value: 'Новый Торьял',
                        label: 'Новый Торьял',
                    },
                    {
                        value: 'Оршанка',
                        label: 'Оршанка',
                    },
                    {
                        value: 'Параньга',
                        label: 'Параньга',
                    },
                    {
                        value: 'Сернур',
                        label: 'Сернур',
                    },
                    {
                        value: 'Советский (Марий Эл)',
                        label: 'Советский (Марий Эл)',
                    },
                    {
                        value: 'Юрино',
                        label: 'Юрино',
                    },
                ],
            },
            {
                value: 'Республика Мордовия',
                label: 'Республика Мордовия',
                children: [
                    {
                        value: 'Ардатов',
                        label: 'Ардатов',
                    },
                    {
                        value: 'Атюрьево',
                        label: 'Атюрьево',
                    },
                    {
                        value: 'Атяшево',
                        label: 'Атяшево',
                    },
                    {
                        value: 'Большие Березники',
                        label: 'Большие Березники',
                    },
                    {
                        value: 'Большое Игнатово',
                        label: 'Большое Игнатово',
                    },
                    {
                        value: 'Выша',
                        label: 'Выша',
                    },
                    {
                        value: 'Ельники',
                        label: 'Ельники',
                    },
                    {
                        value: 'Зубова Поляна',
                        label: 'Зубова Поляна',
                    },
                    {
                        value: 'Инсар',
                        label: 'Инсар',
                    },
                    {
                        value: 'Кадошкино',
                        label: 'Кадошкино',
                    },
                    {
                        value: 'Кемля',
                        label: 'Кемля',
                    },
                    {
                        value: 'Ковылкино',
                        label: 'Ковылкино',
                    },
                    {
                        value: 'Комсомольский (Мордовия)',
                        label: 'Комсомольский (Мордовия)',
                    },
                    {
                        value: 'Кочкурово',
                        label: 'Кочкурово',
                    },
                    {
                        value: 'Краснослободск',
                        label: 'Краснослободск',
                    },
                    {
                        value: 'Лямбирь',
                        label: 'Лямбирь',
                    },
                    {
                        value: 'Ромоданово',
                        label: 'Ромоданово',
                    },
                    {
                        value: 'Рузаевка',
                        label: 'Рузаевка',
                    },
                    {
                        value: 'Саранск',
                        label: 'Саранск',
                    },
                    {
                        value: 'Старое Шайгово',
                        label: 'Старое Шайгово',
                    },
                    {
                        value: 'Темников',
                        label: 'Темников',
                    },
                    {
                        value: 'Теньгушево',
                        label: 'Теньгушево',
                    },
                    {
                        value: 'Торбеево',
                        label: 'Торбеево',
                    },
                    {
                        value: 'Чамзинка',
                        label: 'Чамзинка',
                    },
                ],
            },
            {
                value: 'Нижегородская область',
                label: 'Нижегородская область',
                children: [
                    {
                        value: 'Арзамас',
                        label: 'Арзамас',
                    },
                    {
                        value: 'Арья',
                        label: 'Арья',
                    },
                    {
                        value: 'Балахна',
                        label: 'Балахна',
                    },
                    {
                        value: 'Богородск',
                        label: 'Богородск',
                    },
                    {
                        value: 'Большереченск',
                        label: 'Большереченск',
                    },
                    {
                        value: 'Большое Болдино',
                        label: 'Большое Болдино',
                    },
                    {
                        value: 'Большое Козино',
                        label: 'Большое Козино',
                    },
                    {
                        value: 'Большое Мурашкино',
                        label: 'Большое Мурашкино',
                    },
                    {
                        value: 'Большое Пикино',
                        label: 'Большое Пикино',
                    },
                    {
                        value: 'Бор',
                        label: 'Бор',
                    },
                    {
                        value: 'Бутурлино',
                        label: 'Бутурлино',
                    },
                    {
                        value: 'Вад',
                        label: 'Вад',
                    },
                    {
                        value: 'Варнавино',
                        label: 'Варнавино',
                    },
                    {
                        value: 'Васильсурск',
                        label: 'Васильсурск',
                    },
                    {
                        value: 'Вахтан',
                        label: 'Вахтан',
                    },
                    {
                        value: 'Вача',
                        label: 'Вача',
                    },
                    {
                        value: 'Ветлуга',
                        label: 'Ветлуга',
                    },
                    {
                        value: 'Виля',
                        label: 'Виля',
                    },
                    {
                        value: 'Вознесенское',
                        label: 'Вознесенское',
                    },
                    {
                        value: 'Володарск',
                        label: 'Володарск',
                    },
                    {
                        value: 'Воротынец',
                        label: 'Воротынец',
                    },
                    {
                        value: 'Ворсма',
                        label: 'Ворсма',
                    },
                    {
                        value: 'Воскресенское',
                        label: 'Воскресенское',
                    },
                    {
                        value: 'Выездное',
                        label: 'Выездное',
                    },
                    {
                        value: 'Выкса',
                        label: 'Выкса',
                    },
                    {
                        value: 'Гагино',
                        label: 'Гагино',
                    },
                    {
                        value: 'Гидроторф',
                        label: 'Гидроторф',
                    },
                    {
                        value: 'Горбатов',
                        label: 'Горбатов',
                    },
                    {
                        value: 'Горбатовка',
                        label: 'Горбатовка',
                    },
                    {
                        value: 'Городец',
                        label: 'Городец',
                    },
                    {
                        value: 'Дальнее Константиново',
                        label: 'Дальнее Константиново',
                    },
                    {
                        value: 'Дзержинск',
                        label: 'Дзержинск',
                    },
                    {
                        value: 'Дивеево',
                        label: 'Дивеево',
                    },
                    {
                        value: 'Досчатое',
                        label: 'Досчатое',
                    },
                    {
                        value: 'Заволжье',
                        label: 'Заволжье',
                    },
                    {
                        value: 'Керженец',
                        label: 'Керженец',
                    },
                    {
                        value: 'Княгинино',
                        label: 'Княгинино',
                    },
                    {
                        value: 'Ковернино',
                        label: 'Ковернино',
                    },
                    {
                        value: 'Красные Баки',
                        label: 'Красные Баки',
                    },
                    {
                        value: 'Кстово',
                        label: 'Кстово',
                    },
                    {
                        value: 'Кулебаки',
                        label: 'Кулебаки',
                    },
                    {
                        value: 'Лукоянов',
                        label: 'Лукоянов',
                    },
                    {
                        value: 'Лысково',
                        label: 'Лысково',
                    },
                    {
                        value: 'Навашино',
                        label: 'Навашино',
                    },
                    {
                        value: 'Нижний Новгород',
                        label: 'Нижний Новгород',
                    },
                    {
                        value: 'Новосмолинский',
                        label: 'Новосмолинский',
                    },
                    {
                        value: 'Павлово',
                        label: 'Павлово',
                    },
                    {
                        value: 'Первомайск',
                        label: 'Первомайск',
                    },
                    {
                        value: 'Перевоз',
                        label: 'Перевоз',
                    },
                    {
                        value: 'Пильна',
                        label: 'Пильна',
                    },
                    {
                        value: 'Починки',
                        label: 'Починки',
                    },
                    {
                        value: 'Саров (Нижегородская обл.)',
                        label: 'Саров (Нижегородская обл.)',
                    },
                    {
                        value: 'Семенов',
                        label: 'Семенов',
                    },
                    {
                        value: 'Сергач',
                        label: 'Сергач',
                    },
                    {
                        value: 'Сеченово',
                        label: 'Сеченово',
                    },
                    {
                        value: 'Сосновское',
                        label: 'Сосновское',
                    },
                    {
                        value: 'Спасское',
                        label: 'Спасское',
                    },
                    {
                        value: 'Тонкино',
                        label: 'Тонкино',
                    },
                    {
                        value: 'Тоншаево',
                        label: 'Тоншаево',
                    },
                    {
                        value: 'Уразовка',
                        label: 'Уразовка',
                    },
                    {
                        value: 'Урень',
                        label: 'Урень',
                    },
                    {
                        value: 'Чкаловск',
                        label: 'Чкаловск',
                    },
                    {
                        value: 'Шаранга',
                        label: 'Шаранга',
                    },
                    {
                        value: 'Шатки',
                        label: 'Шатки',
                    },
                    {
                        value: 'Шахунья',
                        label: 'Шахунья',
                    },
                ],
            },
            {
                value: 'Оренбургская область',
                label: 'Оренбургская область',
                children: [
                    {
                        value: 'Абдулино',
                        label: 'Абдулино',
                    },
                    {
                        value: 'Адамовка',
                        label: 'Адамовка',
                    },
                    {
                        value: 'Айдырлинский',
                        label: 'Айдырлинский',
                    },
                    {
                        value: 'Акбулак',
                        label: 'Акбулак',
                    },
                    {
                        value: 'Аккермановка',
                        label: 'Аккермановка',
                    },
                    {
                        value: 'Асекеево',
                        label: 'Асекеево',
                    },
                    {
                        value: 'Беляевка',
                        label: 'Беляевка',
                    },
                    {
                        value: 'Бугуруслан',
                        label: 'Бугуруслан',
                    },
                    {
                        value: 'Бузулук',
                        label: 'Бузулук',
                    },
                    {
                        value: 'Гай',
                        label: 'Гай',
                    },
                    {
                        value: 'Грачевка',
                        label: 'Грачевка',
                    },
                    {
                        value: 'Домбаровский',
                        label: 'Домбаровский',
                    },
                    {
                        value: 'Дубенский',
                        label: 'Дубенский',
                    },
                    {
                        value: 'Илек',
                        label: 'Илек',
                    },
                    {
                        value: 'Ириклинский',
                        label: 'Ириклинский',
                    },
                    {
                        value: 'Кувандык',
                        label: 'Кувандык',
                    },
                    {
                        value: 'Курманаевка',
                        label: 'Курманаевка',
                    },
                    {
                        value: 'Матвеевка',
                        label: 'Матвеевка',
                    },
                    {
                        value: 'Медногорск',
                        label: 'Медногорск',
                    },
                    {
                        value: 'Новоорск',
                        label: 'Новоорск',
                    },
                    {
                        value: 'Новосергиевка',
                        label: 'Новосергиевка',
                    },
                    {
                        value: 'Новотроицк',
                        label: 'Новотроицк',
                    },
                    {
                        value: 'Октябрьское (Оренбург.)',
                        label: 'Октябрьское (Оренбург.)',
                    },
                    {
                        value: 'Оренбург',
                        label: 'Оренбург',
                    },
                    {
                        value: 'Орск',
                        label: 'Орск',
                    },
                    {
                        value: 'Первомайский (Оренбург.)',
                        label: 'Первомайский (Оренбург.)',
                    },
                    {
                        value: 'Переволоцкий',
                        label: 'Переволоцкий',
                    },
                    {
                        value: 'Пономаревка',
                        label: 'Пономаревка',
                    },
                    {
                        value: 'Саракташ',
                        label: 'Саракташ',
                    },
                    {
                        value: 'Светлый (Оренбургская обл.)',
                        label: 'Светлый (Оренбургская обл.)',
                    },
                    {
                        value: 'Соль-Илецк',
                        label: 'Соль-Илецк',
                    },
                    {
                        value: 'Сорочинск',
                        label: 'Сорочинск',
                    },
                    {
                        value: 'Ташла',
                        label: 'Ташла',
                    },
                    {
                        value: 'Тоцкое',
                        label: 'Тоцкое',
                    },
                    {
                        value: 'Тюльган',
                        label: 'Тюльган',
                    },
                    {
                        value: 'Шарлык',
                        label: 'Шарлык',
                    },
                    {
                        value: 'Энергетик',
                        label: 'Энергетик',
                    },
                    {
                        value: 'Ясный',
                        label: 'Ясный',
                    },
                ],
            },
            {
                value: 'Пензенская область',
                label: 'Пензенская область',
                children: [
                    {
                        value: 'Башмаково',
                        label: 'Башмаково',
                    },
                    {
                        value: 'Беднодемьяновск',
                        label: 'Беднодемьяновск',
                    },
                    {
                        value: 'Беково',
                        label: 'Беково',
                    },
                    {
                        value: 'Белинский',
                        label: 'Белинский',
                    },
                    {
                        value: 'Бессоновка',
                        label: 'Бессоновка',
                    },
                    {
                        value: 'Вадинск',
                        label: 'Вадинск',
                    },
                    {
                        value: 'Верхозим',
                        label: 'Верхозим',
                    },
                    {
                        value: 'Городище (Пензенская обл.)',
                        label: 'Городище (Пензенская обл.)',
                    },
                    {
                        value: 'Евлашево',
                        label: 'Евлашево',
                    },
                    {
                        value: 'Заречный (Пензенская обл.)',
                        label: 'Заречный (Пензенская обл.)',
                    },
                    {
                        value: 'Земетчино',
                        label: 'Земетчино',
                    },
                    {
                        value: 'Золотаревка',
                        label: 'Золотаревка',
                    },
                    {
                        value: 'Исса',
                        label: 'Исса',
                    },
                    {
                        value: 'Каменка',
                        label: 'Каменка',
                    },
                    {
                        value: 'Колышлей',
                        label: 'Колышлей',
                    },
                    {
                        value: 'Кондоль',
                        label: 'Кондоль',
                    },
                    {
                        value: 'Кузнецк',
                        label: 'Кузнецк',
                    },
                    {
                        value: 'Лопатино',
                        label: 'Лопатино',
                    },
                    {
                        value: 'Малая Сердоба',
                        label: 'Малая Сердоба',
                    },
                    {
                        value: 'Мокшан',
                        label: 'Мокшан',
                    },
                    {
                        value: 'Наровчат',
                        label: 'Наровчат',
                    },
                    {
                        value: 'Неверкино',
                        label: 'Неверкино',
                    },
                    {
                        value: 'Нижний Ломов',
                        label: 'Нижний Ломов',
                    },
                    {
                        value: 'Никольск (Пензенская обл.)',
                        label: 'Никольск (Пензенская обл.)',
                    },
                    {
                        value: 'Пачелма',
                        label: 'Пачелма',
                    },
                    {
                        value: 'Пенза',
                        label: 'Пенза',
                    },
                    {
                        value: 'Русский Камешкир',
                        label: 'Русский Камешкир',
                    },
                    {
                        value: 'Сердобск',
                        label: 'Сердобск',
                    },
                    {
                        value: 'Сосновоборск',
                        label: 'Сосновоборск',
                    },
                    {
                        value: 'Сура',
                        label: 'Сура',
                    },
                    {
                        value: 'Тамала',
                        label: 'Тамала',
                    },
                    {
                        value: 'Шемышейка',
                        label: 'Шемышейка',
                    },
                ],
            },
            {
                value: 'Пермский край',
                label: 'Пермский край',
                children: [
                    {
                        value: 'Александровск',
                        label: 'Александровск',
                    },
                    {
                        value: 'Барда',
                        label: 'Барда',
                    },
                    {
                        value: 'Березники',
                        label: 'Березники',
                    },
                    {
                        value: 'Большая Соснова',
                        label: 'Большая Соснова',
                    },
                    {
                        value: 'Верещагино',
                        label: 'Верещагино',
                    },
                    {
                        value: 'Гайны',
                        label: 'Гайны',
                    },
                    {
                        value: 'Горнозаводск (Пермский край)',
                        label: 'Горнозаводск (Пермский край)',
                    },
                    {
                        value: 'Гремячинск',
                        label: 'Гремячинск',
                    },
                    {
                        value: 'Губаха',
                        label: 'Губаха',
                    },
                    {
                        value: 'Добрянка',
                        label: 'Добрянка',
                    },
                    {
                        value: 'Елово',
                        label: 'Елово',
                    },
                    {
                        value: 'Зюкайка',
                        label: 'Зюкайка',
                    },
                    {
                        value: 'Ильинский (Пермская обл.)',
                        label: 'Ильинский (Пермская обл.)',
                    },
                    {
                        value: 'Карагай',
                        label: 'Карагай',
                    },
                    {
                        value: 'Керчевский',
                        label: 'Керчевский',
                    },
                    {
                        value: 'Кизел',
                        label: 'Кизел',
                    },
                    {
                        value: 'Коса',
                        label: 'Коса',
                    },
                    {
                        value: 'Кочево',
                        label: 'Кочево',
                    },
                    {
                        value: 'Красновишерск',
                        label: 'Красновишерск',
                    },
                    {
                        value: 'Краснокамск',
                        label: 'Краснокамск',
                    },
                    {
                        value: 'Кудымкар',
                        label: 'Кудымкар',
                    },
                    {
                        value: 'Куеда',
                        label: 'Куеда',
                    },
                    {
                        value: 'Кунгур',
                        label: 'Кунгур',
                    },
                    {
                        value: 'Лысьва',
                        label: 'Лысьва',
                    },
                    {
                        value: 'Ныроб',
                        label: 'Ныроб',
                    },
                    {
                        value: 'Нытва',
                        label: 'Нытва',
                    },
                    {
                        value: 'Октябрьский',
                        label: 'Октябрьский',
                    },
                    {
                        value: 'Орда',
                        label: 'Орда',
                    },
                    {
                        value: 'Оханск',
                        label: 'Оханск',
                    },
                    {
                        value: 'Очер',
                        label: 'Очер',
                    },
                    {
                        value: 'Пермь',
                        label: 'Пермь',
                    },
                    {
                        value: 'Соликамск',
                        label: 'Соликамск',
                    },
                    {
                        value: 'Суксун',
                        label: 'Суксун',
                    },
                    {
                        value: 'Уинское',
                        label: 'Уинское',
                    },
                    {
                        value: 'Усолье',
                        label: 'Усолье',
                    },
                    {
                        value: 'Усть-Кишерть',
                        label: 'Усть-Кишерть',
                    },
                    {
                        value: 'Чайковский',
                        label: 'Чайковский',
                    },
                    {
                        value: 'Частые',
                        label: 'Частые',
                    },
                    {
                        value: 'Чердынь',
                        label: 'Чердынь',
                    },
                    {
                        value: 'Чернореченский',
                        label: 'Чернореченский',
                    },
                    {
                        value: 'Чернушка',
                        label: 'Чернушка',
                    },
                    {
                        value: 'Чусовой',
                        label: 'Чусовой',
                    },
                    {
                        value: 'Юрла',
                        label: 'Юрла',
                    },
                    {
                        value: 'Юсьва',
                        label: 'Юсьва',
                    },
                ],
            },
            {
                value: 'Самарская область',
                label: 'Самарская область',
                children: [
                    {
                        value: 'Алексеевка (Самарская обл.)',
                        label: 'Алексеевка (Самарская обл.)',
                    },
                    {
                        value: 'Безенчук',
                        label: 'Безенчук',
                    },
                    {
                        value: 'Богатое',
                        label: 'Богатое',
                    },
                    {
                        value: 'Богатырь',
                        label: 'Богатырь',
                    },
                    {
                        value: 'Большая Глушица',
                        label: 'Большая Глушица',
                    },
                    {
                        value: 'Большая Глущица (Самарск.)',
                        label: 'Большая Глущица (Самарск.)',
                    },
                    {
                        value: 'Большая Черниговка',
                        label: 'Большая Черниговка',
                    },
                    {
                        value: 'Борское',
                        label: 'Борское',
                    },
                    {
                        value: 'Волжский (Самарская обл.)',
                        label: 'Волжский (Самарская обл.)',
                    },
                    {
                        value: 'Жигулевск',
                        label: 'Жигулевск',
                    },
                    {
                        value: 'Зольное',
                        label: 'Зольное',
                    },
                    {
                        value: 'Исаклы',
                        label: 'Исаклы',
                    },
                    {
                        value: 'Камышла',
                        label: 'Камышла',
                    },
                    {
                        value: 'Кинель',
                        label: 'Кинель',
                    },
                    {
                        value: 'Кинель-Черкасы',
                        label: 'Кинель-Черкасы',
                    },
                    {
                        value: 'Клявлино',
                        label: 'Клявлино',
                    },
                    {
                        value: 'Кошки',
                        label: 'Кошки',
                    },
                    {
                        value: 'Красноармейское (Самарск.)',
                        label: 'Красноармейское (Самарск.)',
                    },
                    {
                        value: 'Красный Яр (Самарская обл.)',
                        label: 'Красный Яр (Самарская обл.)',
                    },
                    {
                        value: 'Куйбышев',
                        label: 'Куйбышев',
                    },
                    {
                        value: 'Нефтегорск',
                        label: 'Нефтегорск',
                    },
                    {
                        value: 'Новокуйбышевск',
                        label: 'Новокуйбышевск',
                    },
                    {
                        value: 'Октябрьск',
                        label: 'Октябрьск',
                    },
                    {
                        value: 'Отрадный',
                        label: 'Отрадный',
                    },
                    {
                        value: 'Пестравка',
                        label: 'Пестравка',
                    },
                    {
                        value: 'Похвистнево',
                        label: 'Похвистнево',
                    },
                    {
                        value: 'Приволжье',
                        label: 'Приволжье',
                    },
                    {
                        value: 'Самара',
                        label: 'Самара',
                    },
                    {
                        value: 'Сергиевск',
                        label: 'Сергиевск',
                    },
                    {
                        value: 'Сургут (Самарская обл.)',
                        label: 'Сургут (Самарская обл.)',
                    },
                    {
                        value: 'Сызрань',
                        label: 'Сызрань',
                    },
                    {
                        value: 'Тольятти',
                        label: 'Тольятти',
                    },
                    {
                        value: 'Хворостянка',
                        label: 'Хворостянка',
                    },
                    {
                        value: 'Чапаевск',
                        label: 'Чапаевск',
                    },
                    {
                        value: 'Челно-Вершины',
                        label: 'Челно-Вершины',
                    },
                    {
                        value: 'Шентала',
                        label: 'Шентала',
                    },
                    {
                        value: 'Шигоны',
                        label: 'Шигоны',
                    },
                ],
            },
            {
                value: 'Саратовская область',
                label: 'Саратовская область',
                children: [
                    {
                        value: 'Александров Гай',
                        label: 'Александров Гай',
                    },
                    {
                        value: 'Аркадак',
                        label: 'Аркадак',
                    },
                    {
                        value: 'Аткарск',
                        label: 'Аткарск',
                    },
                    {
                        value: 'Базарный Карабулак',
                        label: 'Базарный Карабулак',
                    },
                    {
                        value: 'Балаково',
                        label: 'Балаково',
                    },
                    {
                        value: 'Балашов',
                        label: 'Балашов',
                    },
                    {
                        value: 'Балтай',
                        label: 'Балтай',
                    },
                    {
                        value: 'Возрождение',
                        label: 'Возрождение',
                    },
                    {
                        value: 'Вольск',
                        label: 'Вольск',
                    },
                    {
                        value: 'Воскресенское (Саратовск.)',
                        label: 'Воскресенское (Саратовск.)',
                    },
                    {
                        value: 'Дергачи',
                        label: 'Дергачи',
                    },
                    {
                        value: 'Духовницкое',
                        label: 'Духовницкое',
                    },
                    {
                        value: 'Екатериновка',
                        label: 'Екатериновка',
                    },
                    {
                        value: 'Ершов',
                        label: 'Ершов',
                    },
                    {
                        value: 'Ивантеевка (Саратовская обл.)',
                        label: 'Ивантеевка (Саратовская обл.)',
                    },
                    {
                        value: 'Калининск',
                        label: 'Калининск',
                    },
                    {
                        value: 'Каменский',
                        label: 'Каменский',
                    },
                    {
                        value: 'Красноармейск (Саратовск.)',
                        label: 'Красноармейск (Саратовск.)',
                    },
                    {
                        value: 'Красный Кут',
                        label: 'Красный Кут',
                    },
                    {
                        value: 'Лысые Горы',
                        label: 'Лысые Горы',
                    },
                    {
                        value: 'Маркс',
                        label: 'Маркс',
                    },
                    {
                        value: 'Мокроус',
                        label: 'Мокроус',
                    },
                    {
                        value: 'Новоузенск',
                        label: 'Новоузенск',
                    },
                    {
                        value: 'Новые Бурасы',
                        label: 'Новые Бурасы',
                    },
                    {
                        value: 'Озинки',
                        label: 'Озинки',
                    },
                    {
                        value: 'Перелюб',
                        label: 'Перелюб',
                    },
                    {
                        value: 'Петровск',
                        label: 'Петровск',
                    },
                    {
                        value: 'Питерка',
                        label: 'Питерка',
                    },
                    {
                        value: 'Пугачев',
                        label: 'Пугачев',
                    },
                    {
                        value: 'Ровное',
                        label: 'Ровное',
                    },
                    {
                        value: 'Романовка',
                        label: 'Романовка',
                    },
                    {
                        value: 'Ртищево',
                        label: 'Ртищево',
                    },
                    {
                        value: 'Самойловка',
                        label: 'Самойловка',
                    },
                    {
                        value: 'Саратов',
                        label: 'Саратов',
                    },
                    {
                        value: 'Степное (Саратовская обл.)',
                        label: 'Степное (Саратовская обл.)',
                    },
                    {
                        value: 'Татищево',
                        label: 'Татищево',
                    },
                    {
                        value: 'Турки',
                        label: 'Турки',
                    },
                    {
                        value: 'Хвалынск',
                        label: 'Хвалынск',
                    },
                    {
                        value: 'Энгельс',
                        label: 'Энгельс',
                    },
                ],
            },
            {
                value: 'Республика Татарстан',
                label: 'Республика Татарстан',
                children: [
                    {
                        value: 'Агрыз',
                        label: 'Агрыз',
                    },
                    {
                        value: 'Азнакаево',
                        label: 'Азнакаево',
                    },
                    {
                        value: 'Аксубаево',
                        label: 'Аксубаево',
                    },
                    {
                        value: 'Актаныш',
                        label: 'Актаныш',
                    },
                    {
                        value: 'Актюбинский',
                        label: 'Актюбинский',
                    },
                    {
                        value: 'Алексеевское',
                        label: 'Алексеевское',
                    },
                    {
                        value: 'Альметьевск',
                        label: 'Альметьевск',
                    },
                    {
                        value: 'Апастово',
                        label: 'Апастово',
                    },
                    {
                        value: 'Арск',
                        label: 'Арск',
                    },
                    {
                        value: 'Бавлы',
                        label: 'Бавлы',
                    },
                    {
                        value: 'Базарные Матаки',
                        label: 'Базарные Матаки',
                    },
                    {
                        value: 'Балтаси',
                        label: 'Балтаси',
                    },
                    {
                        value: 'Богатые Сабы',
                        label: 'Богатые Сабы',
                    },
                    {
                        value: 'Бугульма',
                        label: 'Бугульма',
                    },
                    {
                        value: 'Буинск',
                        label: 'Буинск',
                    },
                    {
                        value: 'Васильево',
                        label: 'Васильево',
                    },
                    {
                        value: 'Верхний Услон',
                        label: 'Верхний Услон',
                    },
                    {
                        value: 'Высокая Гора',
                        label: 'Высокая Гора',
                    },
                    {
                        value: 'Дербешкинский',
                        label: 'Дербешкинский',
                    },
                    {
                        value: 'Елабуга',
                        label: 'Елабуга',
                    },
                    {
                        value: 'Заинск',
                        label: 'Заинск',
                    },
                    {
                        value: 'Зеленодольск',
                        label: 'Зеленодольск',
                    },
                    {
                        value: 'Казань',
                        label: 'Казань',
                    },
                    {
                        value: 'Камское Устье',
                        label: 'Камское Устье',
                    },
                    {
                        value: 'Карабаш (Татарстан)',
                        label: 'Карабаш (Татарстан)',
                    },
                    {
                        value: 'Куйбышев (Татарстан)',
                        label: 'Куйбышев (Татарстан)',
                    },
                    {
                        value: 'Кукмор',
                        label: 'Кукмор',
                    },
                    {
                        value: 'Лаишево',
                        label: 'Лаишево',
                    },
                    {
                        value: 'Лениногорск',
                        label: 'Лениногорск',
                    },
                    {
                        value: 'Мамадыш',
                        label: 'Мамадыш',
                    },
                    {
                        value: 'Менделеевск',
                        label: 'Менделеевск',
                    },
                    {
                        value: 'Мензелинск',
                        label: 'Мензелинск',
                    },
                    {
                        value: 'Муслюмово',
                        label: 'Муслюмово',
                    },
                    {
                        value: 'Набережные Челны',
                        label: 'Набережные Челны',
                    },
                    {
                        value: 'Нижнекамск',
                        label: 'Нижнекамск',
                    },
                    {
                        value: 'Новошешминск',
                        label: 'Новошешминск',
                    },
                    {
                        value: 'Нурлат',
                        label: 'Нурлат',
                    },
                    {
                        value: 'Пестрецы',
                        label: 'Пестрецы',
                    },
                    {
                        value: 'Рыбная Слобода',
                        label: 'Рыбная Слобода',
                    },
                    {
                        value: 'Сарманово',
                        label: 'Сарманово',
                    },
                    {
                        value: 'Старое Дрожжаное',
                        label: 'Старое Дрожжаное',
                    },
                    {
                        value: 'Тетюши',
                        label: 'Тетюши',
                    },
                    {
                        value: 'Черемшан',
                        label: 'Черемшан',
                    },
                    {
                        value: 'Чистополь',
                        label: 'Чистополь',
                    },
                ],
            },
            {
                value: 'Республика Удмуртия',
                label: 'Республика Удмуртия',
                children: [
                    {
                        value: 'Алнаши',
                        label: 'Алнаши',
                    },
                    {
                        value: 'Балезино',
                        label: 'Балезино',
                    },
                    {
                        value: 'Вавож',
                        label: 'Вавож',
                    },
                    {
                        value: 'Воткинск',
                        label: 'Воткинск',
                    },
                    {
                        value: 'Глазов',
                        label: 'Глазов',
                    },
                    {
                        value: 'Грахово',
                        label: 'Грахово',
                    },
                    {
                        value: 'Дебесы',
                        label: 'Дебесы',
                    },
                    {
                        value: 'Игра',
                        label: 'Игра',
                    },
                    {
                        value: 'Ижевск',
                        label: 'Ижевск',
                    },
                    {
                        value: 'Кама',
                        label: 'Кама',
                    },
                    {
                        value: 'Камбарка',
                        label: 'Камбарка',
                    },
                    {
                        value: 'Каракулино',
                        label: 'Каракулино',
                    },
                    {
                        value: 'Кез',
                        label: 'Кез',
                    },
                    {
                        value: 'Кизнер',
                        label: 'Кизнер',
                    },
                    {
                        value: 'Киясово',
                        label: 'Киясово',
                    },
                    {
                        value: 'Красногорское (Удмуртия)',
                        label: 'Красногорское (Удмуртия)',
                    },
                    {
                        value: 'Можга',
                        label: 'Можга',
                    },
                    {
                        value: 'Сарапул',
                        label: 'Сарапул',
                    },
                    {
                        value: 'Селты',
                        label: 'Селты',
                    },
                    {
                        value: 'Сюмси',
                        label: 'Сюмси',
                    },
                    {
                        value: 'Ува',
                        label: 'Ува',
                    },
                    {
                        value: 'Устинов',
                        label: 'Устинов',
                    },
                    {
                        value: 'Шаркан',
                        label: 'Шаркан',
                    },
                    {
                        value: 'Юкаменское',
                        label: 'Юкаменское',
                    },
                    {
                        value: 'Якшур-Бодья',
                        label: 'Якшур-Бодья',
                    },
                    {
                        value: 'Яр',
                        label: 'Яр',
                    },
                ],
            },
            {
                value: 'Ульяновская область',
                label: 'Ульяновская область',
                children: [
                    {
                        value: 'Базарный Сызган',
                        label: 'Базарный Сызган',
                    },
                    {
                        value: 'Барыш',
                        label: 'Барыш',
                    },
                    {
                        value: 'Большое Нагаткино',
                        label: 'Большое Нагаткино',
                    },
                    {
                        value: 'Вешкайма',
                        label: 'Вешкайма',
                    },
                    {
                        value: 'Глотовка',
                        label: 'Глотовка',
                    },
                    {
                        value: 'Димитровград',
                        label: 'Димитровград',
                    },
                    {
                        value: 'Игнатовка',
                        label: 'Игнатовка',
                    },
                    {
                        value: 'Измайлово',
                        label: 'Измайлово',
                    },
                    {
                        value: 'Инза',
                        label: 'Инза',
                    },
                    {
                        value: 'Ишеевка',
                        label: 'Ишеевка',
                    },
                    {
                        value: 'Канадей',
                        label: 'Канадей',
                    },
                    {
                        value: 'Карсун',
                        label: 'Карсун',
                    },
                    {
                        value: 'Кузоватово',
                        label: 'Кузоватово',
                    },
                    {
                        value: 'Майна',
                        label: 'Майна',
                    },
                    {
                        value: 'Новая Малыкла',
                        label: 'Новая Малыкла',
                    },
                    {
                        value: 'Новоспасское',
                        label: 'Новоспасское',
                    },
                    {
                        value: 'Новоульяновск',
                        label: 'Новоульяновск',
                    },
                    {
                        value: 'Павловка',
                        label: 'Павловка',
                    },
                    {
                        value: 'Радищево',
                        label: 'Радищево',
                    },
                    {
                        value: 'Сенгилей',
                        label: 'Сенгилей',
                    },
                    {
                        value: 'Старая Кулатка',
                        label: 'Старая Кулатка',
                    },
                    {
                        value: 'Старая Майна',
                        label: 'Старая Майна',
                    },
                    {
                        value: 'Сурское',
                        label: 'Сурское',
                    },
                    {
                        value: 'Тереньга',
                        label: 'Тереньга',
                    },
                    {
                        value: 'Ульяновск',
                        label: 'Ульяновск',
                    },
                    {
                        value: 'Чердаклы',
                        label: 'Чердаклы',
                    },
                ],
            },
            {
                value: 'Республика Чувашия',
                label: 'Республика Чувашия',
                children: [
                    {
                        value: 'Алатырь',
                        label: 'Алатырь',
                    },
                    {
                        value: 'Аликово',
                        label: 'Аликово',
                    },
                    {
                        value: 'Батырева',
                        label: 'Батырева',
                    },
                    {
                        value: 'Вурнары',
                        label: 'Вурнары',
                    },
                    {
                        value: 'Ибреси',
                        label: 'Ибреси',
                    },
                    {
                        value: 'Канаш',
                        label: 'Канаш',
                    },
                    {
                        value: 'Киря',
                        label: 'Киря',
                    },
                    {
                        value: 'Комсомольское',
                        label: 'Комсомольское',
                    },
                    {
                        value: 'Красноармейское (Чувашия)',
                        label: 'Красноармейское (Чувашия)',
                    },
                    {
                        value: 'Красные Четаи',
                        label: 'Красные Четаи',
                    },
                    {
                        value: 'Кугеси',
                        label: 'Кугеси',
                    },
                    {
                        value: 'Мариинский Посад',
                        label: 'Мариинский Посад',
                    },
                    {
                        value: 'Моргауши',
                        label: 'Моргауши',
                    },
                    {
                        value: 'Новочебоксарск',
                        label: 'Новочебоксарск',
                    },
                    {
                        value: 'Порецкое',
                        label: 'Порецкое',
                    },
                    {
                        value: 'Урмары',
                        label: 'Урмары',
                    },
                    {
                        value: 'Цивильск',
                        label: 'Цивильск',
                    },
                    {
                        value: 'Чебоксары',
                        label: 'Чебоксары',
                    },
                    {
                        value: 'Шемурша',
                        label: 'Шемурша',
                    },
                    {
                        value: 'Шумерля',
                        label: 'Шумерля',
                    },
                    {
                        value: 'Ядрин',
                        label: 'Ядрин',
                    },
                    {
                        value: 'Яльчики',
                        label: 'Яльчики',
                    },
                    {
                        value: 'Янтиково',
                        label: 'Янтиково',
                    },
                ],
            },
        ],
    },
    {
        label: 'Северо-Кавказский',
        value: 'Северо-Кавказский',
        children: [
            {
                value: 'Республика Дагестан',
                label: 'Республика Дагестан',
                children: [
                    {
                        value: 'Агвали',
                        label: 'Агвали',
                    },
                    {
                        value: 'Акуша',
                        label: 'Акуша',
                    },
                    {
                        value: 'Ахты',
                        label: 'Ахты',
                    },
                    {
                        value: 'Ачису',
                        label: 'Ачису',
                    },
                    {
                        value: 'Бабаюрт',
                        label: 'Бабаюрт',
                    },
                    {
                        value: 'Бежта',
                        label: 'Бежта',
                    },
                    {
                        value: 'Ботлих',
                        label: 'Ботлих',
                    },
                    {
                        value: 'Буйнакск',
                        label: 'Буйнакск',
                    },
                    {
                        value: 'Вачи',
                        label: 'Вачи',
                    },
                    {
                        value: 'Гергебиль',
                        label: 'Гергебиль',
                    },
                    {
                        value: 'Гуниб',
                        label: 'Гуниб',
                    },
                    {
                        value: 'Дагестанские Огни',
                        label: 'Дагестанские Огни',
                    },
                    {
                        value: 'Дербент',
                        label: 'Дербент',
                    },
                    {
                        value: 'Дылым',
                        label: 'Дылым',
                    },
                    {
                        value: 'Ершовка',
                        label: 'Ершовка',
                    },
                    {
                        value: 'Избербаш',
                        label: 'Избербаш',
                    },
                    {
                        value: 'Карабудахкент',
                        label: 'Карабудахкент',
                    },
                    {
                        value: 'Карата',
                        label: 'Карата',
                    },
                    {
                        value: 'Каспийск',
                        label: 'Каспийск',
                    },
                    {
                        value: 'Касумкент',
                        label: 'Касумкент',
                    },
                    {
                        value: 'Кизилюрт',
                        label: 'Кизилюрт',
                    },
                    {
                        value: 'Кизляр',
                        label: 'Кизляр',
                    },
                    {
                        value: 'Кочубей',
                        label: 'Кочубей',
                    },
                    {
                        value: 'Кумух',
                        label: 'Кумух',
                    },
                    {
                        value: 'Курах',
                        label: 'Курах',
                    },
                    {
                        value: 'Магарамкент',
                        label: 'Магарамкент',
                    },
                    {
                        value: 'Маджалис',
                        label: 'Маджалис',
                    },
                    {
                        value: 'Махачкала',
                        label: 'Махачкала',
                    },
                    {
                        value: 'Мехельта',
                        label: 'Мехельта',
                    },
                    {
                        value: 'Новолакское',
                        label: 'Новолакское',
                    },
                    {
                        value: 'Рутул',
                        label: 'Рутул',
                    },
                    {
                        value: 'Советское (Дагестан)',
                        label: 'Советское (Дагестан)',
                    },
                    {
                        value: 'Тарумовка',
                        label: 'Тарумовка',
                    },
                    {
                        value: 'Терекли-Мектеб',
                        label: 'Терекли-Мектеб',
                    },
                    {
                        value: 'Тлярата',
                        label: 'Тлярата',
                    },
                    {
                        value: 'Тпиг',
                        label: 'Тпиг',
                    },
                    {
                        value: 'Унцукуль',
                        label: 'Унцукуль',
                    },
                    {
                        value: 'Уркарах',
                        label: 'Уркарах',
                    },
                    {
                        value: 'Хасавюрт',
                        label: 'Хасавюрт',
                    },
                    {
                        value: 'Хив',
                        label: 'Хив',
                    },
                    {
                        value: 'Хунзах',
                        label: 'Хунзах',
                    },
                    {
                        value: 'Цуриб',
                        label: 'Цуриб',
                    },
                    {
                        value: 'Южно-Сухокумск',
                        label: 'Южно-Сухокумск',
                    },
                ],
            },
            {
                value: 'Кабардино-Балкарская Республика',
                label: 'Кабардино-Балкарская Республика',
                children: [
                    {
                        value: 'Баксан',
                        label: 'Баксан',
                    },
                    {
                        value: 'Майский',
                        label: 'Майский',
                    },
                    {
                        value: 'Нальчик',
                        label: 'Нальчик',
                    },
                    {
                        value: 'Нарткала',
                        label: 'Нарткала',
                    },
                    {
                        value: 'Прохладный',
                        label: 'Прохладный',
                    },
                    {
                        value: 'Советское (Кабардино-Балкария)',
                        label: 'Советское (Кабардино-Балкария)',
                    },
                    {
                        value: 'Терек',
                        label: 'Терек',
                    },
                    {
                        value: 'Тырныауз',
                        label: 'Тырныауз',
                    },
                    {
                        value: 'Чегем-Первый',
                        label: 'Чегем-Первый',
                    },
                ],
            },
            {
                value: 'Северная Осетия-Алания',
                label: 'Северная Осетия-Алания',
                children: [
                    {
                        value: 'Алагир',
                        label: 'Алагир',
                    },
                    {
                        value: 'Ардон',
                        label: 'Ардон',
                    },
                    {
                        value: 'Беслан',
                        label: 'Беслан',
                    },
                    {
                        value: 'Бурон',
                        label: 'Бурон',
                    },
                    {
                        value: 'Владикавказ',
                        label: 'Владикавказ',
                    },
                    {
                        value: 'Дигора',
                        label: 'Дигора',
                    },
                    {
                        value: 'Моздок',
                        label: 'Моздок',
                    },
                    {
                        value: 'Орджоникидзе',
                        label: 'Орджоникидзе',
                    },
                    {
                        value: 'Чикола',
                        label: 'Чикола',
                    },
                ],
            },
            {
                value: 'Ставропольский край',
                label: 'Ставропольский край',
                children: [
                    {
                        value: 'Александровское (Ставрополь.)',
                        label: 'Александровское (Ставрополь.)',
                    },
                    {
                        value: 'Арзгир',
                        label: 'Арзгир',
                    },
                    {
                        value: 'Благодарный',
                        label: 'Благодарный',
                    },
                    {
                        value: 'Буденновск',
                        label: 'Буденновск',
                    },
                    {
                        value: 'Георгиевск',
                        label: 'Георгиевск',
                    },
                    {
                        value: 'Дивное (Ставропольский край)',
                        label: 'Дивное (Ставропольский край)',
                    },
                    {
                        value: 'Домбай',
                        label: 'Домбай',
                    },
                    {
                        value: 'Донское',
                        label: 'Донское',
                    },
                    {
                        value: 'Ессентуки',
                        label: 'Ессентуки',
                    },
                    {
                        value: 'Железноводск(Ставропольский)',
                        label: 'Железноводск(Ставропольский)',
                    },
                    {
                        value: 'Зеленокумск',
                        label: 'Зеленокумск',
                    },
                    {
                        value: 'Изобильный',
                        label: 'Изобильный',
                    },
                    {
                        value: 'Иноземцево',
                        label: 'Иноземцево',
                    },
                    {
                        value: 'Ипатово',
                        label: 'Ипатово',
                    },
                    {
                        value: 'Карачаевск',
                        label: 'Карачаевск',
                    },
                    {
                        value: 'Кисловодск',
                        label: 'Кисловодск',
                    },
                    {
                        value: 'Кочубеевское',
                        label: 'Кочубеевское',
                    },
                    {
                        value: 'Красногвардейское (Ставрополь.)',
                        label: 'Красногвардейское (Ставрополь.)',
                    },
                    {
                        value: 'Курсавка',
                        label: 'Курсавка',
                    },
                    {
                        value: 'Левокумское',
                        label: 'Левокумское',
                    },
                    {
                        value: 'Лермонтов',
                        label: 'Лермонтов',
                    },
                    {
                        value: 'Минеральные Воды',
                        label: 'Минеральные Воды',
                    },
                    {
                        value: 'Невинномысск',
                        label: 'Невинномысск',
                    },
                    {
                        value: 'Нефтекумск',
                        label: 'Нефтекумск',
                    },
                    {
                        value: 'Новоалександровск',
                        label: 'Новоалександровск',
                    },
                    {
                        value: 'Новоалександровская',
                        label: 'Новоалександровская',
                    },
                    {
                        value: 'Новопавловск',
                        label: 'Новопавловск',
                    },
                    {
                        value: 'Новоселицкое',
                        label: 'Новоселицкое',
                    },
                    {
                        value: 'Преградная',
                        label: 'Преградная',
                    },
                    {
                        value: 'Пятигорск',
                        label: 'Пятигорск',
                    },
                    {
                        value: 'Светлоград',
                        label: 'Светлоград',
                    },
                    {
                        value: 'Солнечнодольск',
                        label: 'Солнечнодольск',
                    },
                    {
                        value: 'Ставрополь',
                        label: 'Ставрополь',
                    },
                    {
                        value: 'Степное (Ставропольский край)',
                        label: 'Степное (Ставропольский край)',
                    },
                    {
                        value: 'Теберда',
                        label: 'Теберда',
                    },
                    {
                        value: 'Усть-Джегута',
                        label: 'Усть-Джегута',
                    },
                    {
                        value: 'Хабез',
                        label: 'Хабез',
                    },
                ],
            },
            {
                value: 'Чечено-Ингушетия',
                label: 'Чечено-Ингушетия',
                children: [
                    {
                        value: 'Аргун',
                        label: 'Аргун',
                    },
                    {
                        value: 'Грозный',
                        label: 'Грозный',
                    },
                    {
                        value: 'Гудермес',
                        label: 'Гудермес',
                    },
                    {
                        value: 'Знаменское',
                        label: 'Знаменское',
                    },
                    {
                        value: 'Малгобек',
                        label: 'Малгобек',
                    },
                    {
                        value: 'Назрань',
                        label: 'Назрань',
                    },
                    {
                        value: 'Наурская',
                        label: 'Наурская',
                    },
                    {
                        value: 'Ножай-Юрт',
                        label: 'Ножай-Юрт',
                    },
                    {
                        value: 'Орджоникидзевская',
                        label: 'Орджоникидзевская',
                    },
                    {
                        value: 'Советское (Чечено-Ингушетия)',
                        label: 'Советское (Чечено-Ингушетия)',
                    },
                    {
                        value: 'Урус-Мартан',
                        label: 'Урус-Мартан',
                    },
                    {
                        value: 'Шали',
                        label: 'Шали',
                    },
                ],
            },
            {
                value: 'Карачаево-Черкесская Республика',
                label: 'Карачаево-Черкесская Республика',
                children: [
                    {
                        value: 'Черкесск',
                        label: 'Черкесск',
                    },
                ],
            },
        ],
    },
];
export default tree;
