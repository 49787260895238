import { Modal } from '@mui/material';
import { ReactComponent as Close } from '../../assets/close.svg';
import TextField from '../../UI-kit/TextField/TextField';
import './EntityModal.scss';
import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { API_HOST } from '../../config';

const CreateBankAuditSchema = Yup.object().shape({
    full_name: Yup.string().max(300).required('Это обязательное поле'),
    ur_address: Yup.string().max(300).required('Это обязательное поле'),
    fact_address: Yup.string().max(300).required('Это обязательное поле'),
    phone_number: Yup.string()
        .length(18, 'введите корректный номер'),
    inn: Yup.string()
        .matches(/\d{10}/,'Значение должно быть ИНН')
        .length(10)
        .required('Это обязательное поле'),
    bank_name: Yup.string().max(300).required('Это обязательное поле'),
    bank_bik: Yup.string().matches(/\d{9}/,"Значение должно быть БИК ").length(9).required('Это обязательное поле'),
    correspondent_account: Yup.string()
        .matches(/\d{20}/,"Значение должно быть корреспондентский счет")
        .length(20)
        .required('Это обязательное поле'),
    payment_account: Yup.string()
        .matches(/\d{20}/,"Значение должно быть расчетный счет")
        .length(20)
        .required('Это обязательное поле'),
    sum: Yup.number()
        //.required('Это обязательное поле')
        .typeError('Только цифры')
        .min(4000, 'сумма не может быть менее 4000 рублей'),
});

function EntityModal({ open, handleClose }) {
    const [step, setStep] = useState(1);

    const formik = useFormik({
        initialValues: {
            full_name: '',
            ur_address: '',
            fact_address: '',
            inn: '',
            kpp: '',
            bank_bik: '',
            bank_name: '',
            correspondent_account: '',
            payment_account: '',
            phone_number: '',
            fio: '',
        },
        validationSchema: CreateBankAuditSchema,
        onSubmit: async (values) => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('user_token')
            );
            const res = await fetch(
                `${API_HOST}/api/v1/customer-panel/payment/requisities/entity`,
                {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify({
                        org_name: values.full_name,
                        full_name: values.fio,
                        ur_address: values.ur_address,
                        actual_address: values.fact_address,
                        inn: values.inn,
                        kpp: values.kpp,
                        bank_name: values.bank_name,
                        bank_bik: values.bank_bik,
                        phone_number: values.phone_number,
                        correspondent_account: values.correspondent_account,
                        payment_account: values.payment_account,
                    }),
                }
            );
            if (res.ok) {
                setStep(2);
            }else{
                const data = await res.json();
                console.log(data);
                let msg = ''
                await data.detail.forEach(el => {
                    msg +=el.loc[1] + ' ' + el.msg + ' '
                });
                alert(msg)
            }
        },
    });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="entity-modal">
                {step === 1 && (
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.full_name}
                            touched={formik.touched.full_name}
                            name={'full_name'}
                            placeholder={'Название организации *'}
                        />
                        <TextField
                            placeholder={'Название банка *'}
                            name={'bank_name'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.bank_name}
                            touched={formik.touched.bank_name}
                        />
                        <div className="inp_wrapper">
                            <TextField
                                placeholder={'ИНН *'}
                                name={'inn'}
                                type={'number'}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 10)
                                        value = value.slice(0, 10);
                                    formik.setFieldValue('inn', value)
                                }}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.inn}
                                touched={formik.touched.inn}
                                value={formik.values.inn}
                            />
                            <TextField
                                placeholder={'КПП'}
                                name={'kpp'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.kpp}
                                touched={formik.touched.kpp}
                            />
                            <TextField
                                placeholder={'БИК *'}
                                name={'bank_bik'}
                                type={'number'}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 9)
                                        value = value.slice(0, 9);
                                    formik.setFieldValue('bank_bik', value)
                                }}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.bank_bik}
                                touched={formik.touched.bank_bik}
                                value={formik.values.bank_bik}
                            />
                        </div>
                        <div className="wrapper">
                            <TextField
                                placeholder={'Р/С *'}
                                name={'payment_account'}
                                maxlength={20}
                                type={'number'}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 20)
                                        value = value.slice(0, 20);
                                    formik.setFieldValue('payment_account', value)
                                }}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.payment_account}
                                touched={formik.touched.payment_account}
                                value={formik.values.payment_account}
                            />
                            <TextField
                                placeholder={'Кор. счет *'}
                                name={'correspondent_account'}
                                type={'number'}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 20)
                                        value = value.slice(0, 20);
                                    formik.setFieldValue('correspondent_account', value)
                                }}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.correspondent_account}
                                touched={formik.touched.correspondent_account}
                                value={formik.values.correspondent_account}
                            />
                        </div>
                        <TextField
                            placeholder={'Ф.И.О. контактного лица'}
                            name={'fio'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.fio}
                            touched={formik.touched.fio}
                        />
                        <TextField
                            placeholder={'Телефон'}
                            name={'phone_number'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.phone_number}
                            touched={formik.touched.phone_number}
                            variant={'phone'}
                        />
                        <TextField
                            placeholder={'Юридический адрес *'}
                            name={'ur_address'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.ur_address}
                            touched={formik.touched.ur_address}
                        />
                        <TextField
                            placeholder={'Фактический адрес *'}
                            name={'fact_address'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.fact_address}
                            touched={formik.touched.fact_address}
                        />
                        <div className="btn-wrapper">
                            <button type="submit" className="accept">
                                Далее
                            </button>
                            <button onClick={handleClose} className="cancel">
                                Отмена
                            </button>
                        </div>
                    </form>
                )}
                {step === 2 && (
                    <>
                        <h2 className="title">Пополнить баланс</h2>
                        <h5 className="sub-title">
                            Обратите внимание что сумма не может быть менее 4000
                            рублей
                        </h5>
                        <TextField
                            placeholder={'Введите сумму пополнения'}
                            name={'sum'}
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.sum}
                            touched={formik.touched.sum}
                        />
                        <div className="btn-wrapper">
                            <button
                                onClick={async () => {
                                    if(+formik.values.sum >= 4000 ){
                                        const myHeaders = new Headers();
                                        myHeaders.append(
                                            'Content-Type',
                                            'application/json'
                                        );
                                        myHeaders.append(
                                            'Authorization',
                                            'Bearer ' +
                                                localStorage.getItem('user_token')
                                        );
                                        const link = document.createElement('a');
                                        const res = await fetch(
                                            `${API_HOST}/api/v1/customer-panel/payment/replenish/entity`,
                                            {
                                                method: 'post',
                                                headers: myHeaders,
                                                body:JSON.stringify(+formik.values.sum)
                                            }
                                        );
                                        if (res.ok) {
                                            const blob = await res.blob();
                                            link.download = 'Check.pdf';
                                            link.href = window.URL.createObjectURL(blob);
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                            handleClose()
                                        }else{
                                            const data = await res.json();
                                            console.log(data);
                                            let msg = ''
                                            await data.detail.forEach(el => {
                                                msg +=el.loc[1] + ' ' + el.msg + ' '
                                            });
                                            alert(msg)
                                        }
                                    }
                                }}
                                className="accept"
                            >
                                Пополнить
                            </button>
                            <button onClick={handleClose} className="cancel">
                                Отмена
                            </button>
                        </div>
                    </>
                )}
                <Close onClick={handleClose} className="close" />
            </div>
        </Modal>
    );
}

export default EntityModal;
