import './PasswordRecovery.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TextField from '../../UI-kit/TextField/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IMG from '../../assets/screenOne.png';
import { API_HOST } from '../../config';
import { useEffect } from 'react';

const FORMIK_INIT_VALUES = {
    password_again: '',
    password: '',
};

const VALIDATION_SHEMA = Yup.object().shape({
    password: Yup.string().required('Поле Пароль не может быть пустым')
    .matches(
        /^(?=.*[a-zA-Z])(?=.*[\d\W]).{8,}$/,
        'Пароль должен содержать не менее 8 символов, латинские буквы верхнего и нижнего регистра, хотябы одну цифру и специальный символ'
    ),
    password_again: Yup.string()
        .required('Это обязательное поле')
        .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
});

function PasswordRecovery() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        // eslint-disable-next-line no-unused-vars
        onSubmit: async (values) => {
            console.log(values.password,searchParams.get('confirmation_token'));
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
           const res = await fetch(
                `${API_HOST}/api/v1/auth/password/reset/confirm`,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                        token: searchParams.get('token'),
                        password: values.password,
                    }),
                }
            );
            if(res.ok){
                navigate('/start')
            }else{
                const data = await res.json();
                console.log(data);
                let msg = ''
                await data.detail.forEach(el => {
                    msg +=el.loc[1] + ' ' + el.msg + ' '
                });
                alert(msg)
            }
        },
    });

    useEffect(() => {
        formik.validateForm();
    }, []);

    return (
        <div className="PasswordRecovery">
            <div className="greetings">
                <Logo className="logo" />
                <div className="title">
                    <h1 className="title-mainText">
                        Простой путь к большим продажам
                    </h1>
                    <h4 className="title-subText">
                        Аналитика, управление и настройка в одном месте
                    </h4>
                </div>
                <div className="screen">
                    <img src={IMG} alt="screen" />
                </div>
            </div>
            <div className="form-wrapper">
                <HomeIcon className="homeIcon" onClick={() => navigate('/')} />
                <div className="title">
                    <h1 className="title-mainText">Восстановление доступа</h1>
                    <h4 className="title-subText">Придумайте новый пароль</h4>
                </div>
                <form className="form" onSubmit={formik.handleSubmit}>
                    <TextField
                        label={'Новый пароль'}
                        placeholder={'Новый пароль'}
                        name={'password'}
                        type={'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.password}
                        touched={formik.touched.password}
                    />
                    <TextField
                        label={'Повторите пароль'}
                        placeholder={'Повторите пароль'}
                        name={'password_again'}
                        type={'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.password_again}
                        touched={formik.touched.password_again}
                    />
                    <button type="submit" className={
                                    formik.isValid
                                        ? 'formBtn  active'
                                        : 'formBtn'
                                }>
                        Подтвердить
                    </button>
                </form>
            </div>
        </div>
    );
}

export default PasswordRecovery;
