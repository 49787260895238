import './SignIn.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { Link, useNavigate } from 'react-router-dom';
import CheckBox from '../../UI-kit/CheckBox/CheckBox';
import TextField from '../../UI-kit/TextField/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IMG from '../../assets/screenOne.png';
import { API_HOST } from '../../config';
import { useEffect, useState } from 'react';

const FORMIK_INIT_VALUES = {
    username: '',
    password: '',
    isSavePassword: true,
};

const VALIDATION_SHEMA = Yup.object().shape({
    username: Yup.string()
        .trim()
        .matches(
            /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i,
            'Введите корректный Email'
        )
        .required('Пожалуйста, введите ваш Email'),
    password: Yup.string().required('Это обязательное поле'),
});

function SignIn() {
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        onSubmit: async (values) => {
            values.username = values.username.trim();
            setError('');
            const params = new URLSearchParams();
            params.append('username', values.username.trim());
            params.append('password', values.password);
            const res = await fetch(`${API_HOST}/api/v1/auth/access-token`, {
                method: 'POST',
                body: params,
            });
            if (res.ok) {
                const data = await res.json();
                localStorage.setItem('user_token', data.access_token);
                navigate('/');
            } else {
                const data = await res.json();
                setError('Указан неверный пароль или email');
                alert(data.detail);
            }
        },
    });

    useEffect(() => {
      formik.validateForm();
    }, []);

    return (
        <div className="signIn">
            <div className="greetings">
                <Logo className="logo" />
                <div className="title">
                    <h1 className="title-mainText">
                        Простой путь к большим продажам
                    </h1>
                    <h4 className="title-subText">
                        Аналитика, управление и настройка в одном месте
                    </h4>
                </div>
                <div className="screen">
                    <img src={IMG} alt="screen" />
                </div>
            </div>
            <div className="form-wrapper">
                <HomeIcon className="homeIcon" onClick={() => navigate('/')} />
                <div className="title">
                    <h1 className="title-mainText">Войти в аккаунт</h1>
                    <h4 className="title-subText">
                        Нет аккаунта?{' '}
                        <Link to={'/sign-up'}>Зарегистрируйтесь</Link>
                    </h4>
                </div>
                {error && <h4 className='error-text'>{error}</h4>}
                <form className="form" onSubmit={formik.handleSubmit}>
                    <TextField
                        label={'Почта'}
                        placeholder={'Введите почту'}
                        name={'username'}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.username}
                        touched={formik.touched.username}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        label={'Пароль'}
                        placeholder={'Введите пароль'}
                        name={'password'}
                        type={'password'}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.password}
                        touched={formik.touched.password}
                        onChange={formik.handleChange}
                    />
                    <div className="action-buttons">
                        <CheckBox
                            label={'Запомнить меня'}
                            className="remeber"
                        />
                        <Link to={'/password-reset'}>Забыли пароль?</Link>
                    </div>

                    <button type="submit" className={formik.isValid  ? "formBtn  active" : "formBtn"}>
                        Войти
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SignIn;
