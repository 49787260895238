const columns = [
    {
        Header: 'Дата',
        accessor: 'date',
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => <div>{`${value.getDate()}/${value.getMonth()+1}/${value.getFullYear()}`}</div>,
        width: 400,
    },
    {
        Header: 'Сумма',
        accessor: 'count',
        sortType: 'number',
        width: 200,
    },
    {
        Header: 'Номер счета',
        accessor: 'number',
        sortType: 'string',
        width: 200,
    },
    {
        Header: 'Статус',
        accessor: 'status',
        disableSortBy: true,
        width: 100,
    },
    {
        Header: '',
        accessor: 'button',
        disableSortBy: true,
        width: 550,
    },
];

export default columns;
