import './StartPage.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Link, Navigate } from 'react-router-dom';
import SupportIcon from '../../widgets/SupportIcon/SupportIcon';
import TWO from '../../assets/screenTwo.png'
import THREE from '../../assets/screenThree.png'
import useUser from '../../hooks/useUser';
function StartPage() {
    const { loggedOut } = useUser();
    if (!loggedOut) return <Navigate to="/" />;
    return (
        <div className="startPage">
            <Logo className="logo" />
            <div className="title">
                <h1 className="title-mainText">Личный кабинет Alterprice</h1>
                <h4 className="title-subText">
                    Площадка, позволяющая интернет-магазинам размещать товарные
                    предложения, тем самым увеличить целевую аудиторию сайта
                </h4>
            </div>
            <div className="buttons">
                <Link to={'/sign-in'} className="signInBtn">
                    Войти
                </Link>
                <Link to={'/sign-up'} className="signUpBtn">
                    Зарегистрироваться
                </Link>
            </div>
            <SupportIcon />
            <div className='screen-one' >
                <img src={THREE} alt='screen' />
            </div>
            <div className='screen-two' >
                <img src={TWO} alt='screen' />
            </div>
        </div>
    );
}
export default StartPage;
