import './TariffCard.scss';
import { ReactComponent as Check } from '../../assets/chek.svg';

function TariffCard({ title, content, varinat }) {
    return (
        <div className={`tariff-card ${varinat}`}>
            <div className="card-title">
                <h4 className={`card-title_main ${varinat}`}>{title.h1}</h4>
                <span className="card-title_sub">{title.h4}</span>
                <span className="card-price">
                    {title.price}
                    <span>/месяц</span>
                </span>
            </div>
            <div className="line" />
            <h4 className="list-title">Что входит в план:</h4>
            <ul>
                {content.map((el, i) => (
                    <li className="list-item" key={i}>
                        <Check />
                        {el}
                    </li>
                ))}
            </ul>
            <button type="button" className={`btn ${varinat}`}>
                Выбрать
            </button>
        </div>
    );
}

export default TariffCard;
