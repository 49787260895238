import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import StartPage from './modules/StartPage/StartPage';
import SignIn from './modules/SignIn/SignIn';
import SignUp from './modules/SignUp/SignUp';
import MainPage from './modules/MainPage/MainPage';
import PriceList from './modules/PriceList/PriceList';
import Support from './modules/Support/Support';
import Layout from './widgets/Layouts/Layout';
import TariffPlan from './widgets/TariffPlan/TariffPlan';
import BetManage from './modules/BetManage/BetManage';
import Balance from './modules/Balance/Balance';
import PasswordReset from './modules/PasswordReset/PasswordReset';
import PasswordRecovery from './modules/PasswordRecovery/PasswordRecovery';
import Analytics from './modules/Analytics/Analytics';
import Shop from './modules/Shop/Shop';
import AdminLayout from './modules/AdminPanel/AdminLayout/AdminLayout';
import AdminSignIn from './modules/AdminPanel/AdminSignIn/AdminSignIn';
import Clients from './modules/AdminPanel/Clients/Clients';
// import Сlassification from './modules/AdminPanel/Сlassification/Сlassification';
import Staff from './modules/AdminPanel/Staff/Staff';
import ClientsActions from './modules/AdminPanel/ClientsActions/ClientsActions';
import Logs from './modules/AdminPanel/Logs/Logs';
import ClickLog from './modules/AdminPanel/ClickLog/ClickLog';
import AddShop from './modules/AddShop/AddShop';
// import AddPriceList from './modules/PriceList/AddPriceList';
import ConformationEmail from './modules/SignUp/ConformationEmail';
import RedirectLk from './modules/AdminPanel/RedirectLk/RedirectLk';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/price" element={<PriceList />} />
                    <Route path="/add-shop" element={<AddShop />} />
                    {/* <Route path="/price/add" element={<AddPriceList />} /> */}
                    <Route path="/support" element={<Support />} />
                    <Route path="/bet" element={<BetManage />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/balance" element={<Balance />} />
                    <Route path="/analytics" element={<Analytics />} />
                </Route>

                <Route path="/admin" element={<AdminLayout />}>
                    <Route
                        path="/admin"
                        element={<Navigate to={'/admin/clients'} />}
                    />
                    <Route path="/admin/clients" element={<Clients />} />
                    <Route path="/admin/staff" element={<Staff />} />
                    <Route path="/admin/logs" element={<Logs />} />
                    <Route path="/admin/clicks" element={<ClickLog />} />
                    <Route path="/admin/lk" element={<RedirectLk />} />
                    {/* <Routepath="/admin/classification" element={<Сlassification />} /> */}
                    <Route
                        path="/admin/clients-actions"
                        element={<ClientsActions />}
                    />
                </Route>
                <Route path="/admin/sign-in" element={<AdminSignIn />} />

                <Route path="/plan" element={<TariffPlan />} />
                <Route path="/start" element={<StartPage />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="confirm-email" element={<ConformationEmail />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="password-reset" element={<PasswordReset />} />
                <Route
                    path="password/reset/confirm"
                    element={<PasswordRecovery />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
