//import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from '../../../UI-kit/Select/Select';
import { Box, Modal, Typography } from '@mui/material';
import TextField from '../../../UI-kit/TextField/TextField';
import Option from '../../../UI-kit/Select/Option';
import { API_HOST } from '../../../config';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FORMIK_INITIAL_VALUES = {
    sum: '',
    type: '',
};

// const CreateSegmentSchema = Yup.object().shape({
//     sum: Yup.string().matches(/^\d+$/).required(),
//     type: Yup.string().required(),
// });
function numberWithSpaces(number) {
    if (number < 10000) return number;
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function DepositModal({
    modal,
    handleCloseModal,
    confirm,
    setConfirm,
    client,
    reValidate
}) {
    const [error, setError] = useState('');
    const formik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
       // validationSchema: CreateSegmentSchema,
        onSubmit: async (values) => {
            if(!values.type){
                setError('Выберите Пополнение/Списание')
                formik.setSubmitting(false)
            }
            if(!values.sum){
                setError('Введите сумму Пополнение/Списание')
                formik.setSubmitting(false)
            }
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('admin_token')
            );
            if (values.type === 'Пополнение') {
                const res = await fetch(
                    `${API_HOST}/api/v1/manager-panel/customer/balance`,
                    {
                        method: 'PUT',
                        headers: myHeaders,
                        body: JSON.stringify({
                            customer_id: client.customer_id,
                            amount: Number(values.sum),
                        }),
                    }
                );
                if(res.ok){
                    reValidate()
                    alert("Таблица обновится через несколько секунд.")
                }
            } else {
                const score = Number(values.sum);
                await fetch(
                    `${API_HOST}/api/v1/manager-panel/customer/balance`,
                    {
                        method: 'PUT',
                        headers: myHeaders,
                        body: JSON.stringify({
                            customer_id: client.customer_id,
                            amount: -score,
                        }),
                    }
                );
            }
            handleCloseModal();
        },
    });
    return (
        <Modal
            open={modal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
            {error && <h4 className='error-text'>{error}</h4>}
            <form onSubmit={formik.handleSubmit}>
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Изменить баланс клиента
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            color: '#42454C',
                            opacity: '70%',
                        }}
                    >
                        Клиент: {client.customer_email}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            color: '#42454C',
                            opacity: '70%',
                        }}
                    >
                        Текущий баланс: {numberWithSpaces(client.balance)}
                    </Typography>
                    <Select
                        placeholder="Пополнение/Списание"
                        onChange={formik.setFieldValue}
                        name={'type'}
                    >
                        <Option value={'Пополнение'}>Пополнение</Option>
                        <Option value={'Списание'}>Списание</Option>
                    </Select>
                    <TextField
                        name="sum"
                        type='number'
                        onChange={formik.handleChange}
                        placeholder="Введите сумму пополнения/списания"
                    />
                    {!confirm && (
                        <div
                            onClick={() => setConfirm(true)}
                            className="editButton"
                        >
                            <button type="submit">Изменить</button>
                        </div>
                    )}
                    {confirm && (
                        <div className="buttonGroup">
                            <div
                                className="resetBtn"
                                onClick={() => {
                                    handleCloseModal();
                                    formik.setValues(FORMIK_INITIAL_VALUES);
                                }}
                            >
                                <span>Отменить</span>
                            </div>
                            <div className="confirmBtn">
                                <button type="submit">Подтвердить</button>
                            </div>
                        </div>
                    )}
                </Box>
            </form>
            </>
        </Modal>
    );
}

export default DepositModal;
