import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Modal, Typography } from '@mui/material';
import TextField from '../../../UI-kit/TextField/TextField';
import { API_HOST } from '../../../config';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FORMIK_INITIAL_VALUES = {
    password: '',
};

const CreateSegmentSchema = Yup.object().shape({
    password: Yup.string()
        .required('Поле Пароль не может быть пустым')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*[\d\W]).{8,}$/,
            'Пароль должен содержать не менее 8 символов, латинские буквы и хотя бы одну цифру'
        ),
});

function PasswordModal({
    modal,
    handleCloseModal,
    confirm,
    setConfirm,
    client,
}) {
    const formik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
        validationSchema: CreateSegmentSchema,
        onSubmit: async (values) => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('admin_token')
            );
            await fetch(`${API_HOST}/api/v1/manager-panel/customer/password`, {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    customer_id: client.customer_id,
                    new_password: values.password,
                }),
            });
            handleCloseModal();
            formik.setValues(FORMIK_INITIAL_VALUES);
        },
    });
    return (
        <Modal
            open={modal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Изменить пароль клиента
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            color: '#42454C',
                            opacity: '70%',
                        }}
                    >
                        Клиент: {client.customer_email}
                    </Typography>
                    <TextField
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.password}
                        touched={formik.touched.password}
                        placeholder="Введите новый пароль"
                    />
                    {!confirm && (
                        <div
                            onClick={() => setConfirm(true)}
                            className="editButton"
                        >
                            <button type="submit">Изменить</button>
                        </div>
                    )}
                    {confirm && (
                        <div className="buttonGroup">
                            <div
                                className="resetBtn"
                                onClick={() => {
                                    handleCloseModal();
                                    formik.setValues(FORMIK_INITIAL_VALUES);
                                }}
                            >
                                <span>Отменить</span>
                            </div>
                            <div className="confirmBtn">
                                <button type="submit">Подтвердить</button>
                            </div>
                        </div>
                    )}
                </Box>
            </form>
        </Modal>
    );
}

export default PasswordModal;
