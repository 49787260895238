/* eslint-disable no-fallthrough */
import TextField from '../../../UI-kit/TextField/TextField';
import AdminTable from '../AdminTable/AdminTable';
import './Clients.scss';
import { useEffect, useState } from 'react';
import StaffModal from './StaffModal';
// import { filterData, filterType } from '../filters';
import useClients from '../../../hooks/useClients';
import useManagers from '../../../hooks/useManagers';
import DepositModal from './DepositModal';
import PasswordModal from './PasswordModal';
import { API_HOST } from '../../../config';
import { CircularProgress } from '@mui/material';

const columns = [
    {
        Header: 'Почта',
        accessor: 'email',
        maxWidth: 400,
        minWidth: 140,
        width: 250,
        disableSortBy: true,
    },
    {
        Header: 'Пароль',
        accessor: 'password',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
        disableSortBy: true,
    },
    {
        Header: 'ФИО',
        accessor: 'name',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Телефон',
        accessor: 'phone',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Баланс',
        accessor: 'balance',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Компания',
        accessor: 'shop_name',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Сайт',
        accessor: 'shop_site',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Менеджер',
        accessor: 'manager',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'ЛК',
        accessor: 'lk',
        maxWidth: 400,
        minWidth: 80,
        width: 150,
        disableSortBy: true,
    },
];

// const filterResult = (filters, arr) => {
//     const result = Object.entries(filters).reduce(
//         (acc, [key, value]) => {
//             switch (key) {
//                 case 'search':
//                     if (value === '') return acc;
//                     return filterData(value, ['shop_site', 'email', 'phone', 'name'], acc);
//                 case 'type':
//                     if (value === '') return acc;
//                     return filterType(value, acc);
//                 default:
//                     return acc;
//             }
//         },
//         [...(arr || [])]
//     );
//     return result;
// };

function Clients() {
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState('');
    const { clients, totalAmount, isLoading, mutate } = useClients(currentPage, 10, query);
    const [modal, setModal] = useState(false);
    const [balanceModal, setBalanceModal] = useState(false);
    const [confirmBalance, setConfirmBalance] = useState(false);
    const [password, setPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const { managers, isManagerLoading } = useManagers();
    const [confirm, setConfirm] = useState(false);
    const [current, setCurrent] = useState();
    // const [filter, setFilter] = useState({
    //     email: '',
    //     type: '',
    //     name: '',
    //     shop_site: '',
    //     phone: ''
    // });

    useEffect(() => {
        const tableUpdate = setInterval(() => {
            mutate();
        }, 300000)

        return () => clearInterval(tableUpdate);
    }, [mutate, clients])

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            mutate();
        }
    }

    const handleOpenBalance = (el) => {
        setBalanceModal(true);
        setCurrent(el);
    };
    const handleCloseBalance = () => {
        setBalanceModal(false);
        setConfirm(false);
    };

    const handleOpenManager = (el) => {
        setModal(true);
        setCurrent(el);
    };
    const handleCloseModal = () => {
        setModal(false);
        setConfirm(false);
    };
    const handleOpenPassword = (el) => {
        setPassword(true);
        setCurrent(el);
    };
    const handleClosePassword = () => {
        setPassword(false);
        setConfirmPassword(false);
    };

    // const handleSelect = (name, option) => {
    //     setFilter((prev) => ({ ...prev, type: option }));
    // };

    const getUserToken = async (userid) => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('admin_token')
        );
        const response = await fetch(
            `${API_HOST}/api/v1/auth/get-access-token/${userid}`,
            {
                method: 'GET',
                headers: myHeaders,
            }
        );
        if (!response.ok) { 
            return alert("Невозможно перейти, произошла ошибка.")
        }
        const data = await response.json();
        return data?.access_token
    } 

    const redirectToClient = async (userid) => {
        const access_token = await getUserToken(userid);
        if (access_token) {
            localStorage.setItem('user_token', access_token);
            window.open('/', '_blank');
        }
    };

    function getTableData(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr?.map((el) => {
            return {
                name: `${el.first_name} ${el.second_name}`,
                balance: (
                    <div className="action">
                        <span>{el.balance}</span>
                        <button onClick={() => handleOpenBalance(el)}>
                            Изменить
                        </button>
                    </div>
                ),
                phone: el.phone,
                clik: el.clik,
                manager: (
                    <div className="edit">
                        <span>
                            {
                                managers?.find(
                                    (manager) => manager.id === el.manager_id
                                )?.first_name
                            }{' '}
                            {
                                managers?.find(
                                    (manager) => manager.id === el.manager_id
                                )?.second_name
                            }
                        </span>
                        <svg
                            onClick={() => {
                                handleOpenManager(el);
                            }}
                            className="edit"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5 18.4999H9.24C9.37161 18.5007 9.50207 18.4755 9.62391 18.4257C9.74574 18.3759 9.85656 18.3026 9.95 18.2099L16.87 11.2799L19.71 8.49994C19.8037 8.40698 19.8781 8.29637 19.9289 8.17452C19.9797 8.05266 20.0058 7.92195 20.0058 7.78994C20.0058 7.65793 19.9797 7.52722 19.9289 7.40536C19.8781 7.2835 19.8037 7.1729 19.71 7.07994L15.47 2.78994C15.377 2.69621 15.2664 2.62182 15.1446 2.57105C15.0227 2.52028 14.892 2.49414 14.76 2.49414C14.628 2.49414 14.4973 2.52028 14.3754 2.57105C14.2536 2.62182 14.143 2.69621 14.05 2.78994L11.23 5.61994L4.29 12.5499C4.19732 12.6434 4.12399 12.7542 4.07423 12.876C4.02446 12.9979 3.99924 13.1283 4 13.2599V17.4999C4 17.7652 4.10536 18.0195 4.29289 18.207C4.48043 18.3946 4.73478 18.4999 5 18.4999ZM14.76 4.90994L17.59 7.73994L16.17 9.15994L13.34 6.32994L14.76 4.90994ZM6 13.6699L11.93 7.73994L14.76 10.5699L8.83 16.4999H6V13.6699ZM21 20.4999H3C2.73478 20.4999 2.48043 20.6053 2.29289 20.7928C2.10536 20.9804 2 21.2347 2 21.4999C2 21.7652 2.10536 22.0195 2.29289 22.207C2.48043 22.3946 2.73478 22.4999 3 22.4999H21C21.2652 22.4999 21.5196 22.3946 21.7071 22.207C21.8946 22.0195 22 21.7652 22 21.4999C22 21.2347 21.8946 20.9804 21.7071 20.7928C21.5196 20.6053 21.2652 20.4999 21 20.4999Z"
                                fill="#DCDCDC"
                            />
                        </svg>
                    </div>
                ),
                email: `${el.email}`,
                password: (
                    <div className="action">
                        <span>{el.password}</span>
                        <button onClick={() => handleOpenPassword(el)}>
                            Изменить
                        </button>
                    </div>
                ),
                shop_name: el.shop_name,
                shop_site: el.shop_site,
                lk: (
                    <button
                        style={{padding: "8px 25px", background: "#8adb4a", borderRadius: "3px", color: "white", border: "none", cursor: "pointer"}}
                        onClick={() => redirectToClient(el.id)}
                    >
                        Перейти
                    </button>
                ),
            };
        });
    }

    const totalPages = Math.ceil(totalAmount / 10)

    return (
        <div className="clients">
            <div className="filters">
                <TextField
                    name="name"
                    onChange={(e) => {
                        setQuery(e.target.value)
                        setCurrentPage(1)
                    }}
                    placeholder={'Поиск'}
                />
                {/* <Select
                    placeholder="Выберите тип клиента"
                    //onChange={formik.setFieldValue}
                    name={'type'}
                    onChange={handleSelect}
                >
                    <Option value="">Все</Option>
                    <Option value="have">Есть менеджер</Option>
                    <Option value="false">Нет менеджера</Option>
                </Select> */}
            </div>
            
            {
                isLoading ? (
                    <CircularProgress style={{ marginTop: "40px" }} />
                ) : (
                    <>
                        {
                            totalPages === 0 ? (
                                <span>Не найдено</span>
                            ) : (
                                <>
                                    <AdminTable
                                        columns={columns}
                                        data={getTableData(
                                            !isLoading ? clients : []
                                        )}
                                    />
                                
                                    <div className="pagination" style={{ paddingBottom: "20px" }}>
                                        <button 
                                            onClick={() => handlePageChange(currentPage - 1)} 
                                            disabled={currentPage === 1}
                                        >
                                            Назад
                                        </button>
                                        <span>{currentPage} из {totalPages}</span>
                                        <button 
                                            onClick={() => handlePageChange(currentPage + 1)} 
                                            disabled={currentPage === totalPages}
                                        >
                                            Далее
                                        </button>
                                    </div>
                                    <DepositModal
                                        modal={balanceModal}
                                        handleCloseModal={handleCloseBalance}
                                        confirm={confirmBalance}
                                        setConfirm={setConfirmBalance}
                                        client={current}
                                        refresh={mutate}
                                    />
                                    <PasswordModal
                                        modal={password}
                                        handleCloseModal={handleClosePassword}
                                        confirm={confirmPassword}
                                        setConfirm={setConfirmPassword}
                                        client={current}
                                        refresh={mutate}
                                    />
                                    <StaffModal
                                        modal={modal}
                                        handleCloseModal={handleCloseModal}
                                        confirm={confirm}
                                        setConfirm={setConfirm}
                                        client={current}
                                        refresh={mutate}
                                        arr={!isManagerLoading ? managers : ['']}
                                    />
                                </>
                            )
                        } 
                    </>
                )
            }


        </div>
    );
}

export default Clients;
