const columns = [
    {
        Header: 'Название категории',
        accessor: 'title',
        sortType: 'string',
        width: 400,
    },
    {
        Header: 'Ставка по категории, ₽',
        accessor: 'count',
        sortType: 'number',
        width: 100,
    },
    {
        Header: '',
        accessor: 'button',
        disableSortBy: true,
        width: 550,
    },
];

export default columns;
