import Modal from '@mui/material/Modal';
import './LogOut.scss';

function Logout({ open, handleClose }) {
    const handleLogOut = () => {
        localStorage.removeItem('user_token');
        window.location.reload();
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="logOutModal">
                <h1 className="modal-title">Вы точно хотите выйти?</h1>
                <div className="btn-group">
                    <button className="logOut-btn" onClick={handleLogOut}>
                        Выйти
                    </button>
                    <button className="close-btn" onClick={handleClose}>
                        Остаться
                    </button>
                </div>
            </div>
        </Modal>
    );
}
export default Logout;
