import useSWR from 'swr';
import { API_HOST } from '../config';
import adminFetcher from './adminFetcher'

export default function useLogs() {
    const { data, error, mutate } = useSWR(
        `${API_HOST}/api/v1/manager-panel/logs`,adminFetcher
    );
    const isLoading = !error && !data;
    return {
        logs: data,
        isLoading,
        mutate,
    };
}
