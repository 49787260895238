import { useEffect, useState } from 'react';
import './Logs.scss';
import AdminTable from '../AdminTable/AdminTable';
import { useFormik } from 'formik';
import DateRangePicker from '../../../widgets/DateRangePicker/DateRangePicker';
import EventsModal from './EventsModal';
import { API_HOST } from '../../../config';
import useLogs from '../../../hooks/useLogs';
import moment from 'moment';
import 'moment/locale/ru'
import { CircularProgress } from '@mui/material';

const columnsA = [
    {
        Header: 'Дата',
        accessor: 'date',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
    },
    {
        Header: 'Ивент',
        accessor: 'event',
        maxWidth: 800,
        minWidth: 80,
        width: 500,
        disableSortBy: true,
    },
    {
        Header: 'Действие',
        accessor: 'btn',
        maxWidth: 400,
        minWidth: 80,
        width: 150,
        disableSortBy: true,
    },
];

const columnsB = [
    {
        Header: 'Дата',
        accessor: 'date',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
    },
    {
        Header: 'Ивент',
        accessor: 'event',
        maxWidth: 800,
        minWidth: 80,
        width: 500,
        disableSortBy: true,
    },
];

export const filterDate = (date_end, date_start, arr) => {
    const tempDate = {
        date_end: new Date(date_end),
        date_start: new Date(date_start),
    };
    tempDate.date_end.setHours(23);
    tempDate.date_end.setMinutes(59);
    const result = arr.filter((user) => {
        if (!date_start && !date_end) return true;

        const date = new Date(user?.date);
        const isInside =
            tempDate.date_start <= date && tempDate.date_end >= date;
        if (isInside) return true;

        return false;
    });
    return result;
};

const init = {
    user: '',
    arr: [],
    date_end: '',
    date_start: '',
};
function Logs() {
    moment.locale('ru')
    const [page, setPage] = useState(1);
    const { logs, isLoading, mutate } = useLogs();
    const [modal, setModal] = useState(false);
    function getTableDataA(arr) {
        return arr?.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);

            return dateB - dateA;
        }).map((el) => {
            return {
                date: moment(el.date).format('DD.MM.YYYY HH:mm'),
                event: el.description,
                btn: (
                    <button
                        onClick={async () => {
                            const myHeaders = new Headers();
                            myHeaders.append(
                                'Content-Type',
                                'application/json'
                            );
                            myHeaders.append(
                                'Authorization',
                                'Bearer ' + localStorage.getItem('admin_token')
                            );
                            const res = await fetch(
                                `${API_HOST}/api/v1/manager-panel/logs/${el.id}`,
                                {
                                    method: 'put',
                                    headers: myHeaders,
                                    body: JSON.stringify({
                                        is_handled: true,
                                    }),
                                }
                            );
                            if (res.ok) {
                                mutate();
                            }
                        }}
                        type="button"
                        className="btn"
                    >
                        Принять
                    </button>
                ),
            };
        });
    }

    function getTableDataB(arr) {
        return arr
            ?.sort((a, b) => {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);

                return dateB - dateA;
            })
            .map((el) => {
                return {
                    date: moment(el.date).format('DD.MM.YYYY HH:mm'),
                    event: el.description,
                };
            });
    }

    const formik = useFormik({
        initialValues: init,
    });

    const handleDateChange = (value) => {
        formik.setFieldValue('date_end', value[1] ?? '');
        formik.setFieldValue('date_start', value[0] ?? '');
    };

    useEffect(() => {
        // const id = setInterval(() => {
        //     mutate();
        // }, 10 * 1000);
        // return () => clearInterval(id);
    }, []);

    if (isLoading) return <CircularProgress />;

    return (
        <div className="logs">
            <div className="toggle">
                <div
                    id="1"
                    onClick={() => {
                        setPage(1);
                    }}
                    className={page === 1 ? 'filter active' : 'filter'}
                >
                    Не принятые
                </div>
                <div
                    id="2"
                    onClick={() => {
                        setPage(2);
                    }}
                    className={page === 2 ? 'filter active' : 'filter'}
                >
                    Принятые
                </div>
            </div>
            <div className="filters">
                <DateRangePicker change={(value) => handleDateChange(value)} />
                <button className="reset" onClick={() => setModal(true)}>
                    Ивенты
                </button>
            </div>
            {page === 1 && (
                <div className="clients">
                    <AdminTable
                        data={
                            !isLoading
                                ? getTableDataA(
                                      filterDate(
                                          formik.values.date_end,
                                          formik.values.date_start,
                                          logs.filter((el) => !el.is_handled)
                                      )
                                  )
                                : []
                        }
                        columns={columnsA}
                    />
                </div>
            )}
            {page === 2 && (
                <div className="managers">
                    <AdminTable
                        data={
                            !isLoading
                                ? getTableDataB(
                                      filterDate(
                                          formik.values.date_end,
                                          formik.values.date_start,
                                          logs.filter((el) => el.is_handled)
                                      )
                                  )
                                : []
                        }
                        columns={columnsB}
                    />
                </div>
            )}
            <EventsModal modal={modal} setModal={setModal} />
        </div>
    );
}

export default Logs;
