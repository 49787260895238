import { Checkbox, FormControlLabel, Modal } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

function EventsModal({ modal, setModal }) {
    const [save, setSave] = useState(
        localStorage.getItem('filters') ? true : false
    );
    const formik = useFormik({
        initialValues: localStorage.getItem('filters')
            ? JSON.parse(localStorage.getItem('filters'))
            : {
                  registr: false,
                  shop: false,
                  price: false,
              },
    });
    useEffect(() => {
        if (!localStorage.getItem('filters')) {
            formik.setValues({
                registr: false,
                shop: false,
                price: false,
            });
        }
    }, [save]);
    return (
        <Modal
            open={modal}
            onClose={() => {
                setModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="events">
                <div className="items">
                    <FormControlLabel
                        control={<Checkbox checked={formik.values.registr} />}
                        onChange={(e) =>
                            formik.setFieldValue('registr', e.target.checked)
                        }
                        label="регистрация клиента"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={formik.values.shop} />}
                        onChange={(e) =>
                            formik.setFieldValue('shop', e.target.checked)
                        }
                        label="создание магазина"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={formik.values.price} />}
                        onChange={(e) =>
                            formik.setFieldValue('price', e.target.checked)
                        }
                        label="проверка прайс-листа
                    (ошибка и загружен)"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="выставление счета"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="оплата (зкваринг)"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="включение магазина"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="выключение магазина"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="изменение ставки по
                    магаазину (общая) и по категории"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="включение дополнительного
                    функционала"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="изменение гео магазина"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="низкий баланс
                    (менее 500р)"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="зачисление денег менеджером"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="откат
                    денег менеджером"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="откат кликов менеджером"
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="добавление кликов менеджером"
                    />
                </div>
                {!save ? (
                    <button
                        className="save"
                        onClick={() => {
                            setSave(true);
                            localStorage.setItem(
                                'filters',
                                JSON.stringify(formik.values)
                            );
                        }}
                    >
                        Сохранить
                    </button>
                ) : (
                    <button
                        className="reset"
                        onClick={() => {
                            setSave(false);
                            localStorage.removeItem('filters');
                        }}
                    >
                        Сбросить
                    </button>
                )}
            </div>
        </Modal>
    );
}

export default EventsModal;
