import useSWRImmutable from 'swr/immutable';
import { API_HOST } from '../config';
import adminFetcher from './adminFetcher';

export default function usePriceLists(page = 1, limit = 20, query) {
    const skip = (page - 1) * limit;
    const { data, error, mutate } = useSWRImmutable(
        `${API_HOST}/api/v1/manager-panel/shop/price-lists?skip=${skip}&limit=${limit}${query ? `&query=${query}` : ''}`,
        adminFetcher
    );

    const isLoading = !error && !data;

    return {
        priceLists: data?.data,
        totalAmount: data?.amount,
        isLoading,
        mutate,
    };
}
