const fetcher = async (url) => {
    const myHeaders = new Headers();
    myHeaders.append(
        'Authorization',
        'Bearer ' + localStorage.getItem('user_token')
    );
    const res = await fetch(`${url}`, {
        method: 'get',
        headers: myHeaders,
    });
    if (res.status === 401) {
        throw new Error('A 401 error occurred while fetching the data.');
    }
    return res.json();
};

export default fetcher;
