import useSWR from 'swr';
import { API_HOST } from '../config';

export default function usePriceList() {
    const { data, error, mutate } = useSWR(
        `${API_HOST}/api/v1/customer-panel/shop/price-lists`
    );
    const isLoadingPrice = !error && !data;
    return {
        price_list: data,
        isLoadingPrice,
        mutate,
    };
}
