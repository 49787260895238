import { useFormik } from 'formik';
import ControlledTreeView from './ControlledTreeView';
import { ReactComponent as Delete } from '../../assets/close.svg';
import './Shop.scss';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import {
    CircularProgress,
    FormControlLabel,
    Switch,
    styled,
} from '@mui/material';
import { ReactComponent as Mult } from '../../assets/multy.svg';
import { ReactComponent as Modal } from '../../assets/modalSuport.svg';
import tree from './test';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { API_HOST } from '../../config';
import usePriceList from '../../hooks/usePriceList';

const FORMIK_INITIAL_VALUES = {
    regions: [],
};

const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

function Shop() {
    const [modal, setModal] = useState(false);
    const [upd, setUpd] = useState(false);
    const [geo, setGeo] = useState();
    const [codeHandled, setCodeHandled] = useState(false);
    // const [YandexToken, setYandexToken] = useState(false)
    const navigate = useNavigate();
    const { user, isLoading } = useUser();
    const { price_list, isLoadingPrice } = usePriceList();


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code && !codeHandled) {
          console.log(code)
        //   getYandexToken(code);
          const newUrl = window.location.href.split('?')[0];
          window.history.replaceState({}, document.title, newUrl);
          setCodeHandled(true);
        }

        const setSettings = async () => {
            const myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('user_token')
            );
            const res = await fetch(
                `${API_HOST}/api/v1/customer-panel/shop/price-lists/${price_list[0].id}/settings`,
                {
                    method: 'get',
                    headers: myHeaders,
                }
            );
            if (res.ok) {
                const data = await res.json();
                console.log(data);
                settingsFormik.setValues({
                    retarget: data.find((el) => el.name === 'retarget')
                        ?.is_active,
                    my_target: data.find((el) => el.name === 'vk')?.is_active,
                    interests: data.find((el) => el.name === 'interests')
                        ?.is_active,
                    partner: data.find((el) => el.name === 'partner')
                        ?.is_active,
                    day_limit: data.find((el) => el.name === 'day_limit')
                        ?.is_active,
                });
            }
            fetch(
                `${API_HOST}/api/v1/customer-panel/shop/price-lists/${price_list[0].id}/cities`,
                {
                    method: 'get',
                    headers: myHeaders,
                }
            )
                .then((res) => res.json())
                .then((data) => setGeo(data));
        };
        if (price_list?.length) {
            setSettings();
        }
    }, [isLoadingPrice, upd]);

    // useEffect(() => {
    //   const myHeaders = new Headers();
    //   myHeaders.append('Content-Type', 'application/json');
    //   myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('user_token'));

    //   const fetchData = async () => {
    //     try {
    //       const res = await fetch(`${API_HOST}/api/v1/customer-panel/get_yandex_token`, {
    //         method: 'get',
    //         headers: myHeaders,
    //       });
    //       if (res.ok) {
    //         const data = await res.json();
    //         setYandexToken(!data.has_token == "");
    //       } else {
    //         const errorData = await res.json();
    //         console.error('not ', errorData);
    //       }
    //     } catch (error) {
    //       console.error('err ', error.message);
    //     }
    //   };

    //   fetchData();
    // }, []);

    const formik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
        //validationSchema: CreateSegmentSchema,
    });
    const settingsFormik = useFormik({
        initialValues: {
            retarget: false,
            interests: false,
            my_target: false,
            partner: false,
            day_limit: false,
            limit_value: '',
        },
        validationSchema: Yup.object().shape({
            limit_value: Yup.number().min(500, 'Не менее 500 руб.'),
        }),
        onSubmit: async () => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('user_token')
            );
            const reg = [...geo, ...formik.values.regions];
            const reg_set = new Set(reg);
            await fetch(
                `${API_HOST}/api/v1/customer-panel/shop/price-lists/${price_list[0].id}/cities`,
                {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify(Array.from(reg_set)),
                }
            );
            setUpd((prev) => !prev);
        },
    });

    if (isLoading) return <CircularProgress />;





    // const getYandexToken = async (code) => {
    //   try {
    //     const myHeaders = new Headers();
    //     myHeaders.append('Content-Type', 'application/json')
    //     myHeaders.append(
    //       'Authorization',
    //       'Bearer ' + localStorage.getItem('user_token')
    //     );
    //     const res = await fetch(`${API_HOST}/api/v1/customer-panel/set_yandex_token`, {
    //       method: 'post',
    //       headers: myHeaders,
    //       body: JSON.stringify({
    //         code: code
    //       }),
    //     })
    //     if (res.ok) {
    //       const data = await res.json();
    //       console.log('successful ', data)
    //       alert(data.detail)
    //       setYandexToken(false)
    //     } else {
    //       const errorData = await res.json();
    //       console.error('not ', errorData)
    //     }
    //   } catch (error) {
    //     console.error('err ', error.message)
    //   }
    // }

    // const getYandexMetricaCode = () => {
    //   try {
    //     const clientID = 'bc01584e955f4946942b76ae2ad20fda';
    //     const redirectURI = 'https://alterprice.ru/shop';
    //     console.log()
    //     const authURL = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}`;
    //     window.open(authURL, '_blank');
    //   } catch (error) {
    //     console.error('err ', error.message);
    //   }
    // };


    return (
        <div className="shop">
            {!user?.shop ? (
                <div className="add-shop">
                    <div className="title">
                        <h1 className="title-text_main">
                            Похоже у Вас нет магазина
                        </h1>
                        <h4 className="title-text_sub">
                            Чтобы изменять настройки необходимо создать
                            магазин
                        </h4>
                    </div>
                    <button
                        className="linkBtn"
                        type="button"
                        onClick={() => navigate('/add-shop')}
                    >
                        Создать магазин
                    </button>
                </div>
            ) : (
                <>
                    <form
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                        onSubmit={settingsFormik.handleSubmit}
                    >
                        <div className="shop-settings">
                            {/* <div className="profile"> */}
                                {/* <div className="filters">
                                <div
                                    // onClick={() => {
                                    //     document
                                    //         .querySelector('.filters')
                                    //         .querySelector('.active')
                                    //         .classList.remove('active');
                                    //     document
                                    //         .getElementById('1')
                                    //         .classList.add('active');
                                    //     settingsFormik.setValues({
                                    //         retarget: price_list[0],
                                    //         interests: price_list[0],
                                    //         my_target: price_list[0],
                                    //         partner: price_list[0],
                                    //         day_limit: price_list[0],
                                    //         limit_value: price_list[0],
                                    //     });
                                    // }}
                                    id="1"
                                    className="filter active"
                                >
                                    Прайс 1
                                </div>
                                <div
                                    // onClick={() => {
                                    //     document
                                    //         .querySelector('.filters')
                                    //         .querySelector('.active')
                                    //         .classList.remove('active');
                                    //     document
                                    //         .getElementById('2')
                                    //         .classList.add('active');
                                    //     settingsFormik.setValues({
                                    //         retarget: price_list[0],
                                    //         interests: price_list[0],
                                    //         my_target: price_list[0],
                                    //         partner: price_list[0],
                                    //         day_limit: price_list[0],
                                    //         limit_value: price_list[0],
                                    //     });
                                    // }}
                                    id="2"
                                    className="filter"
                                >
                                    Прайс 2
                                </div>
                                <div
                                    // onClick={() => {
                                    //     document
                                    //     .querySelector('.filters')
                                    //     .querySelector('.active')
                                    //     .classList.remove('active');
                                    // document
                                    //     .getElementById('3')
                                    //     .classList.add('active');
                                    //     settingsFormik.setValues({
                                    //         retarget: price_list[0],
                                    //         interests: price_list[0],
                                    //         my_target: price_list[0],
                                    //         partner: price_list[0],
                                    //         day_limit: price_list[0],
                                    //         limit_value: price_list[0],
                                    //     });
                                    // }}
                                    id="3"
                                    className="filter"
                                >
                                    Прайс 3
                                </div>
                            </div> */}
                                {/* <button
                                    disabled={YandexToken}
                                    className="linkBtn"
                                    style={{
                                      padding: "10px 15px",
                                      borderRadius: "5px",
                                      background: YandexToken ? "#8adb4a" : "#676767",
                                      border: "none",
                                      color: "#fff",
                                      cursor: "pointer"
                                    }}
                                    type="button"
                                    onClick={getYandexMetricaCode}
                                    >
                                    {YandexToken ? "Подключено" : "Подключить Яндекс метрику"}
                                </button> */}
                                {/* <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={false}
                                            // checked={
                                            //     settingsFormik.values.day_limit
                                            // }
                                            // onChange={(e) =>
                                            //     settingsFormik.setFieldValue(
                                            //         'day_limit',
                                            //         e.target.checked
                                            //     )
                                            // }
                                        />
                                    }
                                    label="Дневное ограничение"
                                />
                                <TextField
                                    placeholder={'Введите ограничение трат'}
                                    name={'limit_value'}
                                    type="number"
                                    onBlur={settingsFormik.handleBlur}
                                    touched={settingsFormik.touched.limit_value}
                                    errors={settingsFormik.errors.limit_value}
                                    onChange={settingsFormik.handleChange}
                                    disabled={true}
                                /> */}
                            {/* </div> */}
                            <div className="settings">
                                <div className="settings-title">
                                    <h4 className="title-text">Настройки</h4>
                                    <svg
                                        onClick={() =>
                                            setModal((prev) => !prev)
                                        }
                                        className="title-icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                    </svg>
                                    {modal && <Modal className="modal" />}
                                </div>
                                <div className="switch-wrapper">
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={true}
                                                // checked={
                                                //     settingsFormik.values
                                                //         .retarget
                                                // }
                                                // onChange={(e) =>
                                                //     settingsFormik.setFieldValue(
                                                //         'retarget',
                                                //         e.target.checked
                                                //     )
                                                // }
                                            />
                                        }
                                        label="Ретаргетинг"
                                    />
                                    <div className="dop">
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch
                                                    sx={{ m: 1 }}
                                                    checked={true}
                                                    // checked={
                                                    //     settingsFormik.values
                                                    //         .interests
                                                    // }
                                                    // onChange={(e) =>
                                                    //     settingsFormik.setFieldValue(
                                                    //         'interests',
                                                    //         e.target.checked
                                                    //     )
                                                    // }
                                                />
                                            }
                                            label="Показы по интересам"
                                        />
                                        <Mult
                                            className={
                                                settingsFormik.values
                                                    .interests && 'act'
                                            }
                                        />
                                    </div>
                                    <div className="dop">
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch
                                                    sx={{ m: 1 }}
                                                    checked={false}
                                                    // checked={
                                                    //     settingsFormik.values
                                                    //         .my_target
                                                    // }
                                                    // onChange={(e) =>
                                                    //     settingsFormik.setFieldValue(
                                                    //         'my_target',
                                                    //         e.target.checked
                                                    //     )
                                                    // }
                                                />
                                            }
                                            label="VKреклама"
                                        />
                                        <Mult
                                            className={
                                                settingsFormik.values
                                                    .my_target && 'act'
                                            }
                                        />
                                    </div>
                                    <div className="dop">
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch
                                                    sx={{ m: 1 }}
                                                    checked={false}
                                                    // checked={
                                                    //     settingsFormik.values
                                                    //         .partner
                                                    // }
                                                    // onChange={(e) =>
                                                    //     settingsFormik.setFieldValue(
                                                    //         'partner',
                                                    //         e.target.checked
                                                    //     )
                                                    // }
                                                />
                                            }
                                            label="Партнерская сеть (18+)"
                                        />
                                        <Mult
                                            className={
                                                settingsFormik.values.partner &&
                                                'act'
                                            }
                                        />
                                    </div>
                                    <p>Настройки фиксированы, скоро появится возможность их редактирования</p>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="geo">
                                <div className="list-wrapper">
                                    <div className="list">
                                        {geo?.map((el, i) => (
                                            <span style={{width: "180px", padding: "6px 35px 6px 10px", borderRadius: "5px", background: "#8adb4a", color: "#ffffff", fontSize: "15px", fontWeight: "600"}} className="geo-item" key={i}>
                                                {el}{' '}
                                                <Delete
                                                    className="delete"
                                                    onClick={() => {
                                                        setGeo((prev) =>
                                                            prev.filter(
                                                                (geo) =>
                                                                    geo !== el
                                                            )
                                                        );
                                                    }}
                                                />{' '}
                                            </span>
                                        ))}
                                    </div>
                                    <div style={{ width: '30%' }} />
                                </div>
                                <h5 className="form-title">География</h5>
                                <div className="check-tree">
                                    <ControlledTreeView
                                        value={formik.values.regions}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'regions',
                                                value
                                            )
                                        }
                                        Regions={[
                                            {
                                                id: 0,
                                                label: 'Вся Россия',
                                                value: 'Вся Россия',
                                                children: tree,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="btn-wrapper">
                            <button type="submit" className="save-btn">
                                Сохранить изменения
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}

export default Shop;
