import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormControl, Modal, Typography } from '@mui/material';
import TextField from '../../../UI-kit/TextField/TextField';
import { API_HOST } from '../../../config';

const FORMIK_INITIAL_VALUES = {
    first_name: '',
    surname: '',
    email: '',
    password: '',
    password2: '',
    position: 'аккаунт менеджер',
};

const CreateSegmentSchema = Yup.object().shape({
    password: Yup.string().required('Это обязательное поле'),
    position: Yup.string().required('Это обязательное поле'),
    password2: Yup.string()
        .required('Это обязательное поле')
        .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
    first_name: Yup.string().required('Это обязательное поле'),
    surname: Yup.string().required('Это обязательное поле'),
    email: Yup.string()
        .trim()
        .required('Это обязательное поле')
        .matches(
            /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i,
            'Введен некорректный Email'
        ),
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function AddStaffModal({
    addUserModal,
    handleCloseUser,
    confirmUser,
    setConfirmUser,
    refresh,
}) {
    const formik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
        validationSchema: CreateSegmentSchema,
        onSubmit: async (values) => {
            const myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('admin_token')
            );
            myHeaders.append('Content-Type', 'application/json');
            await fetch(
                `${API_HOST}/api/v1/manager-panel/manager/registration`,
                {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify({
                        email: values.email,
                        password: values.password,
                        first_name: values.first_name,
                        second_name: values.surname,
                        position: values.position,
                    }),
                }
            );
            refresh();
            handleCloseUser();
            formik.setValues(FORMIK_INITIAL_VALUES);
        },
    });
    return (
        <Modal
            open={addUserModal}
            onClose={() => {
                handleCloseUser();
                formik.setValues(FORMIK_INITIAL_VALUES);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Добавить сотрудника
                    </Typography>
                    <FormControl
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                            marginTop: 20,
                        }}
                    >
                        <TextField
                            errors={formik.errors.surname}
                            touched={formik.touched.surname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="surname"
                            value={formik.values.surname}
                            placeholder="Фамилия"
                        />
                        <TextField
                            errors={formik.errors.first_name}
                            touched={formik.touched.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="first_name"
                            value={formik.values.first_name}
                            placeholder="Имя"
                        />
                        <TextField
                            errors={formik.errors.email}
                            touched={formik.touched.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="email"
                            value={formik.values.email}
                            placeholder="Email"
                        />
                        <TextField
                            errors={formik.errors.position}
                            touched={formik.touched.position}
                            // onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="position"
                            value={formik.values.position}
                            placeholder="Должность"
                        />
                        <TextField
                            errors={formik.errors.password}
                            touched={formik.touched.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="password"
                            value={formik.values.password}
                            placeholder="Пароль"
                        />
                        <TextField
                            errors={formik.errors.password2}
                            touched={formik.touched.password2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="password2"
                            value={formik.values.password2}
                            placeholder="Повторите пароль"
                        />
                    </FormControl>
                    {!confirmUser && (
                        <div
                            onClick={() => setConfirmUser(true)}
                            className="editButton"
                        >
                            <span>Добавить</span>
                        </div>
                    )}
                    {confirmUser && (
                        <div className="buttonGroup">
                            <div
                                className="resetButton"
                                onClick={() => {
                                    handleCloseUser();
                                    formik.setValues(FORMIK_INITIAL_VALUES);
                                }}
                            >
                                <span>Отменить</span>
                            </div>
                            <div className="confirmButton">
                                <button type="submit">Подтвердить</button>
                            </div>
                        </div>
                    )}
                </Box>
            </form>
        </Modal>
    );
}

export default AddStaffModal;
