import { useEffect, useState } from 'react';
import './DateRangePicker.scss';
import deLocale from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DateRangePicker({ change }) {
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    useEffect(() => {
        change(dateRange);
    }, [dateRange]);

    return (
        <div className="range_picker">
            <DatePicker
                placeholderText="Выберите период"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                locale={deLocale}
                isClearable
                dateFormat="dd/MM/yyyy"
                onChange={(update) => {
                    setDateRange(update);
                }}
            />
        </div>
    );
}

export default DateRangePicker;
