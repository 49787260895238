/* eslint-disable no-useless-escape */
import './AddShop.scss';
import TextField from '../../UI-kit/TextField/TextField';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from '../../UI-kit/Select/Select';
import Option from '../../UI-kit/Select/Option';
import ControlledTreeView from '../Shop/ControlledTreeView';
import { FormControlLabel, Switch, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import tree from '../Shop/test';
import { API_HOST } from '../../config';
import useUser from '../../hooks/useUser';

const FORMIK_INIT_VALUES = {
    name: '',
    phone: '',
    ownership_type: '',
    site: '',
    city: '',
    orgn: '',
    // retarget: false,
    // interests: false,
    // my_target: false,
    // partner: false,
    price: '',
    bet: '',
    regions: [],
};

const VALIDATION_SHEMA = Yup.object().shape({
    name: Yup.string().required('Это обязательное поле').matches(
        /^[а-яА-ЯёЁa-zA-Z0-9]+$/,
        'Только буквы и цифры'
    ),
    phone: Yup.string()
        .required('Это обязательное поле')
        .length(18, 'введите корректный номер'),
    ownership_type: Yup.string().required('Это обязательное поле'),
    site: Yup.string()
        .trim()
        .required('Это обязательное поле')
        .matches(
            /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-]+\.)+([a-zA-Z0-9-]+)(\/[^\s]*)?$/,
            'Введите корректный сайт'
        ),
    city: Yup.string().trim().required('Это обязательное поле').matches(
        /^[а-яА-ЯёЁ-]+$/,
        'Значение должно быть городом'
    ),
    ogrn: Yup.string()
        .required('Это обязательное поле')
        .length(13, 'Значение должно быть ровно 13 символов'),
    price: Yup.string()
        .trim()
        .required('Это обязательное поле')
        .matches(
            /^(http:\/\/|https:\/\/)/,
            'Значение должно быть ссылкой на прайс-лист'
        )
        .matches(
            /^(http:\/\/|https:\/\/)?([a-zA-Z0-9-]+\.)+([a-zA-Z0-9-]+)(\/[^\s]*)?$/,
            'Значение должно быть ссылкой на прайс-лист'
        ),
    bet: Yup.number()
        .required('Это обязательное поле')
        .min(7, 'Не менее 7 рублей'),
    regions: Yup.array().min(1, 'Выберите хотя бы один регион'),
});

const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

function AddShop() {
    const [step, setStep] = useState(1);
    const [errorText, setErrorText] = useState('');
    const navigate = useNavigate();
    const { mutate, user } = useUser();
     if(user.shop) navigate('/')

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        // eslint-disable-next-line no-unused-vars
        onSubmit: async (values) => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('user_token')
            );
            const res = await fetch(`${API_HOST}/api/v1/customer-panel/shop`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    name: values.name,
                    phone: values.phone,
                    ownership_type: values.ownership_type,
                    site: values.site,
                    city: values.city,
                    ogrn: Number(values.ogrn),
                    price_list: values.price,
                    transition_cost: Number(values.bet),
                    target_regions: values.regions,
                }),
            });
            if (res.ok) {
                // const response = await fetch(`${API_HOST}/api/v1/customer-panel/shop/price-lists`,{
                //     method:'get',
                //     headers: myHeaders,
                // })
                //const d = await response.json();
                // await fetch(
                //     `${API_HOST}/api/v1/customer-panel/shop/price-lists/${d[0].id}/settings`,
                //     {
                //         method: 'put',
                //         headers: myHeaders,
                //         body: JSON.stringify({
                //             name: 'retarget',
                //             is_active: values.retarget,
                //         }),
                //     }
                // );
                // await fetch(
                //     `${API_HOST}/api/v1/customer-panel/shop/price-lists/${d[0].id}/settings`,
                //     {
                //         method: 'put',
                //         headers: myHeaders,
                //         body: JSON.stringify({
                //             name: 'vk',
                //             is_active: values.my_target,
                //         }),
                //     }
                // );
                // await fetch(
                //     `${API_HOST}/api/v1/customer-panel/shop/price-lists/${d[0].id}/settings`,
                //     {
                //         method: 'put',
                //         headers: myHeaders,
                //         body: JSON.stringify({
                //             name: 'partner',
                //             is_active: values.partner,
                //         }),
                //     }
                // );
                // await fetch(
                //     `${API_HOST}/api/v1/customer-panel/shop/price-lists/${d[0].id}/settings`,
                //     {
                //         method: 'put',
                //         headers: myHeaders,
                //         body: JSON.stringify({
                //             name: 'interests',
                //             is_active: values.interests,
                //         }),
                //     }
                // );
                mutate();
                navigate('/');
                formik.setValues(FORMIK_INIT_VALUES);
            } else {
                const data = await res.json();
                setErrorText(data.detail);
                console.log(data);
                let msg = '';
                await data.detail.forEach((el) => {
                    msg += el.loc[1] + ' ' + el.msg + ' ';
                });
                alert(msg);
            }
        },
    });

    useEffect(() => {
        formik.validateForm();
    }, []);

    return (
        <div className="add-shop">
            <div className="form-wrapper">
                <div className="title">
                    <h1 className="title-mainText">Создание магазина</h1>
                    <h4 className="title-subText">Шаг: {step} из 4</h4>
                </div>
                <form className="form" onSubmit={formik.handleSubmit}>
                    {errorText && <h4 className="error-title">{errorText}</h4>}
                    {step === 1 && (
                        <>
                            <TextField
                                label={'Название магазина'}
                                placeholder={'Введите название магазина'}
                                name={'name'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.name}
                                touched={formik.touched.name}
                                value={formik.values.name}
                            />
                            <TextField
                                label={'Контактный телефон магазина'}
                                placeholder={'+7 (___) ___ __ __'}
                                name={'phone'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.phone}
                                touched={formik.touched.phone}
                                variant="phone"
                                value={formik.values.phone}
                            />
                            <Select
                                placeholder="Выберите тип организации"
                                onChange={formik.setFieldValue}
                                name={'ownership_type'}
                                error={formik.errors.ownership_type}
                                defaultValue={formik.values.ownership_type}
                            >
                                <Option value="ENTITY">Юр.лицо</Option>
                                <Option value="INDIVIDUAL">Физ.лицо</Option>
                            </Select>
                            <button
                                type="button"
                                className={
                                    !formik.errors.name &&
                                    !formik.errors.ownership_type &&
                                    !formik.errors.phone
                                        ? 'linkBtn active'
                                        : 'linkBtn'
                                }
                                onClick={async () => {
                                    const err = await formik.validateForm();
                                    if (
                                        !err.name &&
                                        !err.ownership_type &&
                                        !err.phone
                                    ) {
                                        setStep(2);
                                        setErrorText('');
                                    } else {
                                        setErrorText(
                                            'Корректно заполните все поля'
                                        );
                                    }
                                }}
                            >
                                Продолжить
                            </button>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <TextField
                                label={'Сайт магазина'}
                                placeholder={'Введите сайт магазина'}
                                name={'site'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.site}
                                touched={formik.touched.site}
                                value={formik.values.site}
                            />
                            <TextField
                                label={'Город магазина'}
                                placeholder={'Введите город магазина'}
                                name={'city'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.city}
                                touched={formik.touched.city}
                                value={formik.values.city}
                            />
                            <TextField
                                label={'ОГРН'}
                                placeholder={'------------'}
                                name={'ogrn'}
                                type={'number'}
                                maxlength={13}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (value.length > 13)
                                        value = value.slice(0, 13);
                                    formik.setFieldValue('ogrn', value);
                                }}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.ogrn}
                                touched={formik.touched.ogrn}
                                value={formik.values.ogrn}
                            />
                            <button
                                type="button"
                                className={
                                    !formik.errors.site &&
                                    !formik.errors.city &&
                                    !formik.errors.ogrn
                                        ? 'linkBtn active'
                                        : 'linkBtn'
                                }
                                onClick={async () => {
                                    const err = await formik.validateForm();
                                    if (!err.site && !err.city && !err.ogrn) {
                                        setStep(3);
                                        setErrorText('');
                                    } else {
                                        setErrorText(
                                            'Корректно заполните все поля'
                                        );
                                    }
                                }}
                            >
                                Продолжить
                            </button>
                            <button
                                onClick={() => setStep(1)}
                                className="linkBtn back"
                            >
                                Назад
                            </button>
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <TextField
                                label={'Ссылка на прайс лист'}
                                placeholder={'https://'}
                                name={'price'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.price}
                                touched={formik.touched.price}
                                value={formik.values.price}
                            />
                            <TextField
                                label={'Стоимость перехода, ₽'}
                                placeholder={'Стоимость одного перехода'}
                                name={'bet'}
                                type="number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.bet}
                                touched={formik.touched.bet}
                                value={formik.values.bet}
                            />
                            <div className="switch-wrapper">
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={formik.values.retarget}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'retarget',
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Ретаргетинг"
                                />
                                <div>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={
                                                    formik.values.interests
                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'interests',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Показы по интересам"
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={
                                                    formik.values.my_target
                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'my_target',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="VKреклама"
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={formik.values.partner}
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'partner',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Партнерская сеть (18+)"
                                    />
                                </div>
                            </div>
                            <button
                                type="button"
                                className={
                                    !formik.errors.price && !formik.errors.bet
                                        ? 'linkBtn active'
                                        : 'linkBtn'
                                }
                                onClick={async () => {
                                    const err = await formik.validateForm();
                                    if (!err.price && !err.bet) {
                                        setStep(4);
                                        setErrorText('');
                                    } else {
                                        setErrorText(
                                            'Корректно заполните все поля'
                                        );
                                    }
                                }}
                            >
                                Продолжить
                            </button>
                            <button
                                onClick={() => setStep(2)}
                                className="linkBtn back"
                            >
                                Назад
                            </button>
                        </>
                    )}
                    {step === 4 && (
                        <>
                            <div className="check-tree">
                                <h4 className="error-title">
                                    {formik.errors.regions}
                                </h4>
                                <ControlledTreeView
                                    value={formik.values.regions}
                                    onChange={(value) =>
                                        formik.setFieldValue('regions', value)
                                    }
                                    Regions={[
                                        {
                                            id: 0,
                                            label: 'Вся Россия',
                                            value: 'Вся Россия',
                                            children: tree,
                                        },
                                    ]}
                                />
                            </div>
                            <button
                                type="submit"
                                className={
                                    !formik.errors.regions
                                        ? 'linkBtn active'
                                        : 'linkBtn'
                                }
                            >
                                Сохранить
                            </button>
                            <button
                                onClick={() => setStep(3)}
                                className="linkBtn back"
                            >
                                Назад
                            </button>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
}

export default AddShop;
