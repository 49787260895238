/* eslint-disable react/display-name */
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import './MainPage.scss';
import {
    Cell,
    BarChart,
    CartesianGrid,
    YAxis,
    Bar,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import { memo, useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import { CircularProgress } from '@mui/material';
import BalanceModal from '../Balance/BalanceModal';
import ChoiceModal from '../Balance/ChoiceModal';
import { API_HOST } from '../../config';
import usePriceList from '../../hooks/usePriceList';
import moment from 'moment';
import {
    BetChartData,
    clickChartData,
    ctrChartData,
} from '../Analytics/Analytics';

const COLORS_BAR = ['#8ADB4A', '#D5E95F', '#5FE996'];

const style = {
    backgroundColor: '#4a4a4a',
    color: '#fefefe',
    padding: '2px 20px',
    borderRadius: 5,
    fontSize: '14px',
};

const setTooltipType = (type) => {
    switch (type) {
        case 'click': {
            return memo((props) => {
                if (props.payload?.length > 0) {
                    const data = props.payload[0];
                    return (
                        <div style={style}>
                            <p>Дата: {data.payload.date}</p>
                            <p>Клики: {data.value}</p>
                        </div>
                    );
                }
                return null;
            });
        }
        case 'ctr': {
            return memo((props) => {
                if (props.payload?.length > 0) {
                    const data = props.payload[0];
                    return (
                        <div style={style}>
                            <p>Дата: {data.payload.date}</p>
                            <p>
                                {data.value !== 'NaN'
                                    ? `CTR: ${data.value}`
                                    : `CTR: 0`}
                            </p>
                        </div>
                    );
                }
                return null;
            });
        }
        case 'show': {
            return memo((props) => {
                if (props.payload?.length > 0) {
                    const data = props.payload[0];
                    return (
                        <div style={style}>
                            <p>Дата: {data.payload.date}</p>
                            <p>
                                {data.value !== 'NaN'
                                    ? `Показы: ${data.value}`
                                    : `Показы: 0`}
                            </p>
                        </div>
                    );
                }
                return null;
            });
        }
        case 'bet': {
            return memo((props) => {
                if (props.payload?.length > 0) {
                    const data = props.payload[0];
                    return (
                        <div style={style}>
                            <p>Дата: {data.payload.date}</p>
                            <p>
                                {data.value !== 'NaN'
                                    ? `Стоимость: ${data.value} ₽`
                                    : `Стоимость: 0 ₽`}
                            </p>
                        </div>
                    );
                }
                return null;
            });
        }
    }
};

const chartValue = (arr, type) => {
    const transitions_count = arr.reduce((acc, el) => {
        return acc + el.transitions_count;
    }, 0);
    const spend = arr.reduce((acc, el) => {
        return acc + el.spend;
    }, 0);
    switch (type) {
        case 'click':
            return arr.reduce((acc, el) => {
                return acc + el.transitions_count;
            }, 0);
        case 'ctr':
            return ((spend / transitions_count).toFixed(0) / 25).toFixed(2);
        case 'bet':
            return (spend / transitions_count).toFixed(0);
    }
};

function MainPage() {
    const { user, isLoading } = useUser();
    const navigate = useNavigate();
    const [chart, setChart] = useState([]);
    const [pay, setPay] = useState(false);
    const { price_list } = usePriceList();
    const [type, setType] = useState('click');
    const [func, setFunc] = useState({
        click: (e) => clickChartData(e),
    });

    useEffect(() => {
        if (price_list?.length) {
            const fetchData = async () => {
                const myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json');
                myHeaders.append(
                    'Authorization',
                    'Bearer ' + localStorage.getItem('user_token')
                );
                fetch(
                    `${API_HOST}/api/v1/manager-panel/transitions/${
                        price_list[0].id
                    }/get_by_period?start=${moment()
                        .startOf('day')
                        .utc(true)
                        .unix()}&end=${moment().endOf('day').utc(true).unix()}`,
                    {
                        method: 'get',
                        headers: myHeaders,
                    }
                )
                    .then((res) => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            return [];
                        }
                    })
                    .then((data) => {
                        setChart(data);
                    });
            }
            fetchData();
        }
    }, [price_list]);

    if (isLoading) return <CircularProgress />;

    return (
        <div className="MainPage">
            {!user?.shop ? (
                <div className="first-view">
                    <div className="title">
                        <h1 className="title-text_main">
                            Кажется тут ничего нет
                        </h1>
                        <h4 className="title-text_sub">
                            Чтобы начать работать с сервисом необходимо внести
                            информацию о магазине
                        </h4>
                    </div>
                    <button
                        className="linkBtn"
                        type="button"
                        onClick={() => navigate('/add-shop')}
                    >
                        Создать магазин
                    </button>
                </div>
            ) : (
                <div className="main-content">
                    <div className="paymentInfo">
                        <div className="paymentInfo-item">
                            <h6 className="itemText_main">Клики</h6>
                            <h2 className="itemText_sub">{`${user?.charges}`}</h2>
                        </div>
                        <div className="paymentInfo-item pay">
                            <div>
                                <h6 className="itemText_main">Ваш баланс</h6>
                                <h2 className="itemText_sub">{`${user?.balance} ₽`}</h2>
                            </div>
                            <Plus
                                onClick={() => setPay(true)}
                                className="svg"
                            />
                        </div>
                    </div>
                    {user.replenish_type ? (
                        <BalanceModal
                            open={pay}
                            handleClose={() => setPay(false)}
                        />
                    ) : (
                        <ChoiceModal
                            open={pay}
                            handleClose={() => setPay(false)}
                        />
                    )}
                    <div className="charts">
                        <div className="filters">
                            <div
                                id="1"
                                onClick={() => {
                                    document
                                        .querySelector('.filters')
                                        .querySelector('.active')
                                        .classList.remove('active');
                                    document
                                        .getElementById('1')
                                        .classList.add('active');
                                    setFunc({
                                        click: (e) => clickChartData(e),
                                    });
                                    setType('click');
                                }}
                                className="filter active"
                            >
                                Клики
                            </div>
                            <div
                                id="2"
                                onClick={() => {
                                    document
                                        .querySelector('.filters')
                                        .querySelector('.active')
                                        .classList.remove('active');
                                    document
                                        .getElementById('2')
                                        .classList.add('active');
                                    setFunc({
                                        click: (e) => ctrChartData(e),
                                    });
                                    setType('ctr');
                                }}
                                className="filter"
                            >
                                CTR
                            </div>
                            <div
                                id="3"
                                onClick={() => {
                                    document
                                        .querySelector('.filters')
                                        .querySelector('.active')
                                        .classList.remove('active');
                                    document
                                        .getElementById('3')
                                        .classList.add('active');
                                    setFunc({
                                        click: (e) => BetChartData(e),
                                    });
                                    setType('bet');
                                }}
                                className="filter"
                            >
                                Стоимость за клик
                            </div>
                        </div>
                        <div className="cliks">{chartValue(chart,type)}</div>
                        <ResponsiveContainer width="95%" height="80%">
                            <BarChart
                                width={1700}
                                height={450}
                                data={func.click(chart)}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <Tooltip
                                    content={(props) => {
                                        const Tooltip = setTooltipType(type);
                                        return <Tooltip {...props} />;
                                    }}
                                />
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis />
                                <Bar
                                    dataKey="count"
                                    label={'rkbrb'}
                                    fill="#82ca9d"
                                >
                                    {chart.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                COLORS_BAR[
                                                    index % COLORS_BAR.length
                                                ]
                                            }
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MainPage;
