import { useState } from 'react';
import './TextField.scss';

const addMissingCharacters = (value, mask) => {
    if (value.length >= mask.length) return value.slice(0, mask.length);
    const start =value.match(/^\+\d* /i) ? value.match(/^\+\d* /i)[0] : '';

    let remainingValue = [
        ...value.slice(start.length).replace(/\D/gi, ''),
    ].reverse();
    const remainingMask = mask.slice(start.length);

    let result = '';
    let currentMaskIndex = 0;
    while (remainingValue.length > 0) {
        const currentMaskSymbol = remainingMask[currentMaskIndex];
        if (currentMaskSymbol !== '_') {
            result += currentMaskSymbol;
        } else {
            result += remainingValue.pop();
        }
        currentMaskIndex++;
    }
    return start + result;
};
const initialMask = '+7 (___) ___-__-__';

function TextField({
    label,
    name,
    placeholder,
    onChange,
    onBlur,
    errors,
    touched,
    value,
    type,
    variant,
    maxlength,
    mask = initialMask,
}) {
    const [input, setInput] = useState(value ? value : () => {
        const startMask = mask && mask.match(/^\+\d* /i)[0];
        return startMask ?? "+7 ";
      });
    const handleInput = (e) => {
        e.target.value = addMissingCharacters(e.target.value, mask);
        setInput(e.target.value);
        onChange(e);
    };

    return (
        <div className="TextField">
            <label htmlFor={name}>{label}</label>
            {variant === 'phone' ? (
                <input
                    onChange={handleInput}
                    name={name}
                    id={name}
                    onBlur={onBlur}
                    className={errors && touched ? 'input error' : 'input'}
                    value={input}
                />
            ) : (
                <input
                    className={errors && touched ? 'input error' : 'input'}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    type={type}
                    maxLength={maxlength}
                />
            )}
            {errors && touched && <span className="errorText">{errors}</span>}
        </div>
    );
}

export default TextField;
