import { useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { SelectContext } from './SelectContext';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import './Select.scss';

const type_customer = {
    ENTITY: 'Юр.лицо',
    INDIVIDUAL: 'Физ.лицо'
}

const Select = ({
    children,
    defaultValue,
    placeholder,
    onChange,
    name,
    error,
}) => {
    const [selectedOption, setSelectedOption] = useState(type_customer[defaultValue] || '');
    const [showDropdown, setShowDropdown] = useState(false);
    const [touched, setTouched] = useState(false);
    const showDropdownHandler = () => {
        if(showDropdown) setTouched(() => true);
        setShowDropdown(() => !showDropdown);
    };
    const selectPlaceholder = placeholder || 'Choose an option';
    const selectContainerRef = useRef(null);

    const clickOutsideHandler = () => setShowDropdown(false);

    useOnClickOutside(selectContainerRef, clickOutsideHandler);

    const updateSelectedOption = (option, children) => {
        onChange(name, option);
        setSelectedOption(children);
        setShowDropdown(false);
    };

    return (
        <SelectContext.Provider
            value={{
                selectedOption,
                changeSelectedOption: updateSelectedOption,
            }}
        >
            <div
                className={
                    touched && error
                        ? 'select-container error'
                        : 'select-container'
                }
                ref={selectContainerRef}
                name={name}
            >
                <div
                    className={
                        showDropdown ? 'selected-text active' : 'selected-text'
                    }
                    onClick={showDropdownHandler}
                >
                    {selectedOption.length > 0 ? (
                        selectedOption
                    ) : (
                        <span className="placeholder">{selectPlaceholder}</span>
                    )}
                    <Arrow
                        className={showDropdown ? 'arrow active' : 'arrow'}
                    />
                </div>
                <ul
                    className={
                        showDropdown
                            ? 'select-options show-dropdown-options'
                            : 'select-options hide-dropdown-options'
                    }
                >
                    {children}
                </ul>
            </div>
        </SelectContext.Provider>
    );
};

export default Select;
