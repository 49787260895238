import { Modal } from '@mui/material';
import { ReactComponent as Close } from '../../assets/close.svg';
import TextField from '../../UI-kit/TextField/TextField';
import './BetModal.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { API_HOST } from '../../config';

const FORMIK_INIT_VALUES = {
    bet: '',
};

const VALIDATION_SHEMA = Yup.object().shape({
    bet: Yup.number()
        .required('Это обязательное поле')
        .typeError('Только цифры')
        .min(7, 'менее 7 рублей'),
});

function EditOneBet({ open, handleClose, current,refresh }) {
    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        onSubmit: async (values) => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('user_token')
            );
            const res = await fetch(
                `${API_HOST}/api/v1/customer-panel/shop/price-lists/categories/${current.id}`,
                {
                    method: 'put',
                    headers: myHeaders,
                    body: JSON.stringify(values.bet),
                }
            );
            refresh()
            handleClose();
            if (res.ok) {
                res.json()
            } else {
                const data = await res.json();
                console.log(data);
                let msg = '';
                await data.detail.forEach((el) => {
                    msg += el.loc[1] + ' ' + el.msg + ' ';
                });
                alert(msg);
            }
        },
    });
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="bet-modal">
                    <h2 className="title edit">
                        Изменить ставку в категории {current?.category_name}
                    </h2>
                    <h5 className="sub-title">
                        Обратите внимание что ставка не может быть менее 7
                        рублей
                    </h5>
                    <TextField
                        name="bet"
                        type="number"
                        errors={formik.errors.bet}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        touched={formik.touched}
                        placeholder={'Введите ставку'}
                    />
                    <div className="btn-wrapper edit">
                        <button type="submit" className="accept">
                            Применить
                        </button>
                        <button onClick={handleClose} className="cancel">
                            Отмена
                        </button>
                    </div>
                    <Close onClick={handleClose} className="close" />
                </div>
            </form>
        </Modal>
    );
}

export default EditOneBet;
