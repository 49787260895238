//import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from '../../../UI-kit/Select/Select';
import { Box, Modal, Typography } from '@mui/material';
import TextField from '../../../UI-kit/TextField/TextField';
import Option from '../../../UI-kit/Select/Option';
import { API_HOST } from '../../../config';
import { useState } from 'react';

import DateRangePicker from '../../../widgets/DateRangePicker/DateRangePicker';
import moment from 'moment';
import 'moment/locale/ru';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FORMIK_INITIAL_VALUES = {
    clik: '',
    type: '',
    bet: '',
};

// const CreateSegmentSchema = Yup.object().shape({
//     sum: Yup.string().required(),
//     type: Yup.string().required(),
// });
function numberWithSpaces(number) {
    if (number < 10000) return number;
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function ClickModal({ modal, handleCloseModal, confirm, setConfirm, client, reValidate }) {
    const [error, setError] = useState('');
    const [filter, setFilter] = useState({
        date_start: '',
        date_end: '',
    });
    const handleDateChange = (value) => {
        setFilter({
            date_start: value[0] ?? '',
            date_end: value[1] ?? '',
        });
    };
    const dates = {
        from_: moment(filter.date_start).utc(true).startOf('day'),
        to: moment(filter.date_end ? filter.date_end : moment(filter.date_start).add(1, 'd')).utc(true).endOf('day'),
    };
    const formik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
        //validationSchema: CreateSegmentSchema,
        onSubmit: async (values) => {
            if(!values.type){
                setError('Выберите списать/добавить')
                formik.setSubmitting(false)
            }
            if(!values.сlik){
                setError('Введите кол-во кликов')
                formik.setSubmitting(false)
            }
            handleCloseModal();
            console.log(dates)
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append(
                'Authorization',
                'Bearer ' + localStorage.getItem('admin_token')
            );
            if (values.type === 'Добавить') {
                if(!values.bet){
                    setError('Введите ставку')
                    formik.setSubmitting(false)
                }
                await fetch(
                    `${API_HOST}/api/v1/manager-panel/shop/price-lists/${client.price_list_id}`,
                    {
                        method: 'PUT',
                        headers: myHeaders,
                        body: JSON.stringify({
                            date: {...dates},
                            cost: +values.bet,
                            count: values.clik,
                        }),
                    }
                );
                reValidate()
                alert("Таблица обновится через несколько секунд.")
            } else {
                const score = Number(values.clik);
                await fetch(
                    `${API_HOST}/api/v1/manager-panel/shop/price-lists/${client.price_list_id}`,
                    {
                        method: 'PUT',
                        headers: myHeaders,
                        body: JSON.stringify({
                            date:{...dates},
                            count: score,
                        }),
                    }
                );
                reValidate()
                alert("Таблица обновится через несколько секунд.")
            }
        },
    });
    return (
        <Modal
            open={modal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
            {error && <h4 className='error-text'>{error}</h4>}
            <form onSubmit={formik.handleSubmit}>
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Изменить кол-во кликов клиента
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            color: '#42454C',
                            opacity: '70%',
                        }}
                    >
                        Клиент: {client.customer_email}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            color: '#42454C',
                            opacity: '70%',
                        }}
                    >
                        Текущие клики: {numberWithSpaces(client.transitions_count)}
                    </Typography>
                    <DateRangePicker change={(value) => handleDateChange(value)} />
                    <Select
                        placeholder="Добавить/Списать"
                        onChange={formik.setFieldValue}
                        name={'type'}
                    >
                        <Option value={'Добавить'}>Добавить</Option>
                        <Option value={'Списать'}>Списать</Option>
                    </Select>
                    <TextField
                        name="clik"
                        type='number'
                        onChange={formik.handleChange}
                        placeholder="Введите кол-во кликов"
                    />
                    {formik.values.type === 'Добавить' && (
                        <div>
                            <TextField
                                name="bet"
                                type='number'
                                onChange={formik.handleChange}
                                placeholder="Введите стоимоть клика"
                            />
                        </div>
                    )}
                    {!confirm && (
                        <div
                            onClick={() => setConfirm(true)}
                            className="editButton"
                        >
                            <button type="button">Изменить</button>
                        </div>
                    )}
                    {confirm && (
                        <div className="buttonGroup">
                            <div
                                className="resetBtn"
                                onClick={() => {
                                    handleCloseModal();
                                    formik.setValues(FORMIK_INITIAL_VALUES);
                                }}
                            >
                                <span>Отменить</span>
                            </div>
                            <div className="confirmBtn">
                                <button type="submit">Подтвердить</button>
                            </div>
                        </div>
                    )}
                </Box>
            </form>
            </>
        </Modal>
    );
}

export default ClickModal;
