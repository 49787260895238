import './CheckBox.scss';

function CheckBox({ label }) {
    return (
        <div className='CheckBox'>
            <input
                type="checkbox"
                className="checkbox"
                id="remeber"
                name="remeber"
                //checked={true}
                disabled={true}
                defaultChecked={true}
            />
            <label htmlFor="remeber">{label}</label>
        </div>
    );
}

export default CheckBox;
