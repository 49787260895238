import './SignUp.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { Link, useNavigate } from 'react-router-dom';
import CheckBox from '../../UI-kit/CheckBox/CheckBox';
import TextField from '../../UI-kit/TextField/TextField';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from '../../UI-kit/Select/Select';
import Option from '../../UI-kit/Select/Option';
import SupportIcon from '../../widgets/SupportIcon/SupportIcon';
import IMG from '../../assets/screenOne.png';
import { API_HOST } from '../../config';

const FORMIK_INIT_VALUES = {
    name: '',
    email: '',
    phone: '',
    password_again: '',
    password: '',
    ownership_type: '',
    offero_confirmed: true,
};

const VALIDATION_SHEMA = Yup.object().shape({
    first_name: Yup.string()
        .required('Это обязательное поле')
        .matches(
            /^[a-zA-Zа-яА-Я\s-]+$/,
            'Допустимы символы русского или латинского алфaвита, пробелы и дефисы'
        ),
    second_name: Yup.string()
        .required('Это обязательное поле')
        .matches(
            /^[a-zA-Zа-яА-Я\s-]+$/,
            'Допустимы символы русского или латинского алфaвита, пробелы и дефисы'
        ),
    email: Yup.string()
        .trim()
        .required('Это обязательное поле')
        .matches(
            /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i,
            'Введен некорректный Email'
        ),
    phone: Yup.string()
        .required('Это обязательное поле')
        .length(18, 'введите корректный номер'),
    password: Yup.string()
        .required('Поле Пароль не может быть пустым')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*[\d\W]).{8,}$/,
            'Пароль должен содержать не менее 8 символов, латинские буквы и хотя бы одну цифру'
        ),
    password_again: Yup.string()
        .required('Это обязательное поле')
        .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
    ownership_type: Yup.string().required('Это обязательное поле'),
});

const timer = (setCount) => {
    const id = setInterval(() => {
        setCount((prev) => {
            if (prev === 0) {
                clearInterval(id);
            }
            return prev > 0 ? prev - 1 : 0;
        });
    }, 1000);
};

function SignUp() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [errorText, setErrorText] = useState('');
    const [count, setCount] = useState(60);

    const formik = useFormik({
        initialValues: FORMIK_INIT_VALUES,
        validationSchema: VALIDATION_SHEMA,
        // eslint-disable-next-line no-unused-vars
        onSubmit: async (values) => {
            values.email = values.email.trim();
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            const res = await fetch(
                `${API_HOST}/api/v1/customer-panel/customer/registration`,
                {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                        first_name: values.first_name,
                        second_name: values.second_name,
                        email: values.email,
                        phone: values.phone,
                        password: values.password,
                        ownership_type: values.ownership_type,
                        offero_confirmed: true,
                    }),
                }
            );
            if (res.ok) {
                setStep(3);
                //formik.setValues(FORMIK_INIT_VALUES);
            } else {
                const data = await res.json();
                if (
                    data.detail === 'Пользователь с данным email уже существует'
                ) {
                    formik.setErrors({
                        ...formik.errors,
                        email: 'Пользователь с таким email уже зарегистрирован',
                    });
                }
                let msg = '';
                await data.detail.forEach((el) => {
                    console.log(el);
                    msg += el.loc[1] + ' ' + el.msg + ' ';
                });
                alert(msg);
                setErrorText(data.detail[1].msg);
            }
        },
    });

    useEffect(() => {
        if (step === 3) {
            timer(setCount);
        }
    }, [step]);

    useEffect(() => {
        formik.validateForm();
    }, []);

    const handleClickEmailConfirm = async () => {
        if (count === 0) {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            fetch(`${API_HOST}/api/v1/auth/email/confirm/resend`, {
                method: 'post',
                headers: myHeaders,
                body: JSON.stringify(formik.values.email),
            });
            setCount(60);
            timer(setCount);
        }
    };

    return (
        <div className="signUp">
            <div className="greetings">
                <Logo className="logo" />
                <div className="title">
                    <h1 className="title-mainText">
                        Простой путь к большим продажам
                    </h1>
                    <h4 className="title-subText">
                        Аналитика, управление и настройка в одном месте
                    </h4>
                </div>
                <div className="screen">
                    <img src={IMG} alt="screen" />
                </div>
            </div>
            <div className="form-wrapper">
                <HomeIcon className="homeIcon" onClick={() => navigate('/')} />
                {step !== 3 && (
                    <div className="title">
                        <h1 className="title-mainText">Создайте аккаунт</h1>
                        <h4 className="title-subText">Шаг: {step} из 2</h4>
                    </div>
                )}
                <form className="form" onSubmit={formik.handleSubmit}>
                    {errorText && <h4 className="error-text">{errorText}</h4>}
                    {step === 1 && (
                        <>
                            <TextField
                                label={'Фамилия'}
                                placeholder={'Контактное лицо'}
                                name={'second_name'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.second_name}
                                touched={formik.touched.second_name}
                                value={formik.values.second_name}
                            />
                            <TextField
                                label={'Имя'}
                                placeholder={'Контактное лицо'}
                                name={'first_name'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.first_name}
                                touched={formik.touched.first_name}
                                value={formik.values.first_name}
                            />
                            <TextField
                                label={'Телефон'}
                                placeholder={'+7 (___) ___ __ __'}
                                name={'phone'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.phone}
                                touched={formik.touched.phone}
                                variant={'phone'}
                                value={formik.values.phone}
                            />
                            <Select
                                placeholder="Выберите тип организации"
                                onChange={formik.setFieldValue}
                                name={'ownership_type'}
                                error={formik.errors.ownership_type}
                                defaultValue={formik.values.ownership_type}
                            >
                                <Option value="ENTITY">Юр.лицо</Option>
                                <Option value="INDIVIDUAL">Физ.лицо</Option>
                            </Select>
                            <button
                                type="button"
                                className={
                                    !formik.errors.second_name &&
                                    !formik.errors.first_name &&
                                    !formik.errors.ownership_type &&
                                    !formik.errors.phone
                                        ? 'linkBtn active'
                                        : 'linkBtn'
                                }
                                onClick={async () => {
                                    const err = await formik.validateForm();
                                    if (
                                        !err.second_name &&
                                        !err.first_name &&
                                        !err.ownership_type &&
                                        !err.phone
                                    ) {
                                        setStep(2);
                                        setErrorText('');
                                    } else {
                                        setErrorText(
                                            'Правильно заполните все поля'
                                        );
                                    }
                                }}
                            >
                                Продолжить
                            </button>
                            <span className="helpText">
                                Уже есть аккаунт?
                                <Link to={'/sign-in'} className="repeat">
                                    Войдите
                                </Link>
                            </span>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <TextField
                                label={'Почта'}
                                placeholder={'Введите почту'}
                                name={'email'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.email}
                                touched={formik.touched.email}
                                value={formik.values.email}
                            />
                            <TextField
                                label={'Пароль'}
                                placeholder={'Придумайте пароль'}
                                name={'password'}
                                type={'password'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.password}
                                touched={formik.touched.password}
                            />
                            <TextField
                                label={'Повторите пароль'}
                                placeholder={'Повторите пароль'}
                                name={'password_again'}
                                type={'password'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errors={formik.errors.password_again}
                                touched={formik.touched.password_again}
                            />
                            <CheckBox
                                label={
                                    'Я ознакомился и согласен с условиями политики и оферты'
                                }
                                className="remeber"
                            />
                            <button
                                type="submit"
                                className={
                                    formik.isValid
                                        ? 'formBtn  active'
                                        : 'formBtn'
                                }
                            >
                                Зарегистрироваться
                            </button>
                            <button
                                type="submit"
                                className="linkBtn back"
                                onClick={() => setStep(1)}
                            >
                                Назад
                            </button>
                        </>
                    )}
                    {step === 3 && (
                        <div className="confirmEmail">
                            <div className="title">
                                <h1 className="title-mainText">
                                    Проверьте почту
                                </h1>
                                <h4 className="title-subText">
                                    Мы отправили дальнейшие инструкции по
                                    регистрации на почту {formik.values.email}
                                </h4>
                                <h4 className="title-subText mt">
                                    Спасибо что вы с нами!
                                </h4>
                            </div>
                            <button
                                type="button"
                                className="linkBtn"
                                onClick={() => navigate('/')}
                            >
                                Начать работу
                            </button>
                            <span className="helpText">
                                Не пришло письмо?{' '}
                                <span
                                    className={
                                        count > 0 ? 'repeat disable' : 'repeat'
                                    }
                                    onClick={() => handleClickEmailConfirm()}
                                >
                                    Отправить еще раз{' '}
                                    {count === 60
                                        ? '01:00'
                                        : count < 10
                                        ? `00:0${count}`
                                        : `00:${count}`}
                                </span>
                            </span>
                            <SupportIcon />
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default SignUp;
