import AdminTable from '../AdminTable/AdminTable';
import './ClickLog.scss';
import DateRangePicker from '../../../widgets/DateRangePicker/DateRangePicker';
import { Autocomplete, Box, Modal, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { API_HOST } from '../../../config';
import useClients from '../../../hooks/useClients';
import moment from 'moment';
import 'moment/locale/ru';

const columnsA = [
    {
        Header: 'Дата',
        accessor: 'date',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
    },
    {
        Header: 'Ставка',
        accessor: 'bet',
        maxWidth: 400,
        minWidth: 80,
        width: 150,
        disableSortBy: true,
    },
];
const columnsB = [
    {
        Header: 'Клиент',
        accessor: 'user',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
        disableSortBy: true,
    },
    {
        Header: 'кол-во кликов',
        accessor: 'count',
        maxWidth: 400,
        minWidth: 80,
        width: 200,
        disableSortBy: true,
    },
    {
        Header: 'Списания',
        accessor: 'bet',
        maxWidth: 400,
        minWidth: 80,
        width: 150,
        disableSortBy: true,
    },
    {
        Header: '',
        accessor: 'btn',
        maxWidth: 400,
        minWidth: 80,
        width: 150,
        disableSortBy: true,
    },
];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: 500,
    height: 600,
    overflowX:'hidden',
    overflowY:'auto'
};

function ClickLog() {
    moment.locale('ru');
    const [modal, setModal] = useState(false);
    const [clicks, setClicks] = useState([]);
    const [allClicks, setAllClicks] = useState({
        transitions_count: "",
        charges: ""
    });
    const { clients } = useClients(1, 6000);
    const [filter, setFilter] = useState({
        client: '',
        date_start: '',
        date_end: '',
    });

    const handleAllClicks = async () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('admin_token')
        );
        const res = await fetch(
            `${API_HOST}/api/v1/manager-panel/transitions/all`,
            {
                method: 'post',
                headers: myHeaders,
                body: JSON.stringify({
                    from_: filter.date_start ? moment(filter.date_start).utc(true).startOf('day') : null,
                    to: filter.date_end ? moment(filter.date_end).utc(true).endOf('day') : null,
                }),
            }
        );
        if (res.ok) {
            const data = await res.json();
            setAllClicks(data);
        }
    };

    useEffect(() => {
        const tableUpdate = setInterval(() => {
            handleAllClicks();
        }, 300000)

        return () => clearInterval(tableUpdate);
    }, [])



    function getTableDataA(arr) {
        return arr?.map((el) => {
            return {
                date: moment(el.date).format('lll'),
                bet: el.cost,
            };
        });
    }
    function getTableDataB(el) {
        return [
            {
                // count: el.transition_count_by_the_period,
                count: el.transitions.length,
                user: el.email,
                bet: el.charges_by_the_period,
                btn: <span onClick={() => setModal(true)}>Подробнее</span>,
            },
        ];
    }
    function getDefaultTableData(el) {
        return [
            {   
                user: "Все клиенты",
                count: el.transitions_count,
                bet: el.charges,
            },
        ];
    }
    const handleDateChange = (value) => {
        setFilter((prev) => ({
            ...prev,
            date_end: value[1] ?? '',
            date_start: value[0] ?? '',
        }));
    };

    const handleSelectClients = async () => {
        console.log(filter)
        if (filter.client === '') {
            return handleAllClicks();
        };
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('admin_token')
        );
        const res = await fetch(
            `${API_HOST}/api/v1/manager-panel/transitions`,
            {
                method: 'post',
                headers: myHeaders,
                body: JSON.stringify({
                    from_: moment(filter.date_start).utc(true).startOf('day'),
                    to: moment(filter.date_end).utc(true).endOf('day'),
                    customer_id: clients?.find(
                        (el) => el.email === filter.client
                    )?.id,
                }),
            }
        );
        if (res.ok) {
            const data = await res.json();
            setClicks(data);
        }
    };

    return (
        <div className="click_log">
            <div className="filters">
                <Autocomplete
                    className="find"
                    disablePortal
                    onChange={(event, newValue) => {
                        setFilter((prev) => {
                            if (newValue === null) return { ...prev, client: '' };
                            return { ...prev, client: newValue }
                        });
                    }}
                    value={filter.client}
                    id="combo-box-demo"
                    options={clients?.map((el) => el.email) ?? []}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Клиент" />
                    )}
                />
                <DateRangePicker change={(value) => handleDateChange(value)} />
                <button onClick={handleSelectClients} className="reset">
                    Применить
                </button>
            </div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AdminTable
                        data={getTableDataA(clicks.transitions)}
                        columns={columnsA}
                    />
                </Box>
            </Modal>
            {filter.client && clicks.email && (
                <AdminTable data={getTableDataB(clicks)} columns={columnsB} />
            )}
            {!filter.client && allClicks?.transitions_count && (
                <AdminTable data={getDefaultTableData(allClicks)} columns={columnsB.slice(0, -1)} />
            )}
        </div>
    );
}

export default ClickLog;
