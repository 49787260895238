import { Box, Modal, Typography } from '@mui/material';
import { API_HOST } from '../../../config';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function DelModal({ BanModal, handleCloseBan, user }) {
    const handleBan = () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('token')
        );
        fetch(`${API_HOST}/api/v1/admin/employee/${user.custoner_id}`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
                is_active: false,
            }),
        });
    };
    return (
        <Modal
            open={BanModal}
            onClose={handleCloseBan}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Удалить прайс-лист клиента
                </Typography>
                <Typography
                    id="modal-modal-description"
                    style={{
                        marginBottom: 20,
                        marginTop: 20,
                        color: '#42454C',
                        opacity: '70%',
                    }}
                >
                    Клиент: {user?.customer_email}
                </Typography>
                <Typography
                    id="modal-modal-description"
                    style={{
                        marginBottom: 20,
                        marginTop: 20,
                        color: '#42454C',
                        opacity: '70%',
                    }}
                >
                    прайс-лиcт:{user?.url}
                </Typography>
                <div className="buttonGroup">
                    <div
                        className="resetButton"
                        onClick={() => {
                            handleCloseBan();
                        }}
                    >
                        Отменить
                    </div>
                    <div
                        className="confirmButton"
                        onClick={() => {
                            handleBan();
                            handleCloseBan();
                        }}
                    >
                        Подтвердить
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default DelModal;
