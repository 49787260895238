import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_HOST } from '../../../config';

function RedirectLk() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const client = searchParams.get('user');
    if (client) {
        const myHeaders = new Headers();
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('admin_token')
        );
        fetch(`${API_HOST}/api/v1/auth/access-token/${client}`, {
            headers: myHeaders,
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((data) => {
                localStorage.setItem('user_token', data.access_token);
                navigate('/');
            });
    } else {
        navigate('/admin');
    }

    return <CircularProgress />;
}

export default RedirectLk;
