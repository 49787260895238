import useSWR from 'swr';
import { API_HOST } from '../config';

export default function useCategories() {
    const { data, error, mutate } = useSWR(
        `${API_HOST}/api/v1/customer-panel/shop/price-lists/categories`
    );
    const isLoading = !error && !data;
    return {
        categories: data,
        isLoading,
        mutateCategories:mutate,
    };
}
