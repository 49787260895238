import { useNavigate } from 'react-router-dom';
import './PriceList.scss';
import Table from '../../widgets/Table/Table';
import columns from './tableColumns';
import { ReactComponent as Active } from '../../assets/active.svg';
import { ReactComponent as Block } from '../../assets/blocked.svg';
import { useEffect, useState } from 'react';
import usePriceList from '../../hooks/usePriceList';
import { API_HOST } from '../../config';
import { CircularProgress, Modal } from '@mui/material';
import useUser from '../../hooks/useUser';

const STATUS_TYPE = {
    active: <Active />,
    blocked: <Block />,
};

function PriceList() {
    const navigate = useNavigate();
    const { user, isLoading } = useUser();
    const { price_list, mutate,isLoadingPrice } = usePriceList();
    const [modal, setModal] = useState(false);
    const [current, setCurrent] = useState();
    const [err, setErr] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        setList(price_list);
    }, [price_list]);

    useEffect(() => {
        const count = price_list?.map((el) => el.offers_count);
        if (count?.includes(0)) {
            setErr(true);
        }
    }, [isLoadingPrice]);

    if (isLoading) return <CircularProgress />;

    const handleEditStatus = async (price_list) => {
        const myHeaders = new Headers();
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('user_token')
        );
        const res = await fetch(
            `${API_HOST}/api/v1/customer-panel/shop/price-lists/${
                price_list.id
            }?is_active=${!price_list.is_active}`,
            {
                method: 'put',
                headers: myHeaders,
            }
        );
        mutate();
        setModal(false);
        if (res.ok) {
            setModal(false);
        } else {
            const data = await res.json();
            console.log(data);
            let msg = '';
            await data.detail.forEach((el) => {
                msg += el.loc[1] + ' ' + el.msg + ' ';
            });
            alert(msg);
        }
    };


    const handleEditUrl = async (price_list, newPriceUrl) => {
        const myHeaders = new Headers();
        myHeaders.append(
            'Authorization',
            'Bearer ' + localStorage.getItem('user_token')
        );
        const res = await fetch(
            `${API_HOST}/api/v1/customer-panel/shop/price-lists/${
                price_list.id
            }?new_url=${newPriceUrl}`,
            {
                method: 'post',
                headers: myHeaders,
            }
        );
        mutate();
        setModal(false);
        return res
    };

    const handlePriceChange = async (price_list) => {
        let answer = prompt("Введите ссылку на прайс лист")
        if (!!answer && answer !== price_list.url) {
            let res = await handleEditUrl(price_list, answer)
            if (res.ok) {
                alert("Ссылка на прайс лист изменена")
            }
        }
    }

    function getTableData(arr) {
        return arr.map((el) => {
            return {
                title:
                <>
                {el.url}
                <button style={{marginLeft: "15px"}} onClick={() => handlePriceChange(el)}>Изменить</button>
                </>,
                count: el.offers_count,
                status: STATUS_TYPE[el.is_active ? 'active' : 'blocked'],
                button: (
                    <div className="actions-btn">
                        <svg
                            className="act-btn"
                            onClick={() => {
                                setCurrent(el.count);
                                setModal(true);
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 7C12.3956 7 12.7822 6.8827 13.1111 6.66294C13.44 6.44318 13.6964 6.13082 13.8478 5.76537C13.9991 5.39992 14.0387 4.99778 13.9616 4.60982C13.8844 4.22186 13.6939 3.86549 13.4142 3.58579C13.1345 3.30608 12.7781 3.1156 12.3902 3.03843C12.0022 2.96126 11.6001 3.00087 11.2346 3.15224C10.8692 3.30362 10.5568 3.55996 10.3371 3.88886C10.1173 4.21776 10 4.60444 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM12 17C11.6044 17 11.2178 17.1173 10.8889 17.3371C10.56 17.5568 10.3036 17.8692 10.1522 18.2346C10.0009 18.6001 9.96126 19.0022 10.0384 19.3902C10.1156 19.7781 10.3061 20.1345 10.5858 20.4142C10.8655 20.6939 11.2219 20.8844 11.6098 20.9616C11.9978 21.0387 12.3999 20.9991 12.7654 20.8478C13.1308 20.6964 13.4432 20.44 13.6629 20.1111C13.8827 19.7822 14 19.3956 14 19C14 18.4696 13.7893 17.9609 13.4142 17.5858C13.0391 17.2107 12.5304 17 12 17ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z"
                                fill="#B9B9B9"
                            />
                        </svg>
                        {modal && current === el.count && (
                            <div className="btn-modal">
                                <svg
                                    onClick={() => {
                                        setCurrent();
                                        setModal(false);
                                    }}
                                    className="act-svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 7C12.3956 7 12.7822 6.8827 13.1111 6.66294C13.44 6.44318 13.6964 6.13082 13.8478 5.76537C13.9991 5.39992 14.0387 4.99778 13.9616 4.60982C13.8844 4.22186 13.6939 3.86549 13.4142 3.58579C13.1345 3.30608 12.7781 3.1156 12.3902 3.03843C12.0022 2.96126 11.6001 3.00087 11.2346 3.15224C10.8692 3.30362 10.5568 3.55996 10.3371 3.88886C10.1173 4.21776 10 4.60444 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM12 17C11.6044 17 11.2178 17.1173 10.8889 17.3371C10.56 17.5568 10.3036 17.8692 10.1522 18.2346C10.0009 18.6001 9.96126 19.0022 10.0384 19.3902C10.1156 19.7781 10.3061 20.1345 10.5858 20.4142C10.8655 20.6939 11.2219 20.8844 11.6098 20.9616C11.9978 21.0387 12.3999 20.9991 12.7654 20.8478C13.1308 20.6964 13.4432 20.44 13.6629 20.1111C13.8827 19.7822 14 19.3956 14 19C14 18.4696 13.7893 17.9609 13.4142 17.5858C13.0391 17.2107 12.5304 17 12 17ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z"
                                        fill="#8ADB4A"
                                    />
                                </svg>
                                <button
                                    onClick={() => handleEditStatus(el)}
                                    className="btn"
                                >
                                    {el.is_active ? (
                                        <span>Остановить</span>
                                    ) : (
                                        <span>Запустить</span>
                                    )}
                                </button>
                                {/* <button className="btn">
                                    <span>Удалить</span>
                                </button> */}
                            </div>
                        )}
                    </div>
                ),
            };
        });
    }

    return (
        <div className="PriceList">
            {!user?.shop ? (
                <>
                    <div className="title">
                        <h1 className="title-text_main">
                            Похоже у Вас нет магазина
                        </h1>
                        <h4 className="title-text_sub">
                            Чтобы просматривать информацию по прайс-листам
                            необходимо создать магазин
                        </h4>
                    </div>
                    <button
                        className="linkBtn"
                        type="button"
                        onClick={() => navigate('/add-shop')}
                    >
                        Создать магазин
                    </button>
                </>
            ) : (
                <>
                    {err && (
                        <Modal open={err} onClose={() => setErr(false)}>
                            <div className='err-modal' >
                                <h5 className='title'>
                                При добавлении прайс-листа произошла ошибка, добавте, пожайлуйста, прайс-лист еще раз
                                </h5>
                            </div>
                        </Modal>
                    )}
                    <div className="btn-wrapper">
                        {/* <div className="filters">
                            <div
                                id="1"
                                onClick={() => {
                                    document
                                        .querySelector('.filters')
                                        .querySelector('.active')
                                        .classList.remove('active');
                                    setList(price_list);
                                    document
                                        .getElementById('1')
                                        .classList.add('active');
                                }}
                                className="filter active"
                            >
                                Все
                            </div>
                            <div
                                id="2"
                                onClick={() => {
                                    document
                                        .querySelector('.filters')
                                        .querySelector('.active')
                                        .classList.remove('active');

                                    setList(
                                        price_list.filter(
                                            (el) => el.is_active === true
                                        )
                                    );
                                    document
                                        .getElementById('2')
                                        .classList.add('active');
                                }}
                                className="filter"
                            >
                                Одобрено
                            </div>
                            <div
                                id="4"
                                onClick={() => {
                                    document
                                        .querySelector('.filters')
                                        .querySelector('.active')
                                        .classList.remove('active');
                                    setList(
                                        price_list.filter(
                                            (el) => el.is_active === false
                                        )
                                    );
                                    document
                                        .getElementById('4')
                                        .classList.add('active');
                                }}
                                className="filter"
                            >
                                Отклонено
                            </div>
                        </div> */}
                        {/* {price_list?.length <= 3 && (
                            <button
                                className="add-btn"
                                type="button"
                                onClick={() => navigate('/price/add')}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.0846 10.988H13.0121V4.91542C13.0121 4.64699 12.9054 4.38956 12.7156 4.19976C12.5258 4.00995 12.2684 3.90332 12 3.90332C11.7315 3.90332 11.4741 4.00995 11.2843 4.19976C11.0945 4.38956 10.9879 4.64699 10.9879 4.91542V10.988H4.91529C4.64687 10.988 4.38944 11.0946 4.19963 11.2844C4.00983 11.4742 3.9032 11.7317 3.9032 12.0001C3.9032 12.2685 4.00983 12.5259 4.19963 12.7157C4.38944 12.9056 4.64687 13.0122 4.91529 13.0122H10.9879V19.0848C10.9879 19.3532 11.0945 19.6106 11.2843 19.8004C11.4741 19.9902 11.7315 20.0969 12 20.0969C12.2684 20.0969 12.5258 19.9902 12.7156 19.8004C12.9054 19.6106 13.0121 19.3532 13.0121 19.0848V13.0122H19.0846C19.3531 13.0122 19.6105 12.9056 19.8003 12.7157C19.9901 12.5259 20.0967 12.2685 20.0967 12.0001C20.0967 11.7317 19.9901 11.4742 19.8003 11.2844C19.6105 11.0946 19.3531 10.988 19.0846 10.988Z"
                                        fill="#FCFFFC"
                                    />
                                </svg>
                                Добавить прайс-лист
                            </button>
                        )} */}
                    </div>
                    {list?.length ? (
                        <Table columns={columns} data={getTableData(list)} />
                    ) : (
                        ''
                    )}
                </>
            )}
        </div>
    );
}

export default PriceList;
