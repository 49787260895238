import './Staff.scss';
import { useState } from 'react';
import AddStaffModal from './AddStaffModal';
import BanModal from './BanModal';
import AdminTable from '../AdminTable/AdminTable';
import useManagers from '../../../hooks/useManagers';

const columns = [
    {
        Header: 'ID сотрудника',
        accessor: 'id',
        maxWidth: 400,
        minWidth: 80,
        width: 150,
        disableSortBy: true,
    },
    {
        Header: 'Фамилия Имя',
        accessor: 'full_name',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
        disableSortBy: true,
    },
    {
        Header: 'Должность',
        accessor: 'position',
        maxWidth: 400,
        minWidth: 80,
        width: 250,
        disableSortBy: true,
    },
    {
        Header: '',
        accessor: 'ban',
        maxWidth: 400,
        minWidth: 50,
        width: 200,
        disableSortBy: true,
    },
];

function Staff() {
    const { managers, isManagerLoading, mutate } = useManagers();
    const [confirmUser, setConfirmUser] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [banModal, setBanModal] = useState(false);
    const [current, setCurent] = useState({});

    const handleOpenBan = (el) => {
        setBanModal(true);
        setCurent(el);
    };
    const handleCloseBan = () => {
        setBanModal(false);
    };
    const handleOpenUser = () => setAddUserModal(true);
    const handleCloseUser = () => {
        setAddUserModal(false);
        setConfirmUser(false);
    };

    function getTableData() {
        return managers?.map((el) => {
            return {
                id: (
                    <div
                        className="td"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <span style={{ display: 'flex' }}>
                            {el.id.slice(0, 8)}
                        </span>
                        {!el.is_active && (
                            <span style={{ opacity: '70%' }}>blocked</span>
                        )}
                    </div>
                ),
                full_name: (
                    <div className="td">
                        {`${el.second_name} ${el.first_name}`}
                    </div>
                ),
                position: (
                    <div className="td">{el.position ? el.position : '-'}</div>
                ),
                ban: (
                    <div
                        className="td ban"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            className="td"
                            onClick={() => handleOpenBan(el)}
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            Деактивировать
                        </div>
                    </div>
                ),
            };
        });
    }

    return (
        <div className="staff">
            <div onClick={() => handleOpenUser(true)} className="button">
                <span>Добавить сотрудника</span>
            </div>

            <AddStaffModal
                addUserModal={addUserModal}
                handleCloseUser={handleCloseUser}
                confirmUser={confirmUser}
                setConfirmUser={setConfirmUser}
                refresh={mutate}
            />
            <AdminTable data={!isManagerLoading ? getTableData() : []} columns={columns} />
            <BanModal
                BanModal={banModal}
                handleCloseBan={handleCloseBan}
                user={current}
            />
        </div>
    );
}

export default Staff;
